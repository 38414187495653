import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor() { }

  hasRightTo(permissionSlug: string): boolean {
    const permissions: string[] = JSON.parse(localStorage.getItem("userPermissions")) || [];
    return permissions.includes(permissionSlug);
  }
}
