<div class="tree-measurement-data-component">
  <h3>{{'i18n.APP_SPECIFIC.PREMARKING.MUTATE' | translate}}</h3>

  <div class="tree-measurement-data-overview">
      <table>
          <tr>
              <th>
                  {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.PROPERTY' | translate}}
              </th>
              <th>
                  {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.VALUE' | translate}}
              </th>
          </tr>
          <tr>
              <td>{{'i18n.APP_SPECIFIC.PREMARKING.MARKED_VALUE' | translate}}</td>
              <td><input [(ngModel)]="circumference"  placeholder="0.0" type="number" step="0.1" ></td>
          </tr>
          <tr>
              <td>{{'i18n.APP_SPECIFIC.PREMARKING.THINNED' | translate}}</td>
              <td>
                <div class="bool-option-container">
                  <label>
                      <input name="thinned" type="radio" [(ngModel)]="thinned" [value]="true">
                      {{'i18n.STATISTIC_PAGE.STM_STATISTICS.TRUE' | translate}}
                  </label>
                  <label>
                      <input name="thinned" type="radio" [(ngModel)]="thinned" [value]="false">
                      {{'i18n.STATISTIC_PAGE.STM_STATISTICS.FALSE' | translate}}
                  </label>
                  <label class="do-not-change">
                    <input name="thinned" type="radio" [(ngModel)]="thinned" [value]="null">
                    {{'i18n.APP_SPECIFIC.STM.DO_NOT_CHANGE' | translate}}
                </label>
                </div>
              </td>
          </tr>
          <tr>
            <td>{{'i18n.APP_SPECIFIC.PREMARKING.STATUS' | translate}}</td>
            <td>
              <select [(ngModel)]="selectedStatus" (change)="onStatusChange($event.target.value)">
                <option *ngFor="let option of statusOptions" [value]="option.value">{{option.label | translate}}</option>
              </select>
            </td>
        </tr>
      </table>
  </div>

  <div *ngIf="this.showSuccessMessage" class="success-messaage">
      <div class="message">
          {{'i18n.APP_SPECIFIC.STM.MUTATION_SUCCESS_MESSAGE' | translate}}
      </div>
      <div class="progress-bar">
          <div class="progress-bar-filler"></div>
      </div>
  </div>

  <button *ngIf="!this.showSuccessMessage" (click)="applyMutationChanges()">{{'i18n.APP_SPECIFIC.STM.APPLY_MUTATION_CHANGES' | translate}}</button>

</div>
