import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-marking-data',
  templateUrl: './pre-marking-data.component.html',
  styleUrls: ['./pre-marking-data.component.css']
})
export class PreMarkingDataComponent implements OnInit{
  @Input() isVisible: any;
  @Input() displayData: any;

  statusMap = {
    "none": "i18n.APP_SPECIFIC.PREMARKING.STATUS_NONE",
    "not-for-sale": "i18n.APP_SPECIFIC.PREMARKING.STATUS_NOT_FOR_SALE",
    "for-sale": "i18n.APP_SPECIFIC.PREMARKING.STATUS_FOR_SALE",
    "marked": "i18n.APP_SPECIFIC.PREMARKING.STATUS_MARKED",
    "in-stock": "i18n.APP_SPECIFIC.PREMARKING.STATUS_IN_STOCK"
  }

  ngOnInit(){


  }
}
