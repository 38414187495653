<div class="container">
  <h1>{{ 'i18n.CUSTOM_DATA.TITLE' | translate }}</h1>

  <!-- App Selection Section -->
   <div class="section-header">
     <h2>{{ 'i18n.CUSTOM_DATA.SELECT_APP' | translate }}</h2>
     <div class="action-buttons" *ngIf="selectedApp">
       <button class="btn-primary"
               routerLink="/custom-data/import"
               [attr.aria-label]="'i18n.CUSTOM_DATA.IMPORT_DATA' | translate">
         {{ 'i18n.CUSTOM_DATA.IMPORT_DATA' | translate }}
       </button>
     </div>
   </div>
  <div class="section app-selection">
    <div class="app-grid">
      <div *ngFor="let app of apps"
           class="app-tile"
           [class.selected]="selectedApp === app"
           (click)="selectApp(app)">
        <div class="app-icon">
          <img [src]="app.iconPath"
               [alt]="app.name"
               [title]="app.name"
               [attr.aria-label]="app.name">
        </div>
        <div class="app-info">
          <div class="app-name">{{app.codeName}}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Custom Data Section -->
  <div class="section-header" *ngIf="selectedApp">
    <h2>{{ 'i18n.CUSTOM_DATA.TITLE' | translate }}</h2>
    <div class="action-buttons">
      <button class="btn-primary"
              routerLink="/custom-data/add"
              [attr.aria-label]="'i18n.CUSTOM_DATA.ADD' | translate">
        {{ 'i18n.CUSTOM_DATA.ADD' | translate }}
      </button>
      <button class="btn-primary"
              [disabled]="!selectedRow || !selectedRow?.editable"
              (click)="editCustomData()"
              [attr.aria-label]="'i18n.CUSTOM_DATA.EDIT' | translate">
        {{ 'i18n.CUSTOM_DATA.EDIT' | translate }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="selectedApp">
    <div class="section custom-data">
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>{{ 'i18n.CUSTOM_DATA.TABLE.NAME' | translate }}</th>
              <th>{{ 'i18n.CUSTOM_DATA.TABLE.TYPE' | translate }}</th>
              <th>{{ 'i18n.CUSTOM_DATA.TABLE.CONTEXT' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of customData"
                [class.selected]="selectedRow === item"
                (click)="selectRow(item)">
              <td>{{item.name}}</td>
              <td>{{fieldTypes[item.field_type] | translate}}</td>
              <td>{{getContextNames(item.contexts)}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <button [disabled]="currentPage === 1"
                (click)="changePage(currentPage - 1)"
                [title]="'i18n.CUSTOM_DATA.PAGINATION.PREV_PAGE' | translate"
                [attr.aria-label]="'i18n.CUSTOM_DATA.PAGINATION.PREV_PAGE' | translate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" aria-hidden="true" focusable="false"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
        </button>
        <span>{{ 'i18n.CUSTOM_DATA.PAGINATION.PAGE_OF' | translate:{ current: currentPage, total: totalPages || 1 } }}</span>
        <button [disabled]="currentPage === (totalPages || 1)"
                (click)="changePage(currentPage + 1)"
                [title]="'i18n.CUSTOM_DATA.PAGINATION.NEXT_PAGE' | translate"
                [attr.aria-label]="'i18n.CUSTOM_DATA.PAGINATION.NEXT_PAGE' | translate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" aria-hidden="true" focusable="false"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
        </button>
      </div>
    </div>
  </ng-container>
</div>
