import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import { expand, of, takeWhile } from 'rxjs';
import { CacheService } from 'src/app/services/cache.service';
import { FieldService } from 'src/app/services/field.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fields-overview-map-page',
  templateUrl: 'fields-overview-map-page.component.html',
  styleUrls: ["fields-overview-map-page.component.css"]
})

export class FieldsOverviewMapPageComponent implements OnInit {
  fields_map: mapboxgl.Map;

  fields;
  features;

  constructor(private cacheService: CacheService, private router: Router, private portalAppsService: PortalAppsService, private fieldService: FieldService) { }


  getAllFields(){
    if(this.cacheService.hasData("fields-overview-data")){
      this.fields = this.cacheService.getData("fields-overview-data").items;
      this.loadMap();
    }
    else{
      // load fields
      this.getFields(0);
    }
  }

  getFields(offset = 0) {
    const measurementAppIds = Object.values(this.portalAppsService.activeApp.measurementAppsMap);
    const amountPerPage = 15;
    const fieldsSet = new Set();
    let fieldList = {
      items: [],
      limit: amountPerPage,
      offset: 0,
      total: 0
    };

    const fetchFieldsForApp = (appId, offset) => {
      return this.fieldService.get_fields_for_app(appId, offset, amountPerPage);
    };

    const fetchAllFields = async () => {

        for (const appId of measurementAppIds) {
          let currentOffset = offset;
          let moreData = true;

          // new
          fetchFieldsForApp(appId, currentOffset).pipe(
            expand(data => {
              data.items.forEach(item => {
                if (!fieldsSet.has(item.id)) {
                  fieldsSet.add(item.id);
                  fieldList.items.push(item);
                }
              });


              // Update currentOffset for the next loop iteration
              currentOffset += data.items.length;

              // Determine if there's more data to fetch
              const moreData = currentOffset < data.total;

              if(!moreData){
                this.fields = fieldList.items;
                this.loadMap();
              }

              // Return a new Observable for the next iteration or a completed Observable
              return moreData ? fetchFieldsForApp(appId, currentOffset) : of(null);
            }),
            takeWhile(data => data !== null)
          ).subscribe(
            {
              next: () => {
                // Additional actions after each successful fetch can be placed here
              },
              error: error => {
                console.error(`Failed to fetch fields for appId ${appId}:`, error);
              }
            }
          )
        }


    };


    fetchAllFields();
  }

  addFieldsSources(fields){
    let features = fields.map(field => {
      return {
            id: field.id,
            type: 'Feature',
            properties: {
              name: field.name,
              id: field.id,
              center: field.center
            },
            geometry: {
              type: 'Polygon',
              coordinates: field.boundary.coordinates[0]
            }
      }
    })

    this.features = features;

    this.fields_map.addSource('fieldsBoundery-source', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: this.features
      }
    });

    this.fitFeaturesInTheView();
  }


  addFieldsEvents(){
    this.fields_map.on('mouseenter', 'fieldsBoundery-layer', (e) => {
      this.fields_map.getCanvas().style.cursor = 'pointer'; // Change cursor to pointer
      const fieldName = e.features[0].properties.name; // Get field name from properties
      const originalCoordinates = (e.features[0].geometry as GeoJSON.Polygon).coordinates;

      // Calculate the centroid of the polygon to position the popup
      const centroid = this.calculatePolygonCentroid(originalCoordinates[0]);

      // Calculate the topmost point of the polygon for positioning the popup
      const topmostPoint = this.getTopmostPoint(originalCoordinates[0]);

      // Show popup with the field name
      this.popup
        .setLngLat([centroid[0], topmostPoint[1] + 0.0001])
        .setHTML(`<strong>${fieldName}</strong>`)
        .addTo(this.fields_map);
    });


    // Reset the polygon size on mouse leave
    this.fields_map.on('mouseleave', 'fieldsBoundery-layer', () => {
      this.fields_map.getCanvas().style.cursor = ''; // Reset cursor
      this.popup.remove(); // Remove popup
    });

    // Click Event: Call a function and pass the clicked feature's properties
    this.fields_map.on('click', 'fieldsBoundery-layer', (e) => {
      const clickedFeature = e.features[0];
      localStorage.setItem('center', JSON.stringify(JSON.parse(clickedFeature.properties.center).coordinates))
      const activeAppCode = localStorage.getItem("active_app_code_name");
      const route = "apps/" + activeAppCode + '/fields/fieldmap';
      this.fields_map.remove();
      this.router.navigate([route], {queryParams: {field_id: clickedFeature.properties.id }});

    });
  }

  // event helpers

  getTopmostPoint(coordinates: GeoJSON.Position[]): GeoJSON.Position {
    return coordinates.reduce((topmost, current) => {
      return current[1] > topmost[1] ? current : topmost; // Compare latitudes (current[1])
    }, coordinates[0]);
  }

  calculatePolygonCentroid(coordinates: number[][]): [number, number] {
    let sumX = 0.0;
    let sumY = 0.0;
    const numPoints = coordinates.length;

    // Sum up all the coordinates
    coordinates.forEach(point => {
      sumX += point[0]; // Longitude
      sumY += point[1]; // Latitude
    });

    // Calculate the average
    return [sumX / numPoints, sumY / numPoints];
  }



  scalePolygon(coordinates: GeoJSON.Position[], scaleFactor: number): GeoJSON.Position[] {
    const centroid = this.calculatePolygonCentroid(coordinates);
    return coordinates.map(point => [
      centroid[0] + (point[0] - centroid[0]) * scaleFactor, // Scale Longitude
      centroid[1] + (point[1] - centroid[1]) * scaleFactor  // Scale Latitude
    ]);
  }

  popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false
  });

  calculateBoundingBox(features) {
    let bounds = [Infinity, Infinity, -Infinity, -Infinity]; // [minX, minY, maxX, maxY]

    features.forEach(feature => {
      const coordinates = feature.geometry.coordinates[0]; // Assuming Polygon has [ring] structure
      coordinates.forEach(coord => {
        const [lng, lat] = coord;
        bounds[0] = Math.min(bounds[0], lng); // minX (longitude)
        bounds[1] = Math.min(bounds[1], lat); // minY (latitude)
        bounds[2] = Math.max(bounds[2], lng); // maxX (longitude)
        bounds[3] = Math.max(bounds[3], lat); // maxY (latitude)
      });
    });

    return bounds;
  }

  fitFeaturesInTheView(){
    // Calculate the bounding box for the given features
    const boundingBox = this.calculateBoundingBox(this.features);

    // Use the bounding box to set the map view
    this.fields_map.fitBounds([
    [boundingBox[0], boundingBox[1]], // Southwest corner [minX, minY]
    [boundingBox[2], boundingBox[3]]  // Northeast corner [maxX, maxY]
    ], {
      padding: 50, // Optional padding around the bounding box
      maxZoom: 15  // Optional max zoom level to prevent over-zooming
    });
  }



  addBoundaryLayers(){
    this.fields_map.addLayer({
      id: 'fieldsBoundery-layer',
      type: 'fill',
      source: 'fieldsBoundery-source',
      paint: {
          'fill-color': '#ee2308',
          'fill-opacity': 0.4
      }
    });
  }

  loadMap(){
    mapboxgl.accessToken = environment.mapbox.accessToken;

    this.fields_map = new mapboxgl.Map({
      container: 'fields_map',
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: localStorage.getItem('center') ? JSON.parse(localStorage.getItem('center')) : [52.23, 4.55]
    });

    let data_loaded = false

    this.fields_map.on('style.load', () => {
      if (!data_loaded) {
        data_loaded = true //needed or it gets called twice
        this.addFieldsSources(this.fields)
        this.addBoundaryLayers();
        this.addFieldsEvents();

      }
    })
  }

  ngOnInit() {
    this.getAllFields()
   }
}
