import { PermissionService } from './permission.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(private cache: CacheService, private router: Router, private permissionService: PermissionService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const requiredPermissions = route.data['permissions'];

    let conditionMet = true;

    requiredPermissions.forEach(permission => {
      if (!this.permissionService.hasRightTo(permission)) {
        conditionMet = false;
      }
    });

    if(conditionMet){
      return true;
    }

    this.router.navigate(['/access-denied']); // Redirect if permission is missing
    return false;
  }
}
