import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';

interface ImportType {
  id: string;
  name: string;
  description: string;
  guide: string;
}

interface ExcelData {
  headers: string[];
  rows: any[];
}

interface Field {
  id: string;
  name: string;
  type: string;
  validator?: (value: any) => { isValid: boolean; error?: string; value?: any };
}

interface ValidationResult {
  isValid: boolean;
  error?: string;
  value?: any;
}

interface CustomField {
  id: string;
  name: string;
  field_type: 'text' | 'number' | 'single_choice' | 'multiple_choice';
  options?: {
    values: string[];
  };
}

interface TreeType {
  id: string;
  name: string;
}

interface ValidationError {
  row: number;
  column: string;
  error: string;
}

interface ColumnErrorGroup {
  column: string;
  errors: ValidationError[];
  isExpanded: boolean;
}

interface ValidationErrorData {
  row: number;
  column: string;
  error: string;
}

@Component({
  selector: 'app-import-custom-data',
  templateUrl: './import-custom-data.component.html',
  styleUrls: ['./import-custom-data.component.css']
})
export class ImportCustomDataComponent implements OnInit {
  importTypes: ImportType[] = [
    {
      id: 'append',
      name: 'Update Existing Data',
      description: 'Update existing treedata',
      guide: 'Add new fields or update existing tree data. The import file must have the same structure as the tree data.'
    },
    {
      id: 'new',
      name: 'Add New Data',
      description: 'Create new data measurements',
      guide: 'Create new measurements as new tree data. The import file must have the same structure as the tree data.'
    },
    {
      id: 'pre_measurement',
      name: 'Pre-measurement Data',
      description: 'Import tree data that would attached to the measurement data after the measurement is taken',
      guide: 'The import file should have similar structure as the tree data. Must inlcude the location (longitude and latitude) of the tree.'
    }
  ];

  selectedImportType: ImportType | null = null;
  selectedFile: File | null = null;
  error: string | null = null;
  showGuide = true;
  excelColumns: string[] = [];
  excelData: ExcelData | null = null;

  isImporting = false;
  importingProgress = 0;

  // Column mapping properties
  columnMapping: { [key: string]: string } = {};
  availableFields: Field[] = [
    {
      id: 'time',
      name: 'Time',
      type: 'string',
      validator: (value: any): ValidationResult => {
        if (!value) {
          return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.TIME_REQUIRED') };
        }
        const date = new Date(value);
        if (isNaN(date.getTime())) {
          return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_DATE') };
        }
        // Format to required format
        const formattedDate = this.formatDateTime(date);
        return { isValid: true, value: formattedDate };
      }
    },
    {
      id: 'location.coordinates[0]',
      name: 'Longitude',
      type: 'number',
      validator: (value: any): ValidationResult => {
        const num = Number(value);
        if (isNaN(num) || num < -180 || num > 180) {
          return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_LONGITUDE') };
        }
        return { isValid: true, value: num };
      }
    },
    {
      id: 'location.coordinates[1]',
      name: 'Latitude',
      type: 'number',
      validator: (value: any): ValidationResult => {
        const num = Number(value);
        if (isNaN(num) || num < -90 || num > 90) {
          return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_LATITUDE') };
        }
        return { isValid: true, value: num };
      }
    },
    {
      id: 'data.circumference',
      name: 'Circumference',
      type: 'number',
      validator: (value: any): ValidationResult => {
        const num = Number(value);
        if (isNaN(num) || num < 0) {
          return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_CIRCUMFERENCE') };
        }
        return { isValid: true, value: num };
      }
    },
    {
      id: 'data.size_bucket_circumference',
      name: 'Size Bucket',
      type: 'string',
      validator: (value: any): ValidationResult => {
        if (!value) {
          return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.SIZE_BUCKET_REQUIRED') };
        }
        return { isValid: true, value: String(value) };
      }
    },
    {
      id: 'metadata.tree_type',
      name: 'Tree Type',
      type: 'string',
      validator: (value: any): ValidationResult => {
        if (!value) {
          return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.TREE_TYPE_REQUIRED') };
        }
        if (!this.availableTreeTypes.some(t => t.name === String(value))) {
          return {
            isValid: false,
            error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_TREE_TYPE', {
              validTypes: this.availableTreeTypes.map(t => t.name).join(', ')
            })
          };
        }
        return { isValid: true, value: String(value) };
      }
    },
    {
      id: 'metadata.row_index',
      name: 'Row Index',
      type: 'number',
      validator: (value: any): ValidationResult => {
        const num = Number(value);
        if (isNaN(num) || num < 0) {
          return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_ROW_INDEX') };
        }
        return { isValid: true, value: num };
      }
    },
    {
      id: 'metadata.sticky_note',
      name: 'Row Block',
      type: 'string',
      validator: (value: any): ValidationResult => {
        return { isValid: true, value: value ? String(value) : null };
      }
    },
    {
      id: 'metadata.note',
      name: 'Note',
      type: 'string',
      validator: (value: any): ValidationResult => {
        if (value === null || value === undefined) return { isValid: true, value: '' };
        return { isValid: true, value: String(value) };
      }
    },
    {
      id: 'metadata.damaged',
      name: 'Damaged',
      type: 'boolean',
      validator: (value: any): ValidationResult => {
        if (typeof value === 'boolean') return { isValid: true, value };
        if (typeof value === 'string') {
          const lowered = value.toLowerCase();
          if (['true', 'yes', 'ja', 'waar', '1'].includes(lowered)) return { isValid: true, value: true };
          if (['false', 'no', 'nee', 'onwaar', '0'].includes(lowered)) return { isValid: true, value: false };
        }
        return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_BOOLEAN') };
      }
    },
    {
      id: 'metadata.has_pole',
      name: 'Has Pole',
      type: 'boolean',
      validator: (value: any): ValidationResult => {
        if (typeof value === 'boolean') return { isValid: true, value };
        if (typeof value === 'string') {
          const lowered = value.toLowerCase();
          if (['true', 'yes', 'ja', '1'].includes(lowered)) return { isValid: true, value: true };
          if (['false', 'no', 'nee', '0'].includes(lowered)) return { isValid: true, value: false };
        }
        return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_BOOLEAN') };
      }
    },
    {
      id: 'metadata.abQuality',
      name: 'AB Quality',
      type: 'string',
      validator: (value: any): ValidationResult => {
        return { isValid: true, value: value ? String(value) : null };
      }
    },
    {
      id: 'metadata.metadata',
      name: 'Metadata',
      type: 'string|object',
      validator: (value: any): ValidationResult => {
        return { isValid: true, value };
      }
    },
  ];

  // Progress tracking
  isProcessing = false;
  progress = 0;
  currentRow = 0;
  totalRows = 0;
  transformedData: any[] = [];
  isDataProcessed = false;
  successMessage: string | null = null;
  processingSuccessMessage: string | null = null;
  errorMessage: string | null = null;

  availableTreeTypes: TreeType[] = [];
  customFields: CustomField[] = [];

  columnErrorGroups: ColumnErrorGroup[] = [];
  showErrorPanel = false;

  constructor(
    private router: Router,
    private portalAppsService: PortalAppsService,
    private apiService: ApiService,
    private organizationService: OrganizationService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadCustomData();
    this.loadTreeTypes();
  }

  private loadTreeTypes(): void {
    this.organizationService.get_tree_types().subscribe(
      (treeTypes: {collection: TreeType[]}) => {
        this.availableTreeTypes = treeTypes.collection;
        // Update the tree type validator
        const treeTypeField = this.availableFields.find(f => f.id === 'metadata.tree_type');
        if (treeTypeField) {
          treeTypeField.validator = (value: any): ValidationResult => {
            if (!value) {
              return { isValid: false, error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.TREE_TYPE_REQUIRED') };
            }
            const stringValue = String(value).trim();
            const matchingType = this.availableTreeTypes.find(t => t.name.toLowerCase() === stringValue.toLowerCase());
            if (!matchingType) {
              return {
                isValid: false,
                error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.INVALID_TREE_TYPE', {
                  validTypes: this.availableTreeTypes.map(t => t.name).join(', ')
                })
              };
            }
            return { isValid: true, value: matchingType.name }; // Use the exact name from the matching type
          };
        }
      },
      error => {
        console.error('Error loading tree types:', error);
      }
    );
  }

  private loadCustomData(): void {
    if (!this.portalAppsService.getSelectedApp()) {
      this.router.navigate(['/custom-data']);
      return;
    }

    const appId = this.portalAppsService.getSelectedApp()?.measurementAppsMap[0];
    this.apiService.get_with_auth(`/custom-data/definitions?app_id=${appId}`).subscribe(
      (response: any) => {
        this.customFields = response.collection;
        response.collection.forEach((item: CustomField) => {
          const validator = this.createCustomFieldValidator(item);
          this.availableFields.push({
            id: "custom_data." + item.id,
            name: item.name,
            type: item.field_type,
            validator
          });
        });
      },
      error => {
        console.error('Error loading custom data:', error);
      }
    );
  }

  private createCustomFieldValidator(field: CustomField): (value: any) => ValidationResult {
    return (value: any): ValidationResult => {
      if (value === undefined || value === null || value === '') {
        return { isValid: true, value: null }; // Allow empty values for custom fields
      }

      switch (field.field_type) {
        case 'text':
          return { isValid: true, value: String(value) };

        case 'number':
          const num = Number(value);
          if (isNaN(num)) {
            return {
              isValid: false,
              error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.CUSTOM_FIELD_NUMBER', {
                fieldName: field.name
              })
            };
          }
          return { isValid: true, value: num };

        case 'single_choice':
          if (!field.options?.values.includes(String(value))) {
            return {
              isValid: false,
              error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.CUSTOM_FIELD_SINGLE_CHOICE', {
                fieldName: field.name,
                validValues: field.options?.values.join(', ')
              })
            };
          }
          return { isValid: true, value: String(value) };

        case 'multiple_choice':
          let values: string[];
          try {
            values = Array.isArray(value) ? value.map(String) : String(value).split(',').map(v => v.trim());
          } catch {
            return {
              isValid: false,
              error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.CUSTOM_FIELD_MULTIPLE_CHOICE_FORMAT', {
                fieldName: field.name
              })
            };
          }

          const invalidValues = values.filter(v => !field.options?.values.includes(v));
          if (invalidValues.length > 0) {
            return {
              isValid: false,
              error: this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.VALIDATION.CUSTOM_FIELD_MULTIPLE_CHOICE_VALUES', {
                fieldName: field.name,
                invalidValues: invalidValues.join(', '),
                validValues: field.options?.values.join(', ')
              })
            };
          }
          return { isValid: true, value: values };

        default:
          return { isValid: true, value };
      }
    };
  }

  get isColumnMappingComplete(): boolean {
    // Check if at least one column is mapped and required fields are present
    return Object.values(this.columnMapping).some(value => value !== '') && this.hasRequiredFields;
  }

  get hasRequiredFields(): boolean {
    return Object.values(this.columnMapping).includes('location.coordinates[0]') &&
           Object.values(this.columnMapping).includes('location.coordinates[1]');
  }

  get isReadyToImport(): boolean {
    return this.selectedFile !== null && this.isColumnMappingComplete && this.isDataProcessed;
  }

  selectImportType(type: ImportType): void {
    this.selectedImportType = type;
    this.error = null;
  }

  getAcceptedExtensions(): string {
    return '.json,.csv,.xlsx,.xls';
  }

  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];
    if (!this.isValidExcelFile(file)) {
      this.error = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.FILE_SECTION.INVALID_FILE');
      this.selectedFile = null;
      this.excelColumns = [];
      this.excelData = null;
      this.isDataProcessed = false;
      this.transformedData = [];
      return;
    }

    this.selectedFile = file;
    this.error = null;
    this.isDataProcessed = false;
    this.transformedData = [];

    try {
      await this.readExcelFile(file);
    } catch (err) {
      this.error = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.FILE_SECTION.READ_ERROR');
      this.selectedFile = null;
      this.excelColumns = [];
      this.excelData = null;
      this.isDataProcessed = false;
      this.transformedData = [];
    }
  }

  private isValidExcelFile(file: File): boolean {
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-excel' // .xls
    ];
    return validTypes.includes(file.type);
  }

  private async readExcelFile(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const data = e.target?.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          // Convert sheet to JSON with headers
          const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            raw: false, // This will ensure dates are parsed as strings
            dateNF: 'yyyy-mm-dd hh:mm:ss' // This will format dates consistently
          });

          if (jsonData.length < 2) { // At least headers and one data row
            this.error = 'Excel file must contain headers and at least one row of data';
            reject(new Error('Invalid data'));
            return;
          }

          // First row contains headers
          const headers = jsonData[0] as string[];

          // Filter out empty rows and convert to object format
          const rows = jsonData.slice(1)
            .filter((row: any[]) => row.some(cell => cell !== undefined && cell !== null && cell !== ''))
            .map((row: any[]) => {
              const rowObj: { [key: string]: any } = {};
              headers.forEach((header, index) => {
                rowObj[header] = row[index];
              });
              return rowObj;
            });

          if (rows.length === 0) {
            this.error = 'No valid data rows found in the Excel file';
            reject(new Error('No valid data'));
            return;
          }

          this.excelData = { headers, rows };
          this.excelColumns = headers;

          resolve();
        } catch (err) {
          reject(err);
        }
      };

      reader.onerror = () => {
        reject(new Error('File reading failed'));
      };

      reader.readAsBinaryString(file);
    });
  }

  onSubmit(): void {
    if (!this.selectedFile) {
      this.error = 'Please select a file to import';
      return;
    }

    if (!this.selectedImportType) {
      this.error = 'Please select an import type';
      return;
    }

    // TODO: Implement file upload logic
    console.log('Importing file:', this.selectedFile);
    console.log('Import type:', this.selectedImportType.id);
  }

  onCancel(): void {
    this.router.navigate(['/custom-data']);
    this.selectedFile = null;
    this.error = null;
    this.excelColumns = [];
    this.excelData = null;
    this.isDataProcessed = false;
    this.transformedData = [];
  }

  toggleGuide(): void {
    this.showGuide = !this.showGuide;
  }

  onMappingChange(): void {
    // Reset error when mapping changes
    this.error = null;
  }



  private convertToNestedObject(flatObj: any): any {
    const result: any = {};

    Object.entries(flatObj).forEach(([path, value]) => {
      const parts = path.split('.');
      let current = result;

      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const arrayMatch = part.match(/^(.*?)\[(\d+)\]$/);

        if (i === parts.length - 1) {
          // Last part - set the value
          if (arrayMatch) {
            const [, arrayName, index] = arrayMatch;
            if (!current[arrayName]) current[arrayName] = [];
            current[arrayName][parseInt(index)] = value;
          } else {
            current[part] = value;
          }
        } else {
          // Not last part - create nested object/array if doesn't exist
          if (arrayMatch) {
            const [, arrayName, index] = arrayMatch;
            if (!current[arrayName]) current[arrayName] = [];
            if (!current[arrayName][parseInt(index)]) current[arrayName][parseInt(index)] = {};
            current = current[arrayName][parseInt(index)];
          } else {
            if (!current[part]) current[part] = {};
            current = current[part];
          }
        }
      }
    });

    return result;
  }

  private groupErrorsByColumn(validationErrors: ValidationErrorData[]): ColumnErrorGroup[] {
    const errorGroups: { [key: string]: ValidationError[] } = {};

    validationErrors.forEach(error => {
      if (!errorGroups[error.column]) {
        errorGroups[error.column] = [];
      }
      errorGroups[error.column].push({
        row: error.row,
        column: error.column,
        error: error.error
      });
    });

    return Object.entries(errorGroups).map(([column, errors]) => ({
      column,
      errors,
      isExpanded: false
    }));
  }

  onProcessData() {
    this.isProcessing = true;
    this.progress = 0;
    this.currentRow = 0;
    this.errorMessage = null;
    this.successMessage = null;
    this.processingSuccessMessage = null;
    this.transformedData = [];
    this.columnErrorGroups = [];
    this.showErrorPanel = false;

    if (!this.excelData || !this.excelData.rows.length) {
      this.errorMessage = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.ERRORS.NO_DATA');
      this.isProcessing = false;
      return;
    }

    const totalRows = this.excelData.rows.length;
    this.totalRows = totalRows;

    // Array to collect all validation errors
    const validationErrors: ValidationErrorData[] = [];
    let processedRows = 0;

    const processRow = (index: number) => {
      if (index >= totalRows) {
        this.isProcessing = false;
        this.isDataProcessed = true;

        if (validationErrors.length > 0) {
          this.columnErrorGroups = this.groupErrorsByColumn(validationErrors);
          this.showErrorPanel = true;
          this.errorMessage = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.ERRORS.VALIDATION_ERRORS', {
            count: validationErrors.length
          });
        } else {
          this.processingSuccessMessage = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.PROCESSING.SUCCESS', {
            processed: processedRows,
            total: totalRows
          });
        }

        this.cdr.detectChanges();
        return;
      }

      try {
        const row = this.excelData!.rows[index];
        const flatTransformedRow: any = {};
        let rowValidationErrors: ValidationErrorData[] = [];

        Object.entries(this.columnMapping).forEach(([excelColumn, field]) => {
          if (!field) return;

          const fieldConfig = this.availableFields.find(f => f.id === field);
          if (!fieldConfig) return;

          const value = row[excelColumn];
          const validationResult = fieldConfig.validator ? fieldConfig.validator(value) : { isValid: true, value };

          if (!validationResult.isValid) {
            rowValidationErrors.push({
              row: index + 2,
              column: excelColumn,
              error: validationResult.error
            });
          } else {
            flatTransformedRow[field] = validationResult.value;
          }
        });

        if (rowValidationErrors.length === 0) {
          const nestedTransformedRow = this.convertToNestedObject(flatTransformedRow);
          this.transformedData.push(nestedTransformedRow);
          processedRows++;
        } else {
          validationErrors.push(...rowValidationErrors);
        }

        this.currentRow = index + 1;
        this.progress = (this.currentRow / totalRows) * 100;
        this.cdr.detectChanges();

        setTimeout(() => processRow(index + 1), 0);
      } catch (error: any) {
        validationErrors.push({
          row: index + 2,
          column: 'Processing',
          error: error.message
        });
        this.currentRow = index + 1;
        this.progress = (this.currentRow / totalRows) * 100;
        this.cdr.detectChanges();
        setTimeout(() => processRow(index + 1), 0);
      }
    };

    processRow(0);
  }

  toggleColumnErrors(columnGroup: ColumnErrorGroup): void {
    columnGroup.isExpanded = !columnGroup.isExpanded;
    this.cdr.detectChanges();
  }

  async onImport() {
    if (!this.isDataProcessed || !this.transformedData.length) {
      this.error = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.ERRORS.PROCESS_FIRST');
      return;
    }

    this.apiService.post_with_auth(`/custom-point-based-raw`, this.transformedData).subscribe({
      next: (response) => {
        if (response.status === 'completed') {
          // For small datasets (<1000 items), handle as before
          this.successMessage = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.PROCESSING.IMPORT_SUCCESS');
          setTimeout(() => {
            this.router.navigate(['/apps']);
          }, 5000);
        } else if (response.status === 'processing') {
          // For large datasets, start polling for progress
          this.pollImportProgress(response.task_id);
        }
      },
      error: (error) => {
        console.error('Error importing data:', error);
        this.error = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.ERRORS.IMPORT_FAILED');
      }
    });


  }

  private pollImportProgress(taskId: string) {
    // Show loading state
    this.isImporting = true;
    this.progress = 0;

    // Create an interval to poll progress
    const pollInterval = setInterval(() => {
      this.apiService.get_with_auth(`/custom-point-based-raw/import/${taskId}`).subscribe({
        next: (progressResponse) => {
          if (progressResponse.status === 'completed') {
            // Import finished successfully
            clearInterval(pollInterval);
            this.isImporting = false;
            this.successMessage = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.PROCESSING.IMPORT_SUCCESS');
            setTimeout(() => {
              this.router.navigate(['/apps']);
            }, 5000);
          } else if (progressResponse.status.startsWith('failed')) {
            // Import failed
            clearInterval(pollInterval);
            this.isImporting = false;
            this.error = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.ERRORS.IMPORT_FAILED');
          } else {
            // Update progress
            const [current, total] = progressResponse.progress.split('/').map(Number);
            this.importingProgress = Math.round((current / total) * 100);
          }
        },
        error: (error) => {
          console.error('Error checking import progress:', error);
          clearInterval(pollInterval);
          this.isImporting = false;
          this.error = this.translateService.instant('i18n.CUSTOM_DATA.IMPORT.ERRORS.PROGRESS_CHECK_FAILED');
        }
      });
    }, 300);
  }

  private formatDateTime(value: any): string {
    if (!value) return null;

    let date: Date;

    // Try parsing different formats
    if (typeof value === 'number') {
      // Handle Excel serial number
      date = new Date((value - 25569) * 86400 * 1000);
    } else if (typeof value === 'string') {
      date = new Date(value);
    } else {
      date = new Date(value);
    }

    // Check if date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    // Format to the required format: YYYY-MM-DDThh:mm:ss.SSSSSS
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0') + '000';

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  private transformValue(value: any, type: string): any {
    if (value === undefined || value === null || value === '') {
      return null;
    }

    switch (type) {
      case 'number':
        const num = Number(value);
        return isNaN(num) ? null : num;

      case 'boolean':
        if (typeof value === 'boolean') return value;
        if (typeof value === 'string') {
          const lowered = value.toLowerCase();
          if (['true', 'yes', '1'].includes(lowered)) return true;
          if (['false', 'no', '0'].includes(lowered)) return false;
        }
        return null;

      case 'date':
        const date = new Date(value);
        return isNaN(date.getTime()) ? null : date.toISOString();

      case 'string':
      default:
        return String(value);
    }
  }
}

