import { Component, Input, OnInit } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';

@Component({
  selector: 'map-visualize-by-attribute',
  templateUrl: './visualize-by-attribute.component.html',
  styleUrls: ["./visualize-by-attribute.component.css"]
})

export class VisualizeByAttribute implements OnInit {
  @Input() attributes: any;

  listOpen: boolean	= false;
  selectedAttribute;
  mapStrategy;

  labelMap = {
    'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_CIRCUMFERENCE' : "i18n.FIELD_PAGE.THICKNESS_MEASUREMENT",
    'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_TYPE' : "i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_TYPE",
    'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.DAMAGED' : "i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.DAMAGED",
    'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.AB_QUALITY' : "i18n.FIELD_PAGE.QUALITY",
    'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.NOTE' : "i18n.FIELD_PAGE.NOTE"
  }

  toggleList(){
    this.listOpen = !this.listOpen;
  }

  handleSelectAttribute(attribute){
    this.selectedAttribute = attribute;
    this.listOpen = false;

    this.mapStrategy.recolorPointsByAttribute(attribute);
  }


  constructor(private portalAppsService: PortalAppsService) {
    this.mapStrategy = portalAppsService.activeApp.mapStrategy as any;

   }

  ngOnInit() {
    this.selectedAttribute = this.attributes[0];


   }
}
