import { Component } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pre-marking-apply-thinnig',
  templateUrl: './pre-marking-apply-thinnig.component.html',
  styleUrls: ['./pre-marking-apply-thinnig.component.css']
})
export class PreMarkingApplyThinnigComponent {
  portalAppMapStrategy: any;

  // to receive events
  private subscription: Subscription = new Subscription();

  showSuccessMessage = false;

  showErrorSelectionMessage = false;

  thinningType = 'selection';

  // patern
  treesToKeep;
  treesToSkip;

  toThinPercentageInput = 0;

  isManualRowSelectionEnabled = false;
  // disabled
  generateGuideRowsForThinning(){
    this.portalAppMapStrategy.generateGuideRowsForThinning();
  }

  toggleRowSelectionForThinning(isOn){
    this.portalAppMapStrategy.isRowSelectionForThiningEnabled = isOn;
  }

  constructor(private portalAppsService: PortalAppsService, private organizationService: OrganizationService, private eventBusService: EventBusService){}

  ngOnInit() {
    this.portalAppMapStrategy = this.portalAppsService.activeApp.mapStrategy;
    // check for premarking availability

    if(this.portalAppMapStrategy.floatingContainerData.value.count >= 2) this.showErrorSelectionMessage = false;
    else this.showErrorSelectionMessage = true;

    this.subscription.add(
      this.eventBusService.events$.subscribe(data => {
        // Handle the event
        if(data.target == "premarkingThinningComponent"){
          if(data.action == "success"){
            this.showSuccessMessage = true;

          }
          if(data.action == "update"){
            if(this.portalAppMapStrategy.floatingContainerData.value.count >= 2) this.showErrorSelectionMessage = false;
            else this.showErrorSelectionMessage = true;
          }
        }
      })
    );
  }

  clearGuideRows(){
    this.portalAppMapStrategy.clearGuideRowsForThinning();
  }

  handleShowThinningResult(){

    this.portalAppMapStrategy.showThinningResultOnGuideRows(this.treesToKeep, this.treesToSkip);
  }

  clearPatern(){
    this.portalAppMapStrategy.clearToBeThinnedPatern();
  }

  applyThinning(){
    this.portalAppMapStrategy.saveThinnedTrees();
  }

  changeThinningType(type){
      this.thinningType = type;
  }

  showThinningBySelection(){
    const convertedPercentage = this.toThinPercentageInput / 100;
    this.portalAppMapStrategy.showThinningBySelection(convertedPercentage);
  }



}
