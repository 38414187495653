<div>
  <!-- <div *ngIf="premarkingOpen" class="premarking-content" #premarkingContentMenu>
  <h2>Pre-marking features</h2>

  <div class="features">
    <div class="feature-section">
      <mat-label>Thinning</mat-label>
      <div class="feature-btns">

        <button (click)="handleDisplayThinning()" [ngStyle]="{'background-color' : displayThinning ? 'rgb(218, 255, 222)' : 'white'}" class="floating_btn">
          <svg *ngIf='!displayThinning' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/></svg>
          <svg *ngIf='displayThinning' fill="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
        </button>

        <button class="floating_btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 192l-39.5-39.5c4.9-12.6 7.5-26.2 7.5-40.5C224 50.1 173.9 0 112 0S0 50.1 0 112s50.1 112 112 112c14.3 0 27.9-2.7 40.5-7.5L192 256l-39.5 39.5c-12.6-4.9-26.2-7.5-40.5-7.5C50.1 288 0 338.1 0 400s50.1 112 112 112s112-50.1 112-112c0-14.3-2.7-27.9-7.5-40.5L499.2 76.8c7.1-7.1 7.1-18.5 0-25.6c-28.3-28.3-74.1-28.3-102.4 0L256 192zm22.6 150.6L396.8 460.8c28.3 28.3 74.1 28.3 102.4 0c7.1-7.1 7.1-18.5 0-25.6L342.6 278.6l-64 64zM64 112a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48 240a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
        </button>
      </div>

    </div>
    <div class="global_devider"></div>

    <div class="feature-section">
      <mat-label>Sellable Status</mat-label>
      <div class="feature-btns">
        <button (click)="handleDisplaySellableStatus()" [ngStyle]="{'background-color' : displaySellableStatus ? 'rgb(218, 255, 222)' : 'white'}" class="floating_btn">
          <svg *ngIf='!displaySellableStatus' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/></svg>
          <svg *ngIf='displaySellableStatus' fill="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
        </button>
        <button class="floating_btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
        </button>
      </div>
    </div>
  </div>
</div> -->

  <div *ngIf="!this.portalAppMapStrategy.premarkingViewOpen" class="stm-control-set-container">
    <p class="field_name" *ngIf="portalAppMapStrategy.selectedField">{{this.portalAppMapStrategy.selectedField.name}}‎
    </p>

    <mat-label>{{'i18n.FIELD_PAGE.DATASET' | translate}}</mat-label>

    <div class="date_slector">
      <!-- <input style="border: solid #3D9B47 1px" class="global_input_field" placeholder="{{'i18n.GLOBAL.SEARCH' | translate}}"
          (keyup)="portalAppMapStrategy.searchedDate = $event.target.value"> -->
      <div class="date_list" *ngIf="portalAppMapStrategy.selectedField">
        <div *ngIf="portalAppMapStrategy.searchedDate == ''">
          <p (click)="portalAppMapStrategy.dateChange('all')"
            [ngStyle]="{'background-color': (portalAppMapStrategy.selectedDate == 'all') ? '#daffde' : '#fff'}">
            <b>{{'i18n.FIELD_PAGE.ALL_TIME_OVERVIEW' | translate}}</b></p>
        </div>
        <div *ngFor="let date of portalAppMapStrategy.availibleDates">
          <p *ngIf="date.includes(portalAppMapStrategy.searchedDate)" (click)="portalAppMapStrategy.dateChange(date)"
            [ngStyle]="{'background-color': (date.includes(portalAppMapStrategy.selectedDate)) ? '#daffde' : '#fff'}">
            {{date.substring(0, 10)}}
          </p>
        </div>
      </div>
    </div>
    <!-- <button (click)="generateColorsTMP()">test gen</button> -->



    <!-- <div class="option_container">
      <label class="switch">
          <input type="checkbox" value="treeMeasurementData-layer" (change)="portalAppMapStrategy.changeMapStyle($event)">
          <span class="slider"></span>
      </label>
      <p class="option_label">{{'i18n.FIELD_PAGE.SATELLITE' |
          translate}}</p>
  </div> -->

    <div class="global_devider"></div>
    <div class="map-actions-row">
      <div class="satellite-view">
        <mat-label>{{'i18n.FIELD_PAGE.MAP_STYLE' | translate}}</mat-label>
        <div class="option_container map_actions">
          <app-toggle-satellite-view></app-toggle-satellite-view>
        </div>
      </div>
      <div class="center-view">
        <mat-label>{{'i18n.FIELD_PAGE.MAP_ACTIONS' | translate}}</mat-label>
        <div class="option_container map_actions">
          <!-- <app-dynamic-importable-component *ngFor="let action of portalAppMapStrategy.mapActions" [component]="action"></app-dynamic-importable-component> -->
          <go-to-field-action-component></go-to-field-action-component>
        </div>
      </div>
    </div>


    <div class="global_devider"></div>
    <mat-label>{{'i18n.FIELD_PAGE.POINTS_SELECTION' | translate}}</mat-label>
    <div class="option_container point-selection">
      <app-dynamic-map-points-control *ngFor="let selection of portalAppMapStrategy.pointsSelections"
        [component]="selection"></app-dynamic-map-points-control>
    </div>

    <div *ngIf="!portalAppMapStrategy.showArchivedData && this.permissionService.hasRightTo('update-field')" class="global_devider"></div>
    <mat-label *ngIf="!portalAppMapStrategy.showArchivedData && this.permissionService.hasRightTo('update-field')">{{'i18n.FIELD_PAGE.POINTS_CREATION' |
      translate}}</mat-label>
    <div *ngIf="!portalAppMapStrategy.showArchivedData && this.permissionService.hasRightTo('update-field')" class="option_container point-creation">
      <app-dynamic-map-points-control *ngFor="let creation of portalAppMapStrategy.pointsCreation"
        [component]="creation"></app-dynamic-map-points-control>

    </div>

    <!--
  <div class="premarking-menu" #premarkingToggle>
    <div class="global_devider"></div>
    <mat-label>{{'i18n.FIELD_PAGE.PREMARKING_MENU' | translate}}</mat-label>
    <button (click)="togglePremarking()" class="toggle-premarking-menu">More actions</button>
  </div> -->


    <div class="global_devider"></div>
    <mat-label>{{'i18n.FIELD_PAGE.VISUALIZATION' | translate}}</mat-label>

    <map-visualize-by-attribute
    [attributes]="['i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_CIRCUMFERENCE', 'i18n.APP_SPECIFIC.PREMARKING.STATUS', 'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_TYPE', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.DAMAGED', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.AB_QUALITY', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.NOTE']"></map-visualize-by-attribute>

    <!-- Field is availbale by default -->
    <div class="option_container">
      <label class="switch">
        <input type="checkbox" value="fieldBoundery-layer"
          [ngModel]="portalAppMapStrategy.visibleLayers.includes('fieldBoundery-layer')"
          (change)="portalAppMapStrategy.showhideLayer($event)">
        <span class="slider"></span>
      </label>
      <p class="option_label">{{'i18n.FIELD_PAGE.FIELD'
        | translate}}</p>
    </div>

    <!-- Portal defined toggles -->
    <div *ngFor="let layer of portalAppMapStrategy.toggleLayers" class="option_container">
      <label class="switch">
        <input type="checkbox" value="{{layer.layerName}}"
          [ngModel]="portalAppMapStrategy.visibleLayers.includes(layer.layerName)"
          (change)="portalAppMapStrategy.showhideLayer($event)">
        <span class="slider"></span>
      </label>
      <p class="option_label">{{layer.title | translate}}</p>
    </div>

    <div class="option_container">
      <label class="switch">
        <input type="checkbox" value="archived-data" [ngModel]="portalAppMapStrategy.showArchivedData"
          (change)="portalAppMapStrategy.toggleArchivedPoints()">
        <span class="slider"></span>
      </label>
      <p class="option_label">{{'i18n.FIELD_PAGE.SHOW_ARCHIVED' | translate}}</p>
    </div>

    <button *ngIf="this.permissionService.hasRightTo('export-statistics')" class="global_btn export_btn" (click)="portalAppMapStrategy.exportTableToExcel()"
      [ngStyle]="{'display': (portalAppMapStrategy.visibleLayers.includes('treeMeasurementData-layer')) ? 'block' : 'none'}">{{'i18n.FIELD_PAGE.EXPORT_EXCEL'
      | translate}}</button>

  </div>

  <div *ngIf="this.portalAppMapStrategy.premarkingViewOpen" class="pre-marking-control-set-container">
    <p class="field_name" *ngIf="portalAppMapStrategy.selectedField">{{this.portalAppMapStrategy.selectedField.name}}‎
    </p>

    <mat-label>{{'i18n.FIELD_PAGE.PREMARKING_VIEW' | translate}}</mat-label>

    <div class="global_devider"></div>
    <div class="map-actions-row">
      <div class="satellite-view">
        <mat-label>{{'i18n.FIELD_PAGE.MAP_STYLE' | translate}}</mat-label>
        <div class="option_container map_actions">
          <app-toggle-satellite-view></app-toggle-satellite-view>
        </div>
      </div>
      <div class="center-view">
        <mat-label>{{'i18n.FIELD_PAGE.MAP_ACTIONS' | translate}}</mat-label>
        <div class="option_container map_actions">
          <!-- <app-dynamic-importable-component *ngFor="let action of portalAppMapStrategy.mapActions" [component]="action"></app-dynamic-importable-component> -->
          <go-to-field-action-component></go-to-field-action-component>
        </div>
      </div>
    </div>


    <div class="global_devider"></div>
    <mat-label>{{'i18n.FIELD_PAGE.POINTS_SELECTION' | translate}}</mat-label>
    <div class="option_container point-selection">
      <app-dynamic-map-points-control *ngFor="let selection of portalAppMapStrategy.pointsSelections"
        [component]="selection"></app-dynamic-map-points-control>

    </div>

    <div class="global_devider"></div>
    <mat-label>{{ 'i18n.APP_SPECIFIC.PREMARKING.THINNING' | translate}}</mat-label>
    <div class="feature-btns">

      <button (click)="handleDisplayThinning()" [ngStyle]="{'background-color' : portalAppMapStrategy.showWithoutThinnedView ? 'rgb(218, 255, 222)' : 'white'}" class="floating_btn">
        <svg *ngIf='!portalAppMapStrategy.showWithoutThinnedView' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/></svg>
        <svg *ngIf='portalAppMapStrategy.showWithoutThinnedView' fill="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
      </button>

      <button *ngIf="this.permissionService.hasRightTo('tree-thinning')" (click)="toggleToThinView()" [ngStyle]="{'background-color' : portalAppMapStrategy.isToThinnViewOpen ? 'rgb(218, 255, 222)' : 'white'}" class="floating_btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 192l-39.5-39.5c4.9-12.6 7.5-26.2 7.5-40.5C224 50.1 173.9 0 112 0S0 50.1 0 112s50.1 112 112 112c14.3 0 27.9-2.7 40.5-7.5L192 256l-39.5 39.5c-12.6-4.9-26.2-7.5-40.5-7.5C50.1 288 0 338.1 0 400s50.1 112 112 112s112-50.1 112-112c0-14.3-2.7-27.9-7.5-40.5L499.2 76.8c7.1-7.1 7.1-18.5 0-25.6c-28.3-28.3-74.1-28.3-102.4 0L256 192zm22.6 150.6L396.8 460.8c28.3 28.3 74.1 28.3 102.4 0c7.1-7.1 7.1-18.5 0-25.6L342.6 278.6l-64 64zM64 112a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48 240a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
      </button>
    </div>




    <div class="global_devider"></div>
    <mat-label>{{'i18n.FIELD_PAGE.VISUALIZATION' | translate}}</mat-label>

    <map-visualize-by-attribute
    [attributes]="['i18n.APP_SPECIFIC.PREMARKING.STATUS','i18n.APP_SPECIFIC.PREMARKING.MARKED_VALUE', 'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_CIRCUMFERENCE', 'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_TYPE', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.DAMAGED', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.AB_QUALITY', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.NOTE']"></map-visualize-by-attribute>

      <div class="option_container">
        <label class="switch">
          <input type="checkbox" value="fieldBoundery-layer"
            [ngModel]="portalAppMapStrategy.visibleLayers.includes('fieldBoundery-layer')"
            (change)="portalAppMapStrategy.showhideLayer($event)">
          <span class="slider"></span>
        </label>
        <p class="option_label">{{'i18n.FIELD_PAGE.FIELD'
          | translate}}</p>
      </div>

      <!-- Portal defined toggles -->
      <div *ngFor="let layer of portalAppMapStrategy.toggleLayers" class="option_container">
        <label class="switch">
          <input type="checkbox" value="{{layer.layerName}}"
            [ngModel]="portalAppMapStrategy.visibleLayers.includes(layer.layerName)"
            (change)="portalAppMapStrategy.showhideLayer($event)">
          <span class="slider"></span>
        </label>
        <p class="option_label">{{layer.title | translate}}</p>
      </div>

      <button *ngIf="this.permissionService.hasRightTo('export-statistics')" class="global_btn export_btn" (click)="portalAppMapStrategy.exportTableToExcel()"
      [ngStyle]="{'display': (portalAppMapStrategy.visibleLayers.includes('treeMeasurementData-layer')) ? 'block' : 'none'}">{{'i18n.FIELD_PAGE.EXPORT_EXCEL'
      | translate}}</button>


  </div>

  <div [ngClass]="{'disabled' : (!(!this.portalAppMapStrategy.isMutationOn && !this.portalAppMapStrategy.isToThinnViewOpen && premarkingAvailable))}" class="premarking-view-switch">
    <div class="cover"></div>
    <div class="switch-container" [ngClass]="{'premarking-selected' : this.portalAppMapStrategy.premarkingViewOpen}">
      <div class="switch">
        <span class="stm-label">{{ 'i18n.APP_SPECIFIC.PREMARKING.STM_LABEL' | translate}}</span>
        <span class="pre-label">{{ 'i18n.APP_SPECIFIC.PREMARKING.PREMARKING_LABEL' | translate}}</span>
      </div>
      <div class="switch-options">
        <div class="option" (click)="changePremarkingView(false)">{{ 'i18n.APP_SPECIFIC.PREMARKING.STM_LABEL' | translate}}</div>
        <div class="option" (click)="changePremarkingView(true)">{{ 'i18n.APP_SPECIFIC.PREMARKING.PREMARKING_LABEL' | translate}}</div>
      </div>
    </div>
  </div>
</div>
