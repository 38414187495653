import { Component, Input, OnInit , HostListener, OnDestroy, ChangeDetectionStrategy} from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'stem-thickness-measurement-mutation-component',
  templateUrl: './stem-thickness-measurement-mutation.component.html',
  styleUrls: ['./stem-thickness-measurement-mutation.component.css']
})

export class StemThicknessMeasurementMutation implements OnInit {
    @Input() isVisible: any = true;
    @Input() displayData: any;

    // to receive events
    private subscription: Subscription = new Subscription();

    circumference: number = null;
    bucketList = ["0-8","8-10", "10-12", "12-14", "14-16", "16-18", "18-20", "20-25", "25-30", "30-35", "35-40", "40-45", "45-50", "50-60", "60-70", "70-80", "80-90", "90-175", "175-200", "200-250", "250-300", "300-350", "350-400", "400-450", "450-500", "500-550", "550-600", "600-700", "700-800", "800-900", "900-*"];
    treeTypeInputFilter = "";
    selectedTreeType = "";
    damaged: boolean | null = null;
    hasPole: boolean | null = null;
    note = "";

    // ab
    abQualitySelected = "";
    abQualityList = ["a", "b", "c", "null"];
    showABQualiltyDropdown = false;

    // tree types
    availableTreeTypes = [];
    filteredTreeTypes = [];
    showTreeTypeDropdown = false;

    // custom data
    customDataFields: any[] = [];
    activeDropdown: string | null = null;

    //success message
    showSuccessMessage = false;

    // Add search functionality properties
    searchTerms: { [key: string]: string } = {};
    filteredOptions: { [key: string]: string[] } = {};

    get stmCustomDataFields() {
        return this.customDataFields.filter(field => field.contexts.includes('stm_portal'));
    }

    mapStrategy;
    constructor(private portalAppsService: PortalAppsService, private organizationService: OrganizationService, private eventBusService: EventBusService, private apiService: ApiService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }

    renderData(){
      if(this.displayData.value != null){
        this.circumference = this.displayData.value.circumference || null;
        this.selectedTreeType = this.displayData.value.treeType || "";
        this.damaged = this.displayData.value.damaged;
        this.hasPole = this.displayData.value.hasPole;
        this.note = this.displayData.value.note || "";
        this.abQualitySelected = this.displayData.value.abQuality || "";


        // Initialize custom data if it doesn't exist
        if (!this.displayData.value.custom_data) {
          this.displayData.value.custom_data = {};
        }

        // Initialize empty arrays for multiple choice fields

        this.customDataFields.forEach(field => {
          if (field.field_type === 'multiple_choice' && !this.displayData.value.custom_data[field.id]) {
            this.displayData.value.custom_data[field.id] = [];
          }
        });
      }
      else{
        this.circumference = null;
        this.selectedTreeType = "";
        this.damaged = null;
        this.hasPole = null;
        this.note = "";
        this.abQualitySelected = "";
        this.displayData.value = {
          custom_data: {}
        };
      }
      this.fetchTreeTypes();
    }


    findBucket(value: number): string | null {
      if(value){
        for (let i = 0; i < this.bucketList.length; i++) {
          const [min, max] = this.bucketList[i].split('-');

          if (max === '*') {
            // Handle the special case where max is '*', meaning any value >= min
            if (value >= Number(min)) {
              return this.bucketList[i];
            }
          } else {
            // Standard bucket check
            const minNum = Number(min);
            const maxNum = Number(max);


            if (value >= minNum && value < maxNum) {
              return this.bucketList[i];
            }
          }
        }

        // If no bucket is found, return null or an appropriate message
        return null;
      }
    }




    ngOnInit(): void {
      this.getCustomDataFields();
      this.subscription.add(
        this.eventBusService.events$.subscribe(data => {
          // Handle the event
          if(data.target == "mutationComponent"){
            if(data.action == "update"){
              this.renderData();
            }
            if(data.action == "success"){
              this.showSuccessMessage = true;
            }
          }
        })
      );
      this.renderData();
      this.treeTypeInputFilter = "";
      this.showSuccessMessage = false;
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }

    // TREE TYPES SELECTION
    fetchTreeTypes(): void{
      this.organizationService.get_tree_types().subscribe(data => {
        this.availableTreeTypes = data.collection.sort(function (a, b) { return (a.name > b.name) ? 1 : -1 });
        this.filterTreeTypes();
      });
    }

    private isDropdownClicked: boolean = false;

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
      const target = event.target as HTMLElement;

      let containerTreeType = document.getElementsByClassName("tree-type-selector")[0];
      let containerABQulaity = document.getElementsByClassName("ab-quality-dropdown")[0];
      let containerChoiceDropdowns = document.getElementsByClassName("choice-dropdown");
      let choiceButtons = document.getElementsByClassName("choice-selector");
      let multipleChoiceButtons = document.getElementsByClassName("multiple-choice-selector");

      // Check if the click was inside the tree type dropdown
      if(!containerTreeType.contains(target)){
        this.showTreeTypeDropdown = false;
      }

      // Check if the click was inside the AB dropdown
      if(!containerABQulaity.contains(target)){
        this.showABQualiltyDropdown = false;
      }

      // Check if the click was outside any choice dropdown or its button
      let clickedInsideChoiceDropdown = false;

      // Check if click was on a choice button
      for (let i = 0; i < choiceButtons.length; i++) {
        if (choiceButtons[i].contains(target)) {
          clickedInsideChoiceDropdown = true;
          break;
        }
      }

      // Check if click was on a multiple choice button
      for (let i = 0; i < multipleChoiceButtons.length; i++) {
        if (multipleChoiceButtons[i].contains(target)) {
          clickedInsideChoiceDropdown = true;
          break;
        }
      }

      // Check if click was inside any dropdown
      for (let i = 0; i < containerChoiceDropdowns.length; i++) {
        if (containerChoiceDropdowns[i].contains(target)) {
          clickedInsideChoiceDropdown = true;
          break;
        }
      }

      if (!clickedInsideChoiceDropdown) {
        this.activeDropdown = null;
      }
    }

    filterTreeTypes(): void{
      let tempTreeTypes = JSON.parse(JSON.stringify(this.availableTreeTypes)); // makeing a deep copy to avoid referencing.
      if(this.treeTypeInputFilter != ""){
        tempTreeTypes = tempTreeTypes.filter((item) => {
          return item.name.includes(this.treeTypeInputFilter)
        })
      }
      this.filteredTreeTypes = tempTreeTypes;
    }

    showTreeTypeDropDown(): void{
      this.showTreeTypeDropdown = true;
      this.treeTypeInputFilter = "";
    }

    onFocusOutTreeTypeInput(event): void{
      const dropdown = document.getElementsByClassName("tree-type-dropdown")[0];
      if(dropdown){
        if(!this.isDropdownClicked){
          this.showTreeTypeDropdown = false;
        }
      }

    }

    onTypeTreeTypeInput(event): void{
      this.showTreeTypeDropdown = true;
      this.filterTreeTypes();
    }

    onTreeTypeSelect(treeTypeSelected){
      this.selectedTreeType = treeTypeSelected.name;
      this.showTreeTypeDropdown = false;
      this.filteredTreeTypes = JSON.parse(JSON.stringify(this.availableTreeTypes));
    }

    // ab quality
    handleChooseABQualityButton(): void{
      this.showABQualiltyDropdown = true;
    }

    handleSelectABQuality(item): void{
      if(item != "null"){
        this.abQualitySelected = item;
      }
      else{
        this.abQualitySelected = "";
      }

      this.showABQualiltyDropdown = false;
    }

    getCustomDataFields(){
      const appId = '7fa63b0e-ee95-416d-8fa9-cc5dccfa2c4f';
      this.apiService.get_with_auth(`/custom-data/definitions?app_id=${appId}`).subscribe(data => {
        this.customDataFields = data.collection;
      })
    }

    showChoiceDropdown(field: any) {
        this.activeDropdown = this.activeDropdown === field.id ? null : field.id;
        console.log(this.activeDropdown);
        if (this.activeDropdown === field.id) {
            // Initialize filtered options when opening dropdown
            this.filteredOptions[field.id] = [...field.options.values];
            this.searchTerms[field.id] = '';
        }
    }

    filterOptions(fieldId: string) {
        const searchTerm = this.searchTerms[fieldId]?.toLowerCase() || '';
        const field = this.customDataFields.find(f => f.id === fieldId);
        if (field) {
            this.filteredOptions[fieldId] = field.options.values.filter(option =>
                option.toLowerCase().includes(searchTerm)
            );
        }
    }

    getFilteredOptions(fieldId: string): string[] {
        return this.filteredOptions[fieldId] || [];
    }

    selectChoice(field: any, option: string) {
        if (!this.displayData.value.custom_data) {
            this.displayData.value.custom_data = {};
        }
        this.displayData.value.custom_data[field.id] = option;
        this.activeDropdown = null;
    }

    toggleChoice(field: any, option: string) {
        if (!this.displayData.value.custom_data) {
            this.displayData.value.custom_data = {};
        }
        if (!this.displayData.value.custom_data[field.id]) {
            this.displayData.value.custom_data[field.id] = [];
        }
        const index = this.displayData.value.custom_data[field.id].indexOf(option);
        if (index === -1) {
            this.displayData.value.custom_data[field.id].push(option);
        } else {
            this.displayData.value.custom_data[field.id].splice(index, 1);
        }
    }

    async applyMutationChanges(){
      const inputs = {
        circumference : this.circumference,
        bucket : this.findBucket(this.circumference),
        treeType : this.selectedTreeType != "" ? this.selectedTreeType : null,
        hasPole : this.hasPole,
        damaged : this.damaged,
        note : this.note != "" ? this.note : false,
        abQuality : this.abQualitySelected != "" ? this.abQualitySelected : null,
        custom_data: this.displayData.value.custom_data || {}
      }
      this.mapStrategy.mutateSelectedPoints(inputs);
    }
}
