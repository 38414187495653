import { Component } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pre-marking-apply-thinnig',
  templateUrl: './pre-marking-apply-thinnig.component.html',
  styleUrls: ['./pre-marking-apply-thinnig.component.css']
})
export class PreMarkingApplyThinnigComponent {
  portalAppMapStrategy: any;

  // to receive events
  private subscription: Subscription = new Subscription();

  showSuccessMessage = false;

  showErrorSelectionMessage = false;

  thinningType = 'selection';

  // patern
  treesToKeep;
  treesToSkip;

  toThinPercentageInput = 0;

  // New properties for filtering
  minSize: number = 0;
  selectedTreeType: string = 'all';
  excludeDamaged: boolean = false;
  treeTypes: any[] = []; // Array to store tree types
  selectedTreeTypeName: string = 'all'; // New property to store the actual name

  isManualRowSelectionEnabled = false;
  // disabled
  generateGuideRowsForThinning(){
    this.portalAppMapStrategy.generateGuideRowsForThinning();
  }

  toggleRowSelectionForThinning(isOn){
    this.portalAppMapStrategy.isRowSelectionForThiningEnabled = isOn;
  }

  constructor(private portalAppsService: PortalAppsService, private organizationService: OrganizationService, private eventBusService: EventBusService){}

  ngOnInit() {
    this.portalAppMapStrategy = this.portalAppsService.activeApp.mapStrategy;

    // Fetch tree types
    this.organizationService.get_tree_types().subscribe({
      next: (response) => {
        this.treeTypes = response.collection;
        if (this.treeTypes.length > 0) {
          this.selectedTreeType = this.treeTypes[0].id;
          this.selectedTreeTypeName = this.treeTypes[0].name;
        }
      },
      error: (error) => {
        console.error('Error fetching tree types:', error);
      }
    });

    // check for premarking availability
    if(this.portalAppMapStrategy.floatingContainerData.value.count >= 2) this.showErrorSelectionMessage = false;
    else this.showErrorSelectionMessage = true;

    this.subscription.add(
      this.eventBusService.events$.subscribe(data => {
        // Handle the event
        if(data.target == "premarkingThinningComponent"){
          if(data.action == "success"){
            this.showSuccessMessage = true;

          }
          if(data.action == "update"){
            if(this.portalAppMapStrategy.floatingContainerData.value.count >= 2) this.showErrorSelectionMessage = false;
            else this.showErrorSelectionMessage = true;
          }
        }
      })
    );
  }

  clearGuideRows(){
    this.portalAppMapStrategy.clearGuideRowsForThinning();
  }

  handleShowThinningResult(){
    this.portalAppMapStrategy.showThinningResultOnGuideRows(
      this.treesToKeep,
      this.treesToSkip,
      {
        minSize: this.minSize,
        treeType: this.selectedTreeTypeName,
        excludeDamaged: this.excludeDamaged
      }
    );
  }

  clearPatern(){
    this.portalAppMapStrategy.clearToBeThinnedPatern();
  }

  applyThinning(){
    this.portalAppMapStrategy.saveThinnedTrees();
  }

  changeThinningType(type){
      this.thinningType = type;
  }

  showThinningBySelection(){
    const convertedPercentage = this.toThinPercentageInput / 100;
    this.portalAppMapStrategy.showThinningBySelection(
      convertedPercentage,
      {
        minSize: this.minSize,
        treeType: this.selectedTreeTypeName,
        excludeDamaged: this.excludeDamaged
      }
    );
  }

  // Add method to handle tree type selection
  onTreeTypeSelect(event: any) {
    const selectedId = event.target.value;
    if (selectedId === 'all') {
      this.selectedTreeTypeName = 'all';
    } else {
      const selectedType = this.treeTypes.find(type => type.id === selectedId);
      if (selectedType) {
        this.selectedTreeTypeName = selectedType.name;
      }
    }
  }

}
