<div class="help-page-wrapper">
    <!-- <h1>Frequently asked questions</h1>
    <div class="faq-container">

        <div class="search-bar-container">
            <input type="text" placeholder="">
            <button>Search</button>
        </div>
        
        <div class="faq-list">
            <div *ngFor="let subject of faqItmes" class="subject">
                <span (click)="handleFAQItemClick($event)">{{subject.subject}}</span>

                <div class="subject-questions">
                    <div *ngFor="let subquestion of subject.subquestions" class="sub-question">
                        <span (click)="handleFAQItemClick($event)">{{subquestion.subquestion_name}}</span>
                        <div class="sub-question-answer">
                            <p [innerHTML]="subquestion.htmlAnswer"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    
    <h1>{{'i18n.HELP_PAGE.MANUALS_TITLE' | translate}}</h1>
    <div class="manuals-container">
        <div *ngFor="let manual of manuals" class="manual">
            <h3>{{manual.title | translate}}</h3>
            <p class="description">
                {{manual.description}}
            </p>
            <button (click)="downloadPDF(manual.title, manual.downloadLink)">{{'i18n.HELP_PAGE.DOWNLOAD' | translate}}</button>
        </div>

    </div>

    <h1>{{'i18n.HELP_PAGE.CONTACTS_TITLE' | translate}}</h1>
    <div class="contacts-container">
        <div class="left">
            <!-- <div class="logo-container">
                <img src="assets/Agrowizard-Logo-vDEF.jpg">
            </div> -->
            <h3>
                {{'i18n.HELP_PAGE.GET_IN_TOUCH_TEXT' | translate}}
            </h3>
            <ul class="contacts-info">
                <li>
                    <span class="contact-item">Email: </span>
                    <span>servicedesk@agrowizard.com</span>
                </li>
                <li>
                    <span class="contact-item">{{'i18n.HELP_PAGE.PHONE' | translate}}: </span>
                    <span>+31 (0)6 43 12 72 92</span>
                </li>
                <li>
                    <span class="contact-item">WhatsApp for Business: </span>
                    <span>+31 (0)6 43 12 72 92</span>
                </li>
            </ul>
        </div>
        <div class="right">
            <!-- <div class="form">
                <div class="form-item">
                    <label>Name</label>
                    <input type="text">
                </div>
                <div class="form-item">
                    <label>Email</label>
                    <input type="text">
                </div>
                <div class="form-item">
                    <label>Subject</label>
                    <input type="text">
                </div>
                <div class="form-item message">
                    <label>Message</label>
                    <textarea></textarea>
                </div>
                <div class="form-item">
                    <button>Send</button>
                </div>
            </div> -->
            <div class="form">
                <div class="logo-container">
                    <img src="assets/Logo-dark-bg.png">
                </div>
            </div>
        </div>
    </div>

</div>