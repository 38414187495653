import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { OrganizationService } from 'src/app/services/organization.service';
import { IListResponse } from 'src/app/models/global.model';
import { IMember, IOrganization, IRole, ITreeType } from 'src/app/models/organization.model';
import { IField } from 'src/app/models/field.model';
import { FieldService } from 'src/app/services/field.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { CacheService } from 'src/app/services/cache.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-company-management-page',
  templateUrl: './company-management-page.component.html',
  styleUrls: ['./company-management-page.component.css']
})
export class CompanyManagementPageComponent implements OnInit {
  userDataForm: UntypedFormGroup;

  constructor(
    private organizationService: OrganizationService, private fieldService: FieldService, private portalAppsService: PortalAppsService, private router: Router, private userService: UserService, private cacheService: CacheService, private auth: AuthService, public permissionService: PermissionService

  ) { }

  // organizations
  organizationsList = [
  ]



  //tree type pagination
  nrPerPageTreeType = 5;
  currentPageTreeType = 1;
  totalPagesTreeType = 0;

  //fields pagination
  nrPerPageFields = 5;
  currentPageFields = 1;
  totalPagesFields = 0;

  // add field vars
  selectAppPopupOpen = false;
  availableApps;
  filteredApps;
  searchAppInput = "";
  appOptionsVisible = false;
  selectedApp = null;

  newUserModal = false;
  newTreeTypeModal = false;

  // Roles
  newRoleModal = false;
  roleEdit = false;
  newRoleNameInputNL = "";
  newRoleNameInputEN = "";
  copyPermissionsFrom = "None";
  deleteRoleModal = null;

  searchTreeType = '';
  searchFields = '';

  organization: IOrganization;
  roles: IRole[];
  members: IMember[];
  treeTypes: ITreeType[] = [];
  treeTypesFiltered: ITreeType[] = [];
  treeTypesUnfiltered: ITreeType[] = [];

  fields: IField[] = [];
  fieldsFiltered: IField[] = [];
  fieldsUnfiltered: IField[] = [];




  email_invalid = false;
  invite_success = false;
  invite_error = null;

  async getOrganizationRoles(){
    this.organizationService.get_roles().subscribe(data => {
      this.roles = data.collection.sort(function (a, b) {
        const priorityRoles = ["admin", "operator", "member"]; // Replace with your specific role names

        const aPriority = priorityRoles.includes(a.slug) ? 1 : 0;
        const bPriority = priorityRoles.includes(b.slug) ? 1 : 0;

        // Prioritize specific roles first
        if (aPriority !== bPriority) {
            return bPriority - aPriority; // Moves priority roles to the top
        }

        // Secondary sorting: By permissions length (descending)
        return b.permissions.length - a.permissions.length;

      });

    });
  }

  async ngOnInit() {

    this.organizationService.get_members().subscribe(data => {
      this.members = data.collection;
    });


    this.organizationService.get_organization().subscribe(data => {
      this.organization = data;
    });

    this.userService.get_user().subscribe(data => {
      this.organizationsList = data.organizations;
    })

    this.getOrganizationRoles();

    this.organizationService.get_tree_types().subscribe(data => {
      this.treeTypesUnfiltered = data.collection.sort(function (a, b) { return (a.name > b.name) ? 1 : -1 });
      this.paginateTreeTypes()
      this.changeAmountTreeType(this.nrPerPageTreeType)
    });

    this.getFields()

    this.availableApps = await this.portalAppsService.getAllAvaliableApps();
    this.filteredApps = [...this.availableApps];
  }

  async switchOrganization(organization){
    // fetch details
    localStorage.setItem('org_id', JSON.stringify(organization));

    this.cacheService.removeData('fields-overview-data');

    this.organizationService.get_members().subscribe(data => {
      this.members = data.collection;
    });


    this.organizationService.get_organization().subscribe(data => {
      this.organization = data;
    });

    this.userService.get_user().subscribe(data => {
      localStorage.setItem('userPermissions',JSON.stringify( data.permissions[JSON.parse(localStorage.getItem('org_id')).id])) ;
    })


    this.getOrganizationRoles();

    this.organizationService.get_tree_types().subscribe(data => {
      this.treeTypesUnfiltered = data.collection.sort(function (a, b) { return (a.name > b.name) ? 1 : -1 });
      this.paginateTreeTypes()
      this.changeAmountTreeType(this.nrPerPageTreeType)
    });

    this.getFields()

    this.availableApps = await this.portalAppsService.getAllAvaliableApps();
    this.filteredApps = [...this.availableApps];
  }

  // paginate tree type list

  changeTreeTypePage(page: number) {
    if (this.currentPageTreeType + page <= this.totalPagesTreeType && this.currentPageTreeType + page > 0) {
      this.currentPageTreeType = this.currentPageTreeType + page;
    }
    this.paginateTreeTypes()
  }

  setPageTreeType(page: number) {
    this.currentPageTreeType = page;
    this.paginateTreeTypes();
  }

  changeAmountTreeType(amount: number) {
    this.nrPerPageTreeType = amount;
    this.currentPageTreeType = 1;
    this.paginateTreeTypes();
  }

  paginateTreeTypes() {
    this.treeTypes = [];
    this.treeTypesFiltered = this.treeTypesUnfiltered.filter(type => type.name.toUpperCase().includes(this.searchTreeType)).sort(function (a, b) { return (a.name > b.name) ? 1 : -1 })
    this.totalPagesTreeType = Math.ceil(this.treeTypesFiltered.length / this.nrPerPageTreeType);
    this.treeTypes = this.treeTypesFiltered.sort().slice((this.currentPageTreeType - 1) * this.nrPerPageTreeType, this.currentPageTreeType * this.nrPerPageTreeType)
  }

  // paginate fields list

  changeFieldsPage(page: number) {
    if (this.currentPageFields + page <= this.totalPagesFields && this.currentPageFields + page > 0) {
      this.currentPageFields = this.currentPageFields + page;
    }
    this.paginateFields()
  }

  setPageFields(page: number) {
    this.currentPageFields = page;
    this.paginateFields();
  }

  changeAmountFields(amount: number) {
    this.nrPerPageFields = amount;
    this.currentPageFields = 1;
    this.paginateFields();
  }

  paginateFields() {
    this.fields = [];
    this.fieldsFiltered = this.fieldsUnfiltered.filter(field => field.name.toUpperCase().includes(this.searchFields))
    this.totalPagesFields = Math.ceil(this.fieldsFiltered.length / this.nrPerPageFields);
    this.fields = this.fieldsFiltered.sort().slice((this.currentPageFields - 1) * this.nrPerPageFields, this.currentPageFields * this.nrPerPageFields)
  }

  // managing fields

  addFieldClick(){
    this.selectAppPopupOpen = true;
  }

  closeAppSelectorPopup(event){
    if(event.target.classList.contains("choose-app-popup-background")){
      this.selectAppPopupOpen = false;
    }

  }

  filterAppSelectionOptions(input){
    this.searchAppInput = input;
    this.filteredApps = this.availableApps.filter(app => app.name.includes(this.searchAppInput));
  }

  navigateToTheAppAddField(app){
    this.portalAppsService.setActiveApp(app);
    const innerRoute = "fields/add";
    const url = "/apps/" + app.codeName;
    this.router.navigate([url], {queryParams: {navigateTo: innerRoute }})
  }

  archiveField(field: any) {
    this.fieldService.archive_field(field).subscribe(d => {
      this.getFields();
    });
  }
  unarchiveField(field: any) {
    this.fieldService.unarchive_field(field).subscribe(d => {
      this.getFields();
    });
  }

  getFields() {
    this.fieldService.get_fields(0, 1000, true).subscribe(data => {
      let raw_fields: IListResponse<IField> = data;
      this.fieldsUnfiltered = raw_fields.items.sort(function (a, b) { return (a.name > b.name) ? 1 : -1 });
      this.paginateFields()
      this.changeAmountFields(this.nrPerPageFields)
    });
  }

  // managing members

  addMember(email: string, role: string) {
    if (email.length < 4 || email.indexOf('@') === -1) {
      this.email_invalid = true
      return
    }
    this.email_invalid = false;
    this.organizationService.add_member(email, role).subscribe(
      d => {
        this.email_invalid = false;
        this.invite_error = false;
        this.invite_success = true;
      },
      err => this.invite_error = err.error.detail,
    );

  }

  changeMemberRole(memberID: string, roleID: string) {
    this.organizationService.set_member_role(memberID, roleID).subscribe(d => {

    });
  }

  deleteMember(memberID: string) {
    this.organizationService.delete_member(memberID).subscribe(d => {
      this.members = this.members.filter(obj => { return obj.id !== memberID });
    });
  }

  checkRoles(permissions, perm) {
    if (permissions.some(permission => permission.id === perm)) {
      return true
    }
    return false;

  }

  // editing tree type row

  editTreeType(event, tree_type_id) {

    // (event.target as Element).parentElement.parentElement
    var nodes = (event.target as Element).parentElement.parentElement.getElementsByTagName('input');

    if ((nodes[0] as HTMLInputElement).disabled == false) {
      for (var i = 0; i < nodes.length; i++) {
        (nodes[i] as HTMLInputElement).disabled = true;
        if ((nodes[i] as HTMLInputElement).value != '') {
          //update it
          this.organizationService.patch_tree_type(tree_type_id, (nodes[i] as HTMLInputElement).value).subscribe(d => {

          });
        } else {
          //delete it
          this.organizationService.delete_tree_type(tree_type_id).subscribe(d => {
          });
        }
      }
      (event.target as Element).textContent = "Aanpassen"
    } else {
      for (var i = 0; i < nodes.length; i++) {
        (nodes[i] as HTMLInputElement).disabled = false;
      }
      (event.target as Element).textContent = "Opslaan"
    }
  }

  deleteTreeType(treeTypeId) {
    this.organizationService.delete_tree_type(treeTypeId).subscribe(d => {
      this.treeTypesUnfiltered.splice(this.treeTypesUnfiltered.findIndex(a => a.id === treeTypeId), 1);
      this.paginateTreeTypes()
    });
  }

  addTreeType(tree_type: string) {
    if (tree_type == '') {
      return
    }

    this.organizationService.add_tree_type(tree_type).subscribe(d => {
      this.newTreeTypeModal = !this.newTreeTypeModal
      this.treeTypesUnfiltered.push(d)
      this.paginateTreeTypes()
    });

  }

  sort(event) {
    // use this to select on what column to sort the data
    (event.target as Element).textContent += "▼▲"
  }

  update_search_tree_type(search_val) {
    this.searchTreeType = search_val.target.value.toUpperCase();
    this.currentPageTreeType = 1
    this.paginateTreeTypes();
  }

  update_search_fields(search_val) {
    this.searchFields = search_val.target.value.toUpperCase();
    this.currentPageFields = 1
    this.paginateFields();
  }

  toggleRoleModal(){
    this.newRoleModal = !this.newRoleModal;

    if(!this.newRoleModal){
      this.newRoleNameInputEN = "";
      this.newRoleNameInputNL = "";
      this.copyPermissionsFrom = "None";
    }
  }

  toggleRoleEdit(){
    this.roleEdit = !this.roleEdit;
  }

  createNewRole(){
    const newRole: any = {
      id: "",
      slug: this.newRoleNameInputEN.toLowerCase().replace(' ', "-"),
      name: JSON.stringify({en: this.newRoleNameInputEN, nl: this.newRoleNameInputNL}),
      description: "",
      permissions: [],
      organization_id : JSON.parse(localStorage.getItem('org_id')).id
    }


    this.organizationService.addRole(newRole).subscribe(data => {
      if(this.copyPermissionsFrom != "None"){
        const copyRole = this.roles.find(role => role.name == this.copyPermissionsFrom);


        if(copyRole){
          newRole.permissions = copyRole.permissions;
          newRole.id = data.role_id;
          this.organizationService.updateRolePermissions(newRole).subscribe(data2 => {
            this.getOrganizationRoles();
            this.toggleRoleModal();


          });
        }
      }
      else{
        this.getOrganizationRoles();
        this.toggleRoleModal();
      }
    })
  }

  handleRolePermissionsEdit() {
    const updatedInfo = this.roles.slice(3); // Exclude first 3 roles (base roles)

    // Find all checked checkboxes inside the role table
    const role_table = document.getElementsByClassName('role_table')[0];
    const checkedBoxes = role_table.querySelectorAll('input[type="checkbox"]:checked');

    // Filter checkboxes with valid IDs and extract their values
    const allCheckBoxesWithIds = Array.from(checkedBoxes).filter(cb => cb.id.trim() !== "");
    const checkedIds = allCheckBoxesWithIds.map(cb => cb.id);

    // Loop through checked checkboxes to ADD permissions if missing
    checkedIds.forEach(checkboxId => {
        const permissionId = checkboxId.split('.')[0].split('_')[1];
        const roleId = checkboxId.split('.')[1].split('_')[1];

        const roleIndexToEdit = updatedInfo.findIndex(roleTemp => roleTemp.id === roleId);
        if (roleIndexToEdit !== -1) {
            // Check if permission already exists
            const foundPermissionIndex = updatedInfo[roleIndexToEdit].permissions.findIndex(permissionTemp => permissionTemp.id === permissionId);
            if (foundPermissionIndex === -1) {
                // Copy permission from the "admin" role (roles[0])
                const permissionToAdd = this.roles[0].permissions.find(permTemp => permTemp.id == permissionId);
                if (permissionToAdd) {
                    updatedInfo[roleIndexToEdit].permissions.push(permissionToAdd);
                }
            }
        }
    });

    // Loop through roles and REMOVE unchecked permissions
    (updatedInfo as any).forEach(role => {
        // Get permission IDs currently assigned to the role
        const currentPermissionIds = role.permissions.map(perm => perm.id);

        // Get permissions that should be removed (those NOT in checkedIds)
        role.permissions = role.permissions.filter(perm => {
            const permissionCheckboxId = `p_${perm.id}.r_${role.id}`;
            return checkedIds.includes(permissionCheckboxId);
        });
    });

    const requests = updatedInfo.map(role => {
      return this.organizationService.updateRolePermissions(role);
    });

    forkJoin(requests).subscribe({
      next: responses => {
        console.log('All roles updated!', responses)
        this.getOrganizationRoles();
        this.toggleRoleEdit();
      },
      error: err => console.error('Error updating roles:', err)
    });

}

  toggleDeleteRoleModal(role){
    if(this.deleteRoleModal){
      this.deleteRoleModal = null
    }
    else{
      this.deleteRoleModal = role;
    }
  }

  handleDeleteRole(){
    this.organizationService.deleteRole(this.deleteRoleModal.id).subscribe(data => {
      this.deleteRoleModal = null;
      this.getOrganizationRoles();
      this.roleEdit = false;
    })
  }
}
