import { Component, Input, OnInit, ViewContainerRef } from "@angular/core";

@Component({
    selector: 'app-dynamic-navbar-content', // Define your custom HTML tag here
    template: '', // Since this is a dynamic component, it doesn't need its own template
  })
export class DynamicNavbarContentComponent implements OnInit {

    @Input() component: any;
    @Input() codeName: any;

    constructor(public viewContainerRef: ViewContainerRef) { }

    ngOnInit() {
      this.viewContainerRef.clear();
      if(this.component){
        const componentRef = this.viewContainerRef.createComponent(this.component);
        (componentRef.instance as DynamicNavbarContentComponent).codeName = this.codeName;
      }

    }
  }
