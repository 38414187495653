<div class="tree-measurement-data-component">
  <h3>{{'i18n.APP_SPECIFIC.PREMARKING.MUTATE' | translate}}</h3>

  <div class="tree-measurement-data-overview">
      <table>
          <tr>
              <th>
                  {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.PROPERTY' | translate}}
              </th>
              <th>
                  {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.VALUE' | translate}}
              </th>
          </tr>
          <tr *ngIf="this.permissionService.hasRightTo('tree-thinning')">
              <td>{{'i18n.APP_SPECIFIC.PREMARKING.MARKED_VALUE' | translate}}</td>
              <td><input [(ngModel)]="circumference"  placeholder="0.0" type="number" step="0.1" ></td>
          </tr>
          <tr *ngIf="this.permissionService.hasRightTo('tree-thinning')">
              <td>{{'i18n.APP_SPECIFIC.PREMARKING.THINNED' | translate}}</td>
              <td>
                <div class="bool-option-container">
                  <label>
                      <input name="thinned" type="radio" [(ngModel)]="thinned" [value]="true">
                      {{'i18n.STATISTIC_PAGE.STM_STATISTICS.TRUE' | translate}}
                  </label>
                  <label>
                      <input name="thinned" type="radio" [(ngModel)]="thinned" [value]="false">
                      {{'i18n.STATISTIC_PAGE.STM_STATISTICS.FALSE' | translate}}
                  </label>
                  <label class="do-not-change">
                    <input name="thinned" type="radio" [(ngModel)]="thinned" [value]="null">
                    {{'i18n.APP_SPECIFIC.STM.DO_NOT_CHANGE' | translate}}
                </label>
                </div>
              </td>
          </tr>
          <tr>
            <td>{{'i18n.APP_SPECIFIC.PREMARKING.STATUS' | translate}}</td>
            <td>
              <select [(ngModel)]="selectedStatus" (change)="onStatusChange($event.target.value)">
                <option *ngFor="let option of statusOptions" [value]="option.value">{{option.label | translate}}</option>
              </select>
            </td>
          </tr>

          <ng-container *ngIf="premarkingCustomDataFields.length > 0">
            <tr *ngFor="let field of premarkingCustomDataFields" class="custom-data">
              <td>{{field.name}}</td>
              <td>
                <ng-container [ngSwitch]="field.field_type">
                  <!-- Text field -->
                  <input *ngSwitchCase="'text'"
                         [(ngModel)]="displayData.value.custom_data[field.id]"
                         [placeholder]="'i18n.GLOBAL.ENTER_TEXT' | translate"
                         type="text">

                  <!-- Number field -->
                  <input *ngSwitchCase="'number'"
                         [(ngModel)]="displayData.value.custom_data[field.id]"
                         [placeholder]="'i18n.GLOBAL.ENTER_NUMBER' | translate"
                         type="number">

                  <!-- Single choice field -->
                  <div *ngSwitchCase="'single_choice'" class="choice-selector">
                    <button (click)="showChoiceDropdown(field)">
                      <span *ngIf="displayData.value.custom_data ? !displayData.value.custom_data[field.id] : true">{{'i18n.GLOBAL.SELECT' | translate}}</span>
                      <span *ngIf="displayData.value.custom_data ? displayData.value.custom_data[field.id] : false">{{displayData.value.custom_data[field.id]}}</span>
                    </button>
                    <div class="choice-dropdown" *ngIf="activeDropdown === field.id">
                      <div class="search-container">
                        <input
                          type="text"
                          [placeholder]="'i18n.GLOBAL.SEARCH' | translate"
                          [(ngModel)]="searchTerms[field.id]"
                          (input)="filterOptions(field.id)"
                          class="search-input"
                        >
                      </div>
                      <ul>
                        <li *ngFor="let option of getFilteredOptions(field.id)"
                            (click)="selectChoice(field, option)">
                          {{option}}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- Multiple choice field -->
                  <div *ngSwitchCase="'multiple_choice'" class="multiple-choice-selector">
                    <button (click)="showChoiceDropdown(field)">
                      <span *ngIf="displayData.value.custom_data ? !displayData.value.custom_data[field.id]?.length : true">{{'i18n.GLOBAL.SELECT' | translate}}</span>
                      <span *ngIf="displayData.value.custom_data ? displayData.value.custom_data[field.id]?.length : false">
                        {{displayData.value.custom_data[field.id].join(', ')}}
                      </span>
                    </button>
                    <div class="choice-dropdown" *ngIf="activeDropdown === field.id">
                      <div class="search-container">
                        <input
                          type="text"
                          [placeholder]="'i18n.GLOBAL.SEARCH' | translate"
                          [(ngModel)]="searchTerms[field.id]"
                          (input)="filterOptions(field.id)"
                          class="search-input"
                        >
                      </div>
                      <ul>
                        <li *ngFor="let option of getFilteredOptions(field.id)">
                          <label>
                            <input type="checkbox"
                                   [checked]="displayData.value.custom_data[field.id]?.includes(option)"
                                   (change)="toggleChoice(field, option)">
                            {{option}}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- Default case -->
                  <input *ngSwitchDefault
                         [(ngModel)]="displayData.value.custom_data[field.id]"
                         type="text">
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </table>
    </div>

    <div *ngIf="this.showSuccessMessage" class="success-messaage">
        <div class="message">
            {{'i18n.APP_SPECIFIC.STM.MUTATION_SUCCESS_MESSAGE' | translate}}
        </div>
        <div class="progress-bar">
            <div class="progress-bar-filler"></div>
        </div>
    </div>

    <button *ngIf="!this.showSuccessMessage" (click)="applyMutationChanges()">{{'i18n.APP_SPECIFIC.STM.APPLY_MUTATION_CHANGES' | translate}}</button>

</div>
