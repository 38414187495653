<div class="visualize-by-attribute" [ngClass]="{'toggled' : listOpen}">
  <div class="btn" (click)="toggleList()">
    <span class="title">{{'i18n.FIELD_PAGE.COLOR_BY' | translate}}</span>
    <span  class="selected">{{labelMap[selectedAttribute] | translate}}</span>
  </div>

  <ul class="list" *ngIf="listOpen">
    <li (click)="handleSelectAttribute(attribute)" *ngFor="let attribute of attributes">{{labelMap[attribute] | translate}}</li>
  </ul>
</div>
