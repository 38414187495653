import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import { IField } from 'src/app/models/field.model';
import { FieldService } from 'src/app/services/field.service';
import { IPortalApp } from 'src/app/services/portal-apps/iportal-app';
import { IMapStrategy } from 'src/app/services/portal-apps/map-strategies/iMapStrategy';
import { companyLogoLayer } from 'src/app/services/portal-apps/map-strategies/map-layers/layers/company-logo-layer.function';
import { fieldBoundaryLayer } from 'src/app/services/portal-apps/map-strategies/map-layers/layers/field-boundery-layer.function';
import { companyLogoSource } from 'src/app/services/portal-apps/map-strategies/map-layers/sources/company-logo-source.function';
import { fieldBoundarySource } from 'src/app/services/portal-apps/map-strategies/map-layers/sources/field-boundary-source.function';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-field-map-page',
  templateUrl: './field-map-page.component.html',
  styleUrls: ['./field-map-page.component.css']
})
export class FieldMapPageComponent implements OnDestroy {
  map: mapboxgl.Map;

  constructor(private activatedRoute: ActivatedRoute, private fieldService: FieldService, private portalAppsService: PortalAppsService) { }
  portalAppMapStrategy: IMapStrategy;

  // MAP VARIABLES
  startZoom = 16

  // right menu close prop
  rightMenuScroll = 0;


  // tree measurment data layer storage
  dataStore_TreeMeasurmentData: { [date: string]: {} } = {}; // for excel export





  // HOVER DATA INFO
  metaHoverData: any = {
    value: {}
  };
  mainHoverData: any = {
    value: {
    }
  }
  customerAddedTreeData = []

  // Side info menu toggle
  showEntityInfo = {value: false};

  ngOnDestroy(): void{
    let mapStrategy = this.portalAppMapStrategy as any;
    mapStrategy.clearDatasetData();
  }

  ngOnInit(): void {
    this.portalAppMapStrategy = this.portalAppsService.activeApp.mapStrategy;
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: JSON.parse(localStorage.getItem('center')),
      zoom: this.startZoom
    });

    this.portalAppMapStrategy.setMapInstance(this.map);


    this.portalAppMapStrategy.bindHoverData(this.mainHoverData, this.metaHoverData);
    this.portalAppMapStrategy.bindShowLeftMenu(this.showEntityInfo);
  }

  addSourcesAndLayers() {
    this.portalAppMapStrategy.addAppSpecificSources()
    .then(() => {
      this.portalAppMapStrategy.addAppSpecificLayers();
      this.portalAppMapStrategy.handleAppSpecificEvents();
    })
  }



  toggle_element(element_id: string, e: HTMLElement) {
    var el = document.getElementById(element_id)
    const floatingContainer = document.getElementsByClassName("floating-container")[0] as HTMLElement;
    let mobileIcon = document.getElementsByClassName("open-info-menu-container")[0] as HTMLElement;
    mobileIcon = mobileIcon.querySelector(".desktop");
    const computedStyle = window.getComputedStyle(mobileIcon);
    const isMobile = computedStyle.display == "none"
    if (el.style.display == 'none') {
      el.style.display = 'block';
      if(floatingContainer != null && !isMobile){

        floatingContainer.style.right = "303px";
      }
    }
    else {
      el.style.display = 'none';
      if(floatingContainer != null){
        floatingContainer.style.right = "5vw";
      }
    }
    e.classList.toggle("change")
  }



  isSwitchedOn: boolean = false;

  toggleSwitch() {
    this.isSwitchedOn = !this.isSwitchedOn;

    // You can add logic here to perform actions based on the switch's state.
    if (this.isSwitchedOn) {
      // Perform actions when the switch is turned on.
    } else {
      // Perform actions when the switch is turned off.
    }
  }

  toggleEntityInfoBox(){
    this.showEntityInfo.value = !this.showEntityInfo.value;
  }

  onRightMenuScroll(event: any): void{
    this.rightMenuScroll =  event.target.scrollTop;
  }
}
