import { Component, Input, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventBusService } from 'src/app/services/event-bus.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PermissionService } from 'src/app/services/permission.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-pre-marking-mutation',
  templateUrl: './pre-marking-mutation.component.html',
  styleUrls: ['./pre-marking-mutation.component.css']
})
export class PreMarkingMutationComponent implements OnInit, OnDestroy{
  @Input() isVisible: any = true;
  @Input() displayData: any;

  // to receive events
  private subscription: Subscription = new Subscription();

  circumference: number = null;
  thinned: boolean | string = null;
  selectedStatus: string | null = 'null';

  userRole = null;

  //success message
  showSuccessMessage = false;

  // custom data
  customDataFields: any[] = [];
  activeDropdown: string | null = null;

  // Add search functionality properties
  searchTerms: { [key: string]: string } = {};
  filteredOptions: { [key: string]: string[] } = {};

  get premarkingCustomDataFields() {
    return this.customDataFields.filter(field => field.contexts.includes('stm_premarking_portal'));
  }

  statusOptions = [
    {label: "i18n.APP_SPECIFIC.STM.DO_NOT_CHANGE", value: 'null'},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_NONE", value: "none"},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_NOT_FOR_SALE", value: "not-for-sale"},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_FOR_SALE", value: "for-sale"},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_IN_STOCK", value: "in-stock"},
  ]

  mapStrategy;
  constructor(
    private portalAppsService: PortalAppsService,
    private organizationService: OrganizationService,
    private eventBusService: EventBusService,
    private apiService: ApiService,
    public permissionService: PermissionService
  ){
    this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
  }

  ngOnInit(): void {
    this.getCustomDataFields();
    this.subscription.add(
      this.eventBusService.events$.subscribe(data => {
        // Handle the event
        if(data.target == "premarkingMutationComponent"){
          if(data.action == "update"){
            this.renderData();
          }
          if(data.action == "success"){
            this.showSuccessMessage = true;
          }
        }
      })
    );
    this.userRole = localStorage.getItem('user_role');
    this.renderData();
    this.showSuccessMessage = false;
  }

  renderData(){
    if(this.displayData.value != null){
      this.circumference = this.displayData.value.circumference || null;
      this.thinned = this.displayData.value.thinned != null ? this.displayData.value.thinned : false;
      this.selectedStatus = this.displayData.value.status != null ? this.displayData.value.status : "null";

      // Initialize custom data if it doesn't exist
      if (!this.displayData.value.custom_data) {
        this.displayData.value.custom_data = {};
      }

      // Initialize empty arrays for multiple choice fields
      this.customDataFields.forEach(field => {
        if (field.field_type === 'multiple_choice' && !this.displayData.value.custom_data[field.id]) {
          this.displayData.value.custom_data[field.id] = [];
        }
      });
    }
    else{
      this.circumference = null;
      this.thinned = null;
      this.selectedStatus = "null";
      this.displayData.value = {
        custom_data: {}
      };
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getCustomDataFields(){
    const appId = '7fa63b0e-ee95-416d-8fa9-cc5dccfa2c4f';
    this.apiService.get_with_auth(`/custom-data/definitions?app_id=${appId}`).subscribe(data => {
      this.customDataFields = data.collection;
    })
  }

  showChoiceDropdown(field: any) {
    this.activeDropdown = this.activeDropdown === field.id ? null : field.id;
    if (this.activeDropdown === field.id) {
        // Initialize filtered options when opening dropdown
        this.filteredOptions[field.id] = [...field.options.values];
        this.searchTerms[field.id] = '';
    }
  }

  filterOptions(fieldId: string) {
    const searchTerm = this.searchTerms[fieldId]?.toLowerCase() || '';
    const field = this.customDataFields.find(f => f.id === fieldId);
    if (field) {
        this.filteredOptions[fieldId] = field.options.values.filter(option =>
            option.toLowerCase().includes(searchTerm)
        );
    }
  }

  getFilteredOptions(fieldId: string): string[] {
    return this.filteredOptions[fieldId] || [];
  }

  selectChoice(field: any, option: string) {
    if (!this.displayData.value.custom_data) {
      this.displayData.value.custom_data = {};
    }
    this.displayData.value.custom_data[field.id] = option;
    this.activeDropdown = null;
  }

  toggleChoice(field: any, option: string) {
    if (!this.displayData.value.custom_data) {
      this.displayData.value.custom_data = {};
    }
    if (!this.displayData.value.custom_data[field.id]) {
      this.displayData.value.custom_data[field.id] = [];
    }
    const index = this.displayData.value.custom_data[field.id].indexOf(option);
    if (index === -1) {
      this.displayData.value.custom_data[field.id].push(option);
    } else {
      this.displayData.value.custom_data[field.id].splice(index, 1);
    }
  }

  async applyMutationChanges(){
    const inputs = {
      circumference : this.circumference,
      thinned: this.thinned,
      status: this.selectedStatus,
      custom_data: this.displayData.value.custom_data || {}
    }

    this.mapStrategy.mutateSelectedPremarkingPoints(inputs);
  }

  onStatusChange(selectedValue: string) {
    this.selectedStatus = selectedValue;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Get all choice dropdown containers
    const containerChoiceDropdowns = document.getElementsByClassName('choice-dropdown');
    const choiceButtons = document.getElementsByClassName('choice-selector');
    const multipleChoiceButtons = document.getElementsByClassName('multiple-choice-selector');

    // Check if click was inside any dropdown
    let clickedInsideDropdown = false;
    for (let i = 0; i < containerChoiceDropdowns.length; i++) {
      if (containerChoiceDropdowns[i].contains(event.target as Node)) {
        clickedInsideDropdown = true;
        break;
      }
    }

    // Check if click was on any button that opens dropdowns
    let clickedOnButton = false;
    for (let i = 0; i < choiceButtons.length; i++) {
      if (choiceButtons[i].contains(event.target as Node)) {
        clickedOnButton = true;
        break;
      }
    }
    for (let i = 0; i < multipleChoiceButtons.length; i++) {
      if (multipleChoiceButtons[i].contains(event.target as Node)) {
        clickedOnButton = true;
        break;
      }
    }

    // If click was outside both dropdowns and buttons, close the active dropdown
    if (!clickedInsideDropdown && !clickedOnButton) {
      this.activeDropdown = null;
    }
  }
}
