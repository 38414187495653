import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap, take, map } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { environment as env } from '../../environments/environment';
import { Router } from '@angular/router';
import { lang } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
  }

  // new methods replacing floris his jokes

  private baseUrl = `${env.api_gateway_url}/v2`

  // private baseUrl = `http://localhost:8000/v1`

  private transformResponse(response: any): any {
    if (this.baseUrl === 'http://localhost:8000/v1' && Array.isArray(response)) {
      return {
        collection: response
      };
    }
    return response;
  }

  private getHeaders() {
    if (!localStorage.getItem('org_id')) {
      this.router.navigateByUrl('/welcome');
    }
    else {
      let orgId = JSON.parse(localStorage.getItem('org_id')).id
      let language = localStorage.getItem('lang')
      if(!language){
        language = 'nld'
        localStorage.setItem('lang', 'nld')
      }
      let requestHeaders = {
        headers: new HttpHeaders().set('X-Organization', orgId).set('Accept-Language', language).set('X-Context', 'stm_portal'), // TODO: remove this .set("X-User", "auth0|65d3533befcaef133094f260")
      };

      return requestHeaders
    }
  }

  //calling api with auth headers and get request
  public get_with_auth(url: string): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) =>
          this.http.get<any>(
            encodeURI(`${this.baseUrl}${url}`), this.getHeaders()
          ).pipe(map(response => this.transformResponse(response)))
        )
      );
  }

  public get_with_auth_without_headers(url: string): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) =>
          this.http.get<any>(
            encodeURI(`${this.baseUrl}${url}`)
          ).pipe(map(response => this.transformResponse(response)))
        )
      );
  }

  //calling api with auth headers and create request
  public post_with_auth(url: string, body = {}): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) =>
          this.http.post<any>(
            encodeURI(`${this.baseUrl}${url}`), body, this.getHeaders()
          ).pipe(map(response => this.transformResponse(response)))
        )
      );
  }

  //calling api with auth headers and delete request
  public delete_with_auth(url: string): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) =>
          this.http.delete<any>(
            encodeURI(`${this.baseUrl}${url}`), this.getHeaders()
          ).pipe(map(response => this.transformResponse(response)))
        )
      );
  }

  //calling api with auth headers and update request
  public put_with_auth(url: string): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) =>
          this.http.put<any>(
            encodeURI(`${this.baseUrl}${url}`), {}, this.getHeaders()
          ).pipe(map(response => this.transformResponse(response)))
        )
      );
  }

  //calling api with auth headers and get request
  public patch_with_auth(url: string, requestBody: any): Observable<any> {
    return this.auth.user$
      .pipe(
        take(1),
        concatMap((user) =>
          this.http.patch<any>(
            encodeURI(`${this.baseUrl}${url}`), requestBody, this.getHeaders()
          ).pipe(map(response => this.transformResponse(response)))
        )
      );
  }

  public mutateSTMObject(body: any): Observable<any>{
    return this.auth.user$
    .pipe(
      take(1),
      concatMap((user) =>
        this.http.put<any>(
          encodeURI(`${this.baseUrl}/object`), body, this.getHeaders()
        ).pipe(map(response => this.transformResponse(response)))
      )
    );
  }

  public archiveSTMObject(body: any): Observable<any>{
    return this.auth.user$
    .pipe(
      take(1),
      concatMap((user) =>
        this.http.post<any>(
          encodeURI(`${this.baseUrl}/object/archive`), body, this.getHeaders()
        ).pipe(map(response => this.transformResponse(response)))
      )
    );
  }

  public unarchiveSTMObject(body: any): Observable<any>{
    return this.auth.user$
    .pipe(
      take(1),
      concatMap((user) =>
        this.http.post<any>(
          encodeURI(`${this.baseUrl}/object/unarchive`), body, this.getHeaders()
        ).pipe(map(response => this.transformResponse(response)))
      )
    );
  }

  public createSTMTree(body: any, fieldId: string): Observable<any>{
    return this.auth.user$
    .pipe(
      take(1),
      concatMap((user) =>
        this.http.post<any>(
          encodeURI(`${this.baseUrl}/object?field_id=${fieldId}`), body, this.getHeaders()
        ).pipe(map(response => this.transformResponse(response)))
      )
    );
  }
}
