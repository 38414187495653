export function treeMetaDataEvents(map, hoverData, customerAddedData, clickData){
    map.on('mousemove', 'treeData-layer', (e) => {
      // customerAddedData = []

        map.getCanvas().style.cursor = 'pointer';

        hoverData.value = JSON.parse(e.features[0].properties.meta)
        // if (hoverData.metadata) {

        //   for (const [key, value] of Object.entries(hoverData.metadata)) {
        //     customerAddedData.push({ key: key, value: value })
        //   }
        // }
      });

      map.on('mouseleave', 'treeData-layer', () => {
        hoverData.value = {}
        if(clickData.value != null && clickData.value.meta){
          hoverData.value = JSON.parse(clickData.value.meta);
        }
        map.getCanvas().style.cursor = '';
      });

}
