export function treeMeasurementDataEvents(map, hoverData, isSelectionOn, clickData, isCreationOn, eventBus){
    let featureid = ''

    map.on('mousemove', 'treeMeasurementData-layer', (e) => {
        if (featureid != e.features[0].id) {
          map.setFeatureState(
            {
              source: 'treeMeasurementData-source',
              id: featureid,
            },
            {
              hover: false,
            }
          );
        }

        if(e.features[0].id != undefined){
          featureid = e.features[0].id.toString();
          map.getCanvas().style.cursor = 'pointer';

          map.setFeatureState(
          {
            source: 'treeMeasurementData-source',
            id: featureid,
          },
          {
            hover: true,
          }
          );
        }
        else{
          featureid = ""
        }




        // @ts-ignore
        const coordinates = e.features[0].geometry.coordinates.slice();
        const circumference = e.features[0].properties.circumference;
        const treeType = e.features[0].properties.treeType;
        const bucket = e.features[0].properties.bucket;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        // this.popup.setLngLat(coordinates).setHTML(this.createTreeWidthHTML(circumference, coordinates,treeType ,bucket)).addTo(this.map)
        if(e.features[0].state.clicked == undefined || e.features[0].state.clicked == false){
          // fix
        }


        let properties = {
          ...e.features[0].properties,
          meta: JSON.parse(e.features[0].properties.meta)
        }

        hoverData.value = properties;


        eventBus.emit({
          target: 'legendComponent',
          action: 'scroll',
          item: e.features[0].properties
        });

      });


      map.on('mouseleave', 'treeMeasurementData-layer', () => {
        hoverData.value = {}; // fix
        if(clickData.value != null){
          hoverData.value = clickData.value;
        }


        if(isSelectionOn.value == null && isCreationOn.value == null){

          map.getCanvas().style.cursor = '';
        }
        else{
          map.getCanvas().style.cursor = 'crosshair';
        }

        map.setFeatureState(
          {
            source: 'treeMeasurementData-source',
            id: featureid,
          },
          {
            hover: false,
          }
        );
      });
}
