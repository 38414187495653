import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { IPortalApp } from 'src/app/services/portal-apps/iportal-app';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';

interface CustomDataDefinition {
  id: number;
  name: string;
  field_type: string;
  context: string[];
  options: string[] | null;
  editable: boolean;
}

@Component({
  selector: 'app-custom-data-page',
  templateUrl: './custom-data-page.component.html',
  styleUrls: ['./custom-data-page.component.css']
})
export class CustomDataPageComponent implements OnInit {
  apps: IPortalApp[] = [];
  selectedApp: IPortalApp | null = null;
  selectedRow: CustomDataDefinition | null = null;
  customData: CustomDataDefinition[] = [];
  currentPage = 1;
  totalPages = 1;
  itemsPerPage = 10;
  currentLang: string;

  fieldTypes: { [key: string]: string } = {
    'text': 'i18n.CUSTOM_DATA_PAGE.FIELD_TYPES.TEXT',
    'number': 'i18n.CUSTOM_DATA_PAGE.FIELD_TYPES.NUMBER',
    'single_choice': 'i18n.CUSTOM_DATA_PAGE.FIELD_TYPES.SINGLE_CHOICE',
    'multiple_choice': 'i18n.CUSTOM_DATA_PAGE.FIELD_TYPES.MULTIPLE_CHOICE'
  };

  constructor(
    private portalAppsService: PortalAppsService,
    private router: Router,
    private apiService: ApiService,
    private translate: TranslateService
  ) {
    this.currentLang = this.translate.currentLang || this.translate.defaultLang;
    this.translate.onLangChange.subscribe(event => {
      this.currentLang = event.lang;
    });
  }

  ngOnInit(): void {
    this.loadApps();
  }

  getContextName(contextSlug: string): string {
    if (!this.selectedApp) return contextSlug;

    const contextView = this.selectedApp.contextViews.find(ctx => ctx.slug === contextSlug);
    if (!contextView) return contextSlug;

    return this.currentLang === 'nl' ? contextView.name_nl : contextView.name_en;
  }

  getContextNames(contextSlugs: string[]): string {
    if (!Array.isArray(contextSlugs)) {
      return this.getContextName(contextSlugs as string);
    }
    return contextSlugs.map(slug => this.getContextName(slug)).join(', ');
  }

  private loadApps(): void {
    this.portalAppsService.getAllAvaliableApps().then(apps => {
      this.apps = apps;
      if (apps.length > 0) {
        this.selectApp(apps[0]);
      }
    });
  }

  selectApp(app: IPortalApp): void {
    this.selectedApp = app;
    this.portalAppsService.setSelectedApp(app);
    this.loadCustomData();
  }

  private loadCustomData(): void {
    if (!this.selectedApp) {
      return;
    }

    const appId = this.selectedApp.measurementAppsMap[0];
    this.apiService.get_with_auth(`/custom-data/definitions?app_id=${appId}`).subscribe(
      (response: any) => {
        this.customData = response.collection;
        this.totalPages = Math.ceil(this.customData.length / this.itemsPerPage);
      },
      error => {
        console.error('Error loading custom data:', error);
      }
    );
  }

  selectRow(item: CustomDataDefinition): void {
    this.selectedRow = item;
  }

  editCustomData(): void {
    if (this.selectedRow) {
      this.router.navigate(['/custom-data/edit', this.selectedRow.id]);
    }
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }
}
