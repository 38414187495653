import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { IRole } from '../models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private api: ApiService) {
  }

  // new methods replacing floris his jokes

  public get_devices(): Observable<any> {
    return this.api.get_with_auth(`/organization/devices`)
  }

  public get_tree_types(): Observable<any> {
    return this.api.get_with_auth(`/tree_type/list`)
  }

  public delete_tree_type(tree_type_id:string): Observable<any> {
    return this.api.delete_with_auth(`/tree_type?tree_type_id=${tree_type_id}`)
  }

  public patch_tree_type(tree_type_id:string, tree_type:string): Observable<any> {
    return this.api.patch_with_auth(`/tree_type`, {"id": tree_type_id, "name": tree_type})
  }

  public add_tree_type(tree_type:string): Observable<any> {
    return this.api.post_with_auth(`/tree_type?name=${tree_type}`)
  }

  public get_members(): Observable<any> {
    return this.api.get_with_auth(`/organization/members`)
  }

  public get_organization(): Observable<any> {
    return this.api.get_with_auth(`/organization`)
  }

  public get_roles(): Observable<any> {
    return this.api.get_with_auth(`/organization/roles`)
  }

  public delete_member(userId: string): Observable<any> {
    return this.api.delete_with_auth(`/organization/member/${userId}`)
  }

  public set_member_role(userId: string, roleId:string): Observable<any> {
    return this.api.post_with_auth(`/organization/member/${userId}/role?role_id=${roleId}`)
  }

  public add_member(email: string, roleId:string): Observable<any> {
    return this.api.put_with_auth(`/organization/member/invite?email=${email}&role_id=${roleId}`)
  }

  public addRole(role: IRole): Observable<any> {
    return this.api.post_with_auth(`/organization/roles/create`, role);
  }

  public updateRolePermissions(role: IRole): Observable<any> {
    return this.api.patch_with_auth(`/organization/roles/${role.id}/permissions`, role);
  }

  public deleteRole(roleId: string): Observable<any>{
    return this.api.delete_with_auth(`/organization/roles/${roleId}`);
  }
}

