import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { OrganizationService } from 'src/app/services/organization.service';
import { IListResponse } from 'src/app/models/global.model';
import { IMember, IOrganization, IRole, ITreeType } from 'src/app/models/organization.model';
import { IField } from 'src/app/models/field.model';
import { FieldService } from 'src/app/services/field.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-management-page',
  templateUrl: './company-management-page.component.html',
  styleUrls: ['./company-management-page.component.css']
})
export class CompanyManagementPageComponent implements OnInit {
  userDataForm: UntypedFormGroup;

  constructor(
    private organizationService: OrganizationService, private fieldService: FieldService, private portalAppsService: PortalAppsService, private router: Router

  ) { }

  //tree type pagination
  nrPerPageTreeType = 5;
  currentPageTreeType = 1;
  totalPagesTreeType = 0;

  //fields pagination
  nrPerPageFields = 5;
  currentPageFields = 1;
  totalPagesFields = 0;

  // add field vars
  selectAppPopupOpen = false;
  availableApps;
  filteredApps;
  searchAppInput = "";
  appOptionsVisible = false;
  selectedApp = null;

  newUserModal = false;
  newTreeTypeModal = false;
  searchTreeType = '';
  searchFields = '';

  organization: IOrganization;
  roles: IRole[];
  members: IMember[];
  treeTypes: ITreeType[] = [];
  treeTypesFiltered: ITreeType[] = [];
  treeTypesUnfiltered: ITreeType[] = [];

  fields: IField[] = [];
  fieldsFiltered: IField[] = [];
  fieldsUnfiltered: IField[] = [];




  email_invalid = false;
  invite_success = false;
  invite_error = null;

  async ngOnInit() {
    this.organizationService.get_members().subscribe(data => {
      this.members = data.collection;
    });

    this.organizationService.get_organization().subscribe(data => {
      this.organization = data;
    });

    this.organizationService.get_roles().subscribe(data => {
      this.roles = data.collection.sort(function (a, b) { return b.permissions.length - a.permissions.length });
    });

    this.organizationService.get_tree_types().subscribe(data => {
      this.treeTypesUnfiltered = data.collection.sort(function (a, b) { return (a.name > b.name) ? 1 : -1 });
      this.paginateTreeTypes()
      this.changeAmountTreeType(this.nrPerPageTreeType)
    });

    this.getFields()

    this.availableApps = await this.portalAppsService.getAllAvaliableApps();
    this.filteredApps = [...this.availableApps];
  }

  // paginate tree type list

  changeTreeTypePage(page: number) {
    if (this.currentPageTreeType + page <= this.totalPagesTreeType && this.currentPageTreeType + page > 0) {
      this.currentPageTreeType = this.currentPageTreeType + page;
    }
    this.paginateTreeTypes()
  }

  setPageTreeType(page: number) {
    this.currentPageTreeType = page;
    this.paginateTreeTypes();
  }

  changeAmountTreeType(amount: number) {
    this.nrPerPageTreeType = amount;
    this.currentPageTreeType = 1;
    this.paginateTreeTypes();
  }

  paginateTreeTypes() {
    this.treeTypes = [];
    this.treeTypesFiltered = this.treeTypesUnfiltered.filter(type => type.name.toUpperCase().includes(this.searchTreeType)).sort(function (a, b) { return (a.name > b.name) ? 1 : -1 })
    this.totalPagesTreeType = Math.ceil(this.treeTypesFiltered.length / this.nrPerPageTreeType);
    this.treeTypes = this.treeTypesFiltered.sort().slice((this.currentPageTreeType - 1) * this.nrPerPageTreeType, this.currentPageTreeType * this.nrPerPageTreeType)
  }

  // paginate fields list

  changeFieldsPage(page: number) {
    if (this.currentPageFields + page <= this.totalPagesFields && this.currentPageFields + page > 0) {
      this.currentPageFields = this.currentPageFields + page;
    }
    this.paginateFields()
  }

  setPageFields(page: number) {
    this.currentPageFields = page;
    this.paginateFields();
  }

  changeAmountFields(amount: number) {
    this.nrPerPageFields = amount;
    this.currentPageFields = 1;
    this.paginateFields();
  }

  paginateFields() {
    this.fields = [];
    this.fieldsFiltered = this.fieldsUnfiltered.filter(field => field.name.toUpperCase().includes(this.searchFields))
    this.totalPagesFields = Math.ceil(this.fieldsFiltered.length / this.nrPerPageFields);
    this.fields = this.fieldsFiltered.sort().slice((this.currentPageFields - 1) * this.nrPerPageFields, this.currentPageFields * this.nrPerPageFields)
  }

  // managing fields

  addFieldClick(){
    this.selectAppPopupOpen = true;
  }

  closeAppSelectorPopup(event){
    if(event.target.classList.contains("choose-app-popup-background")){
      this.selectAppPopupOpen = false;
    }

  }

  filterAppSelectionOptions(input){
    this.searchAppInput = input;
    this.filteredApps = this.availableApps.filter(app => app.name.includes(this.searchAppInput));
  }

  navigateToTheAppAddField(app){
    this.portalAppsService.setActiveApp(app);
    const innerRoute = "fields/add";
    const url = "/apps/" + app.codeName;
    this.router.navigate([url], {queryParams: {navigateTo: innerRoute }})
  }

  archiveField(field: any) {
    this.fieldService.archive_field(field).subscribe(d => {
      this.getFields();
    });
  }
  unarchiveField(field: any) {
    this.fieldService.unarchive_field(field).subscribe(d => {
      this.getFields();
    });
  }

  getFields() {
    this.fieldService.get_fields(0, 1000, true).subscribe(data => {
      let raw_fields: IListResponse<IField> = data;
      this.fieldsUnfiltered = raw_fields.items.sort(function (a, b) { return (a.name > b.name) ? 1 : -1 });
      this.paginateFields()
      this.changeAmountFields(this.nrPerPageFields)
    });
  }

  // managing members

  addMember(email: string, role: string) {
    if (email.length < 4 || email.indexOf('@') === -1) {
      this.email_invalid = true
      return
    }
    this.email_invalid = false;
    this.organizationService.add_member(email, role).subscribe(
      d => {
        this.email_invalid = false;
        this.invite_error = false;
        this.invite_success = true;
      },
      err => this.invite_error = err.error.detail,
    );

  }

  changeMemberRole(memberID: string, roleID: string) {
    this.organizationService.set_member_role(memberID, roleID).subscribe(d => {

    });
  }

  deleteMember(memberID: string) {
    this.organizationService.delete_member(memberID).subscribe(d => {
      this.members = this.members.filter(obj => { return obj.id !== memberID });
    });
  }

  checkRoles(permissions, perm) {
    if (permissions.some(permission => permission.id === perm)) {
      return true
    }

  }

  // editing tree type row

  editTreeType(event, tree_type_id) {

    // (event.target as Element).parentElement.parentElement
    var nodes = (event.target as Element).parentElement.parentElement.getElementsByTagName('input');

    if ((nodes[0] as HTMLInputElement).disabled == false) {
      for (var i = 0; i < nodes.length; i++) {
        (nodes[i] as HTMLInputElement).disabled = true;
        if ((nodes[i] as HTMLInputElement).value != '') {
          //update it
          this.organizationService.patch_tree_type(tree_type_id, (nodes[i] as HTMLInputElement).value).subscribe(d => {

          });
        } else {
          //delete it
          this.organizationService.delete_tree_type(tree_type_id).subscribe(d => {
          });
        }
      }
      (event.target as Element).textContent = "Aanpassen"
    } else {
      for (var i = 0; i < nodes.length; i++) {
        (nodes[i] as HTMLInputElement).disabled = false;
      }
      (event.target as Element).textContent = "Opslaan"
    }
  }

  deleteTreeType(treeTypeId) {
    this.organizationService.delete_tree_type(treeTypeId).subscribe(d => {
      this.treeTypesUnfiltered.splice(this.treeTypesUnfiltered.findIndex(a => a.id === treeTypeId), 1);
      this.paginateTreeTypes()
    });
  }

  addTreeType(tree_type: string) {
    if (tree_type == '') {
      return
    }

    this.organizationService.add_tree_type(tree_type).subscribe(d => {
      this.newTreeTypeModal = !this.newTreeTypeModal
      this.treeTypesUnfiltered.push(d)
      this.paginateTreeTypes()
    });

  }

  sort(event) {
    // use this to select on what column to sort the data
    (event.target as Element).textContent += "▼▲"
  }

  update_search_tree_type(search_val) {
    this.searchTreeType = search_val.target.value.toUpperCase();
    this.currentPageTreeType = 1
    this.paginateTreeTypes();
  }

  update_search_fields(search_val) {
    this.searchFields = search_val.target.value.toUpperCase();
    this.currentPageFields = 1
    this.paginateFields();
  }
}
