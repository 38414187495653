import { Component, Input } from '@angular/core';

@Component({
  selector: 'stem-thickness-meta-data-component',
  templateUrl: './stem-thickness-meta-data.component.html',
  styleUrls: ['./stem-thickness-meta-data.component.css']
})

export class StemThicknessMetaData  {
    @Input() isVisible: any;
    @Input() displayData: any;

    isMetaMetaDataEmpty(){
      if(this.displayData.value.metadata){
        return Object.keys(this.displayData.value.metadata).length === 0
      }
      else{
        return true
      }

    }

    getObjectKeys(obj: any): string[] {
      return Object.keys(obj);
    }
}
