import { Component, OnInit } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';
import { IMapStrategy } from '../../iMapStrategy';

@Component({
  selector: 'stm-control-set-component',
  templateUrl: 'stm-control-set.component.html',
  styleUrls: ['stm-control-set.component.css']
})

export class StmConrtolSetComponent implements OnInit {
  portalAppMapStrategy: any;
  constructor(private portalAppsService: PortalAppsService) { }

  ngOnInit() {
    this.portalAppMapStrategy = this.portalAppsService.activeApp.mapStrategy;
    this.portalAppMapStrategy.intializeMapConrtols();
  }
}
