import { Injectable } from '@angular/core';
import { IPortalApp } from './iportal-app';
import { StmAppService } from './apps/stm-app';
import { Co2AppService } from './apps/co2-app';
import { Observable, of, firstValueFrom } from 'rxjs';
import { StmMapStrategy } from './map-strategies/stm-map-strategy';
import { OrganizationService } from '../organization.service';
import { PermissionService } from '../permission.service';
import { ApiService } from '../api.service';


// import portalApps from '../assets/portal-apps.json';

@Injectable({
  providedIn: 'root'
})
export class PortalAppsService {
  activeApp: IPortalApp;
  private allApps: IPortalApp[];
  private selectedApp: IPortalApp | null = null;

  constructor(stmMapStartegy: StmMapStrategy, private organizationService: OrganizationService, private permissionService: PermissionService) {
    // declare apps
    this.allApps = [];
    this.allApps.push(new StmAppService(stmMapStartegy, permissionService));
    // this.allApps.push(new Co2AppService());
  }

  public async getAllAvaliableApps(): Promise<IPortalApp[]> {
    return new Promise((resolve, reject) => {
      const avalaibleApps: IPortalApp[] = [];

      this.organizationService.get_organization().subscribe({
        next: (data) => {
          // @ts-ignore
          const orgMeasurementAppIds = Object.entries(data.apps).map(([key, value]) => value.id);


          this.allApps.forEach((app) => {
            const isAvailable = app.measurementAppsMap.every(appId => orgMeasurementAppIds.includes(appId));

            if (isAvailable) {
              avalaibleApps.push(app);
            }
          });

          resolve(avalaibleApps);
        },
        error: (err) => {
          console.error('Error fetching organization data:', err);
          reject(err);
        }
        })
    });
  }

  // to display the apps in the app selection
  public getAllApps(): IPortalApp[]{
    // filter apps
    return this.allApps;
  }





  // to display the apps in the app selection based on the stored organization details
  public getAppsByCodeName(codeNames: string[]): IPortalApp[]{
    const appsToReturn: IPortalApp[] = [];
    codeNames.forEach((codeName) => {
      const app = this.allApps.find((targetApp) => targetApp.codeName == codeName);
      if(app != undefined){
        appsToReturn.push(app);
      }
    })
    return appsToReturn;
  }

  public getAppObservableByCodeName(codeName: string): Observable<IPortalApp>{
    const app = this.getAppsByCodeName([codeName])[0];
    return of(app);
  }

  public setActiveApp(app: IPortalApp): void{
      this.activeApp = app;
  }

  setSelectedApp(app: IPortalApp): void {
    this.selectedApp = app;
  }

  getSelectedApp(): IPortalApp | null {
    return this.selectedApp;
  }

}
