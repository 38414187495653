import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OrganizationService } from 'src/app/services/organization.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { IPortalApp } from 'src/app/services/portal-apps/iportal-app';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-custom-data',
  templateUrl: './add-custom-data.component.html',
  styleUrls: ['./add-custom-data.component.css']
})
export class AddCustomDataComponent implements OnInit {
  customDataForm: FormGroup;
  roles: any[] = [];
  selectedApp: IPortalApp | null = null;
  currentLang: string;

  fieldTypes = [
    { id: 'text', name: 'Text' },
    { id: 'number', name: 'Number' },
    { id: 'single_choice', name: 'Single choice selection' },
    { id: 'multiple_choice', name: 'Multiple choice selection' }
  ];

  contexts = [];

  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private portalAppsService: PortalAppsService,
    private router: Router,
    private translate: TranslateService,
    private apiService: ApiService
  ) {
    this.currentLang = this.translate.currentLang || this.translate.defaultLang;
    this.translate.onLangChange.subscribe(event => {
      this.currentLang = event.lang;
    });
  }

  ngOnInit(): void {
    this.selectedApp = this.portalAppsService.getSelectedApp();
    console.log('Initial selected app:', this.selectedApp);

    if (!this.selectedApp) {
      console.log('No app selected, redirecting to custom-data page');
      this.router.navigate(['/custom-data']);
      return;
    }

    this.contexts = this.selectedApp.contextViews;
    console.log('Contexts loaded:', this.contexts);

    this.initForm();
    this.loadRoles();
  }

  private initForm(): void {
    this.customDataForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      type: ['', Validators.required],
      choices: [''],
      contexts: [[], [Validators.required, Validators.minLength(1)]]
    });


    // Subscribe to type changes to handle choices validation
    this.customDataForm.get('type').valueChanges.subscribe(type => {
      const choicesControl = this.customDataForm.get('choices');
      if (type === 'single_choice' || type === 'multiple_choice') {
        choicesControl.setValidators([Validators.required]);
        choicesControl.updateValueAndValidity();
      } else {
        choicesControl.clearValidators();
        choicesControl.setValue(''); // Clear the choices value
        choicesControl.updateValueAndValidity();
      }
    });

    // Subscribe to choices changes to update form validity
    this.customDataForm.get('choices').valueChanges.subscribe(value => {
      const type = this.customDataForm.get('type').value;
      if (type === 'single_choice' || type === 'multiple_choice') {
        if (!value || value.trim() === '') {
          this.customDataForm.get('choices').setErrors({ required: true });
        } else {
          this.customDataForm.get('choices').setErrors(null);
        }
      }
      this.customDataForm.updateValueAndValidity();
    });

    // Subscribe to context changes to ensure at least one is selected
    this.customDataForm.get('contexts').valueChanges.subscribe(value => {
      if (!value || value.length === 0) {
        this.customDataForm.get('contexts').setErrors({ required: true });
      } else {
        this.customDataForm.get('contexts').setErrors(null);
      }
      this.customDataForm.updateValueAndValidity();
    });
  }

  private loadRoles(): void {
    this.organizationService.get_roles().subscribe(
      response => {
        this.roles = response.collection;
      },
      error => {
        console.error('Error loading roles:', error);
      }
    );
  }

  // Add method to check if form is valid
  isFormValid(): boolean {
    const nameValid = this.customDataForm.get('name').valid;
    const typeValid = this.customDataForm.get('type').valid;
    const contextValid = this.customDataForm.get('contexts').valid;
    const choicesValid = this.customDataForm.get('type').value === 'single_choice' ||
                        this.customDataForm.get('type').value === 'multiple_choice'
                        ? this.customDataForm.get('choices').valid
                        : true;


    return nameValid && typeValid && contextValid && choicesValid;
  }

  onSubmit(): void {
    if (this.isFormValid() && this.selectedApp) {
      console.log('Selected App:', this.selectedApp);
      console.log('Measurement Apps Map:', this.selectedApp.measurementAppsMap);
      const appId = this.selectedApp.measurementAppsMap[0];
      console.log('Selected App ID:', appId);

      if (!appId) {
        console.error('No app ID found in measurementAppsMap');
        return;
      }

      // Transform form data to match API requirements
      const formData = {
        name: this.customDataForm.get('name').value,
        field_type: this.customDataForm.get('type').value,
        contexts: this.customDataForm.get('contexts').value,
        app_id: appId,
        options: this.customDataForm.get('type').value === 'single_choice' ||
                this.customDataForm.get('type').value === 'multiple_choice'
                ? this.customDataForm.get('choices').value
                    .split('\n')
                    .map(choice => choice.trim())
                    .filter(choice => choice !== '')
                : null
      };

      console.log('Form submitted:', formData);
      this.apiService.post_with_auth(`/custom-data/definition`, formData).subscribe(data => {
        console.log('Successfully created custom data definition:', data);
        this.router.navigate(['/custom-data']);
      }, error => {
        console.error('Error saving custom data:', error);
      });
    } else {
      console.error('Form is invalid or no app selected');
      console.log('Form validity:', this.isFormValid());
      console.log('Selected app:', this.selectedApp);
    }
  }

  onCancel(): void {
    this.router.navigate(['/custom-data']);
  }

  get showChoices(): boolean {
    const type = this.customDataForm.get('type').value;
    return type === 'single_choice' || type === 'multiple_choice';
  }

  onContextChange(event: any, contextId: string): void {
    const contextControl = this.customDataForm.get('contexts');
    const currentValue = contextControl.value || [];

    if (event.target.checked) {
      contextControl.setValue([...currentValue, contextId]);
    } else {
      contextControl.setValue(currentValue.filter(id => id !== contextId));
    }

    contextControl.updateValueAndValidity();
  }
}
