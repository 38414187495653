import { Component, Input, OnInit, ViewContainerRef } from "@angular/core";

@Component({
    selector: 'app-dynamic-importable-component', // Define your custom HTML tag here
    template: '', // Since this is a dynamic component, it doesn't need its own template,
    styles: [":host {position: absolute}"]
  })
export class DynamicImportableComponent implements OnInit {

    // change accordingly
    @Input() component: any;

    constructor(public viewContainerRef: ViewContainerRef) { }

    ngOnInit() {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(this.component);
    }
  }
