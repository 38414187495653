import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache = new Map<string, any>(); // A Map to store cache data

  constructor() {}

  // Method to set data in the cache with a key
  setData(key: string, value: any): void {
    this.cache.set(key, value);
  }

  // Method to get data from the cache by key
  getData(key: string): any | null {
    return this.cache.has(key) ? this.cache.get(key) : null;
  }

  // Optional: Method to check if a key exists in the cache
  hasData(key: string): boolean {
    return this.cache.has(key);
  }

  // Optional: Method to remove data from the cache
  removeData(key: string): void {
    this.cache.delete(key);
  }

  // Optional: Method to clear all data from the cache
  clearCache(): void {
    this.cache.clear();
  }
}
