import { PermissionService } from './../../../../permission.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';
import { IMapStrategy } from '../../iMapStrategy';
import { OrganizationService } from 'src/app/services/organization.service';
import { switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'stm-control-set-component',
  templateUrl: 'stm-control-set.component.html',
  styleUrls: ['stm-control-set.component.css'],
  styles: [":host {height: 100%}"]
})

export class StmConrtolSetComponent implements OnInit {
  portalAppMapStrategy: any;

  // premarking
  premarkingAvailable = false;
  userRole = null;

  isToThinnViewOpen = false;


  constructor(private portalAppsService: PortalAppsService, private organizationService: OrganizationService, private userService: UserService, public permissionService: PermissionService) { }


  ngOnInit() {
    this.portalAppMapStrategy = this.portalAppsService.activeApp.mapStrategy;
    this.portalAppMapStrategy.intializeMapConrtols();
    // check for premarking availability

    let orgMembers = [];
    let premarkingCondition;

    this.organizationService.get_organization().subscribe(organization => {
        // Process the first subscription
        if (organization.apps['pre-marking'] != null) {
          premarkingCondition = organization.apps['pre-marking']['id'] === 'e443a3e7-11ea-4936-8167-2ff7454cdaf4';
        } else {
          premarkingCondition = false;
        }

        if(premarkingCondition){

          this.premarkingAvailable = this.permissionService.hasRightTo('premarking-view');
        }
      }
    )

  }

  changePremarkingView(option){
    this.portalAppMapStrategy.togglePremarking(option);

  }

  handleDisplayThinning(){
    this.portalAppMapStrategy.showThinnedTrees(!this.portalAppMapStrategy.showWithoutThinnedView);
  }

  toggleToThinView(){
    this.portalAppMapStrategy.toggleToThinView();
  }



}
