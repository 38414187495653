<div class="container">
  <h1>{{ 'i18n.CUSTOM_DATA.IMPORT.TITLE' | translate }}</h1>
  <h2>{{ 'i18n.CUSTOM_DATA.IMPORT.SUBTITLE' | translate }}</h2>

  <div class="section">
    <!-- File Upload Section -->
    <div class="upload-section">
      <h3>{{ 'i18n.CUSTOM_DATA.IMPORT.FILE_SECTION.TITLE' | translate }}</h3>
      <form (ngSubmit)="onSubmit()" #importForm="ngForm">
        <div class="form-field">
          <label for="file" class="visually-hidden">{{ 'i18n.CUSTOM_DATA.IMPORT.FILE_SECTION.SELECT_FILE' | translate }}</label>
          <div class="file-upload">
            <input
              type="file"
              id="file"
              (change)="onFileSelected($event)"
              accept=".xlsx,.xls"
              [attr.aria-label]="'i18n.CUSTOM_DATA.IMPORT.FILE_SECTION.SELECT_FILE' | translate"
              [title]="'i18n.CUSTOM_DATA.IMPORT.FILE_SECTION.SELECT_FILE' | translate"
            >
            <div class="hint">{{ 'i18n.CUSTOM_DATA.IMPORT.FILE_SECTION.ACCEPTED_FORMATS' | translate }}</div>
          </div>
          <div class="error" *ngIf="error" role="alert">{{ error }}</div>
        </div>
      </form>
    </div>

    <!-- Column Matching Section (visible after file upload) -->
    <div class="column-matching-section" *ngIf="excelColumns?.length">
      <h3>{{ 'i18n.CUSTOM_DATA.IMPORT.COLUMN_MATCHING.TITLE' | translate }}</h3>
      <p class="hint">{{ 'i18n.CUSTOM_DATA.IMPORT.COLUMN_MATCHING.SUBTITLE' | translate }}</p>
      <p class="error" *ngIf="!hasRequiredFields">{{ 'i18n.CUSTOM_DATA.IMPORT.COLUMN_MATCHING.REQUIRED_FIELDS_ERROR' | translate }}</p>

      <div class="column-mapping">
        <div class="mapping-row header">
          <div class="excel-column">{{ 'i18n.CUSTOM_DATA.IMPORT.COLUMN_MATCHING.EXCEL_COLUMN' | translate }}</div>
          <div class="platform-column">{{ 'i18n.CUSTOM_DATA.IMPORT.COLUMN_MATCHING.PLATFORM_FIELD' | translate }}</div>
        </div>
        <div class="mapping-row" *ngFor="let column of excelColumns; let i = index">
          <div class="excel-column">{{ column }}</div>
          <div class="platform-column">
            <select
              [id]="'mapping_' + i"
              [(ngModel)]="columnMapping[column]"
              (change)="onMappingChange()"
              [title]="'i18n.CUSTOM_DATA.IMPORT.COLUMN_MATCHING.MAP_FIELD' | translate:{ column: column }"
              [attr.aria-label]="'i18n.CUSTOM_DATA.IMPORT.COLUMN_MATCHING.MAP_FIELD' | translate:{ column: column }">
              <option value="">{{ 'i18n.CUSTOM_DATA.IMPORT.COLUMN_MATCHING.SELECT_FIELD' | translate }}</option>
              <option *ngFor="let field of availableFields" [value]="field.id">
                {{ field.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Progress Section -->
    <div *ngIf="isProcessing" class="progress-section">
      <div class="progress-bar">
        <div class="progress" [style.width.%]="progress"></div>
      </div>
      <div class="progress-text">{{ 'i18n.CUSTOM_DATA.IMPORT.PROCESSING.PROCESSING_ROW' | translate:{ current: currentRow, total: totalRows } }}</div>
    </div>

    <!-- Processing Success Message -->
    <div *ngIf="processingSuccessMessage" class="validation-success">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 512 512" fill="#2e7d32"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
      <span>{{ 'i18n.CUSTOM_DATA.IMPORT.PROCESSING.SUCCESS' | translate }}</span>
    </div>

    <!-- Importing Progress Bar -->
    <div *ngIf="isImporting" class="progress-section">
      <div class="progress-bar">
        <div class="progress" [style.width.%]="progress"></div>
      </div>
      <div class="progress-text">{{ progress }}% {{ 'i18n.CUSTOM_DATA.IMPORT.PROCESSING.IMPORTING' | translate }}</div>
    </div>

    <!-- Error Panel -->
    <div *ngIf="showErrorPanel" class="error-panel" role="alert">
      <div class="error-panel-header">
        <h3>{{ errorMessage }}</h3>
      </div>

      <div class="error-panel-content">
        <div *ngFor="let group of columnErrorGroups" class="error-group">
          <div
            class="error-group-header"
            (click)="toggleColumnErrors(group)"
            role="button"
            [attr.aria-expanded]="group.isExpanded"
            [attr.aria-controls]="'error-group-' + group.column">
            <div class="error-group-title">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon icon-sm"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
              <span>{{ group.column }}</span>
              <span class="error-count"> ({{ 'i18n.CUSTOM_DATA.IMPORT.ERRORS.ERRORS_COUNT' | translate:{ count: group.errors.length } }})</span>
            </div>
          </div>

          <div
            class="error-group-content"
            [class.expanded]="group.isExpanded"
            [id]="'error-group-' + group.column"
            role="region"
            [attr.aria-hidden]="!group.isExpanded">
            <div *ngFor="let error of group.errors" class="error-item">
              <div class="error-row">{{ 'i18n.CUSTOM_DATA.IMPORT.ERRORS.ROW' | translate }} {{ error.row }}</div>
              <div class="error-message">{{ error.error }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Action buttons -->
    <div class="button-group">
      <button
        *ngIf="selectedFile && excelColumns?.length && !isDataProcessed"
        (click)="onProcessData()"
        [disabled]="isProcessing || !hasRequiredFields"
        class="btn btn-primary"
        [title]="'i18n.CUSTOM_DATA.IMPORT.BUTTONS.PROCESS_DATA' | translate">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon icon-sm">
          <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3z"/>
        </svg>
        {{ 'i18n.CUSTOM_DATA.IMPORT.BUTTONS.PROCESS_DATA' | translate }}
      </button>
      <button
        *ngIf="isDataProcessed && !showErrorPanel"
        (click)="onImport()"
        [disabled]="isProcessing"
        class="btn btn-primary"
        [title]="'i18n.CUSTOM_DATA.IMPORT.BUTTONS.IMPORT_DATA' | translate">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon icon-sm">
          <path d="M160 0c-17.7 0-32 14.3-32 32V96H96c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H384V32c0-17.7-14.3-32-32-32H160zM384 96V64H256V96H384zm-64 0V64H192V96H320zM192 256c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16zm16 64H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
        </svg>
        {{ 'i18n.CUSTOM_DATA.IMPORT.BUTTONS.IMPORT_DATA' | translate }}
      </button>
      <button
        *ngIf="showErrorPanel"
        (click)="onProcessData()"
        [disabled]="isProcessing"
        class="btn btn-primary"
        [title]="'i18n.CUSTOM_DATA.IMPORT.BUTTONS.RETRY' | translate">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon icon-sm">
          <path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"/>
        </svg>
        {{ 'i18n.CUSTOM_DATA.IMPORT.BUTTONS.RETRY' | translate }}
      </button>
      <button
        (click)="onCancel()"
        class="btn btn-secondary"
        [title]="'i18n.CUSTOM_DATA.IMPORT.BUTTONS.CANCEL' | translate">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="icon icon-sm">
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
        </svg>
        {{ 'i18n.CUSTOM_DATA.IMPORT.BUTTONS.CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>

<!-- Import Success Modal -->
<div *ngIf="successMessage" class="modal_container">
  <div class="global_tile">
    <div class="modal_content">
      <div class="success-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon">
          <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm113-303L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 97c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
        </svg>
      </div>
      <h3>{{ 'i18n.CUSTOM_DATA.IMPORT.PROCESSING.SUCCESS_TITLE' | translate }}</h3>
      <p>{{ 'i18n.CUSTOM_DATA.IMPORT.PROCESSING.IMPORT_SUCCESS' | translate }}</p>
      <p class="redirect-message">{{ 'i18n.CUSTOM_DATA.IMPORT.PROCESSING.REDIRECT_MESSAGE' | translate }}</p>
    </div>
  </div>
</div>
