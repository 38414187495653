<div class="tree-measurement-data-component">
    <h3>{{'i18n.APP_SPECIFIC.STM.MUTATE' | translate}}</h3>

    <div class="tree-measurement-data-overview">
        <table>
            <tr>
                <th>
                    {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.PROPERTY' | translate}}
                </th>
                <th>
                    {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.VALUE' | translate}}
                </th>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_CIRCUMFERENCE' | translate}}</td>
                <td><input [(ngModel)]="circumference"  placeholder="0.0" type="number" step="0.1" ></td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_TYPE' | translate}}</td>
                <td>
                    <div class="tree-type-selector">
                        <div class="tree-type-input">
                            <button (click)="showTreeTypeDropDown()">
                                <span *ngIf="selectedTreeType == ''">{{'i18n.GLOBAL.SELECT' | translate}}</span>
                                <span *ngIf="selectedTreeType != ''">{{selectedTreeType}}</span>

                            </button>
                        </div>
                        <div class="tree-type-dropdown" *ngIf="showTreeTypeDropdown">
                            <ul>
                                <li>
                                    <input [(ngModel)]="treeTypeInputFilter"
                            (input)="onTypeTreeTypeInput($event)"
                            placeholder=""
                            type="text">
                                </li>
                                <li *ngFor="let treeType of this.filteredTreeTypes" (click)="onTreeTypeSelect(treeType)">
                                    <span class="tree-name">
                                        {{treeType.name}}
                                    </span>
                                </li>
                            </ul>
                        </div>

                    </div>
                </td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.DAMAGED' | translate}}</td>
                <td>
                    <div class="bool-option-container">
                        <label>
                            <input name="damaged" type="radio" [(ngModel)]="damaged" [value]="true">
                            {{'i18n.STATISTIC_PAGE.STM_STATISTICS.TRUE' | translate}}
                        </label>
                        <label>
                            <input name="damaged" type="radio" [(ngModel)]="damaged" [value]="false">
                            {{'i18n.STATISTIC_PAGE.STM_STATISTICS.FALSE' | translate}}
                        </label>
                        <label  class="do-not-change">
                            <input name="damaged" type="radio" [(ngModel)]="damaged" [value]="null">
                            {{'i18n.APP_SPECIFIC.STM.DO_NOT_CHANGE' | translate}}
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>{{'i18n.FIELD_PAGE.NOTE' | translate}}</td>
                <td><input [(ngModel)]="note" placeholder="" type="text"></td>
            </tr>
            <tr>
                <td>{{'i18n.FIELD_PAGE.QUALITY' | translate}}</td>
                <td>
                    <div class="ab-quality-dropdown">
                        <button (click)="handleChooseABQualityButton()">
                            <span *ngIf="abQualitySelected == ''">{{'i18n.GLOBAL.SELECT' | translate}}</span>
                            <span *ngIf="abQualitySelected != ''">{{abQualitySelected | uppercase}}</span>
                        </button>
                        <div class="ab-quality-dropdown-menu" *ngIf="showABQualiltyDropdown">
                            <ul>
                                <li (click)="handleSelectABQuality(item)" *ngFor="let item of abQualityList">
                                  <ng-container *ngIf="item != 'null'">
                                    {{item | uppercase}}
                                  </ng-container>
                                  <ng-container *ngIf="item == 'null'">
                                    {{"i18n.FIELD_PAGE.MISSING_QUALITY" | translate}}
                                  </ng-container>
                                </li>
                            </ul>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>{{'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.HAS_POLE' | translate}}</td>
                <td>
                    <div class="bool-option-container">
                        <label>
                            <input name="hasPole" type="radio" [(ngModel)]="hasPole" [value]="true">
                            {{'i18n.STATISTIC_PAGE.STM_STATISTICS.TRUE' | translate}}
                        </label>
                        <label>
                            <input name="hasPole" type="radio" [(ngModel)]="hasPole" [value]="false">
                            {{'i18n.STATISTIC_PAGE.STM_STATISTICS.FALSE' | translate}}
                        </label>
                        <label class="do-not-change">
                            <input name="hasPole" type="radio" [(ngModel)]="hasPole" [value]="null">
                            {{'i18n.APP_SPECIFIC.STM.DO_NOT_CHANGE' | translate}}
                        </label>
                    </div>

                </td>
            </tr>
        </table>
    </div>

    <div *ngIf="this.showSuccessMessage" class="success-messaage">
        <div class="message">
            {{'i18n.APP_SPECIFIC.STM.MUTATION_SUCCESS_MESSAGE' | translate}}
        </div>
        <div class="progress-bar">
            <div class="progress-bar-filler"></div>
        </div>
    </div>

    <button *ngIf="!this.showSuccessMessage" (click)="applyMutationChanges()">{{'i18n.APP_SPECIFIC.STM.APPLY_MUTATION_CHANGES' | translate}}</button>

</div>
