import { Component } from '@angular/core';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';

@Component({
  selector: 'app-toggle-satellite-view',
  templateUrl: './toggle-satellite-view.component.html',
  styleUrls: ['./toggle-satellite-view.component.css']
})
export class ToggleSatelliteViewComponent {
  isToggled: boolean = false;

  mapStrategy;
  constructor(private portalAppsService: PortalAppsService){
      this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
  }

  toggleSatelliteView(){
    this.isToggled = !this.isToggled;
      this.mapStrategy.changeMapStyle(this.isToggled);
  }
}
