<div class="spacing_non_map_pages">
    <div>
      <div class="header">
        <h1 class="unselectable global_title">{{'i18n.STATISTIC_PAGE.TITLE' | translate}}</h1>
        <div class="export_btns">

          <button *ngIf="this.permissionService.hasRightTo('export-statistics')" class="global_btn export_btn" (click)="exportTableToExcel()">{{'i18n.STATISTIC_PAGE.EXPORT_EXCEL' |
            translate}}</button>

          <button *ngIf="this.permissionService.hasRightTo('export-statistics')" class="global_btn export_btn" (click)="exportToPDF()">{{'i18n.STATISTIC_PAGE.EXPORT_PDF' | translate}}
            <div id="loader"></div>
          </button>
        </div>
      </div>
      <div class="content">
        <div class="item global_tile ">
          <div class="field_selector_container">

            <div class="date_picker">
              <label for="datePicker">{{'i18n.STATISTIC_PAGE.SELECT_A_DATE' |
                translate}}</label>
              <!-- <input type="date" id="datePicker" name="date" (input)="changeDate($event)"> -->
              <mat-calendar (selectedChange)="changeDate($event)" [selected]="selectedDate"></mat-calendar>
            </div>


            <div class="date_picker">
              <label for="fieldPicker">{{'i18n.STATISTIC_PAGE.SELECT_A_FIELD' |
                translate}}</label>
              <div class="date_list" id="fieldPicker" >
                <div class="dataset-checkbox">
                  <span *ngIf="fieldList != undefined">
                    <mat-checkbox (change)="selectAllFields($event)"
                    [checked]="selectedFields.length == fieldList.items.length"></mat-checkbox>
                  </span>

                  <label class="checkbox-label">[{{'i18n.STATISTIC_PAGE.SELECT_ALL' |
                    translate}}]</label>
                </div>

                <div  *ngIf="fieldList != undefined">
                  <div *ngFor="let field of fieldList.items" class="dataset-checkbox" id="checkbox_list">
                    <mat-checkbox *ngIf="field.name.toLowerCase().includes(searchedField.toLowerCase())" id="{{field.id}}"
                      (change)="includeField($event, field.id)"
                      [checked]="selectedFields.includes(field.id)"></mat-checkbox>
                    <label *ngIf="field.name.toLowerCase().includes(searchedField.toLowerCase())" for="{{field.id}}"
                      class="checkbox-label">{{field.name}}</label>
                  </div>
                </div>

              </div>
            </div>

            <div>
              <button class="compare-to-fields-btn" *ngIf="!isFieldComparisonOn" (click)="handleToggleFieldComparison()">{{'i18n.STATISTIC_PAGE.STM_STATISTICS.COMPARE_WITH_FIELD' | translate}}</button>

              <div *ngIf="isFieldComparisonOn" class="date_picker field-to-compare-selection">
                <div class="compare-to-arrow">
                  <span>{{'i18n.STATISTIC_PAGE.STM_STATISTICS.COMPARE_TO' | translate}}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                </div>
                <label for="fieldPicker">{{'i18n.STATISTIC_PAGE.SELECT_A_FIELD' |
                  translate}}</label>
                <div class="date_list" id="fieldPicker" >
                  <div class="dataset-checkbox">
                    <span *ngIf="fieldList != undefined">
                      <mat-checkbox (change)="selectAllFieldsToCompare($event)"
                      [checked]="selectedFieldsToCompare.length == fieldList.items.length"></mat-checkbox>
                    </span>

                    <label class="checkbox-label">[{{'i18n.STATISTIC_PAGE.SELECT_ALL' |
                      translate}}]</label>
                  </div>

                  <div  *ngIf="fieldList != undefined">
                    <div *ngFor="let field of fieldList.items" class="dataset-checkbox" id="checkbox_list">
                      <mat-checkbox *ngIf="field.name.toLowerCase().includes(searchedField.toLowerCase())" id="{{field.id}}"
                        (change)="includeFieldToCompare($event, field.id)"
                        [checked]="selectedFieldsToCompare.includes(field.id)"></mat-checkbox>
                      <label *ngIf="field.name.toLowerCase().includes(searchedField.toLowerCase())" for="{{field.id}}"
                        class="checkbox-label">{{field.name}}</label>
                    </div>
                  </div>

                </div>
              </div>
            </div>



            <div *ngIf="bucketSizes.length > 0" class="additional-filters">
              <h2>{{'i18n.STATISTIC_PAGE.STM_STATISTICS.ADDITIONAL_FILTERS' |
                translate}}</h2>
              <ul class="filters-list">
                <li class="tree-type">
                  <label for="tree_type_selection">{{'i18n.STATISTIC_PAGE.STM_STATISTICS.TREE_TYPE' |
                    translate}}</label>
                  <!-- drop down box -->
                   <div class="tree-type-drop-down">
                      <input id="tree_type_selection" [(ngModel)]="treeTypeInput" type="text" title="tree type" (focus)="onTreeTypeFocus()">
                      <ul class="tree-type-drop-selection" [ngClass]="{'expanded' : isTreeTypeInputFocused}">
                        <li class="tree-type-list-item" [ngClass]="{'selected' : treeTypeInput.includes(treeType)}" *ngFor="let treeType of treeTypesList" (click)="handleSelectTreeType(treeType)">{{treeType}}</li>
                      </ul>
                   </div>
                </li>
                <li class="bucket-sizes">
                    <label for="bucket_input">{{'i18n.STATISTIC_PAGE.STM_STATISTICS.BUCKET_SIZE' |
                      translate}}</label>
                    <ul class="buckets-list">
                      <li [ngClass]="{'selected' : selectedBuckets.includes(bucket) }" *ngFor="let bucket of bucketSizes" (click)="handleBucketSizeClick(bucket)">{{bucket}}</li>
                    </ul>
                </li>
                <!-- <li class="notes">
                  <label for="note_input">{{'i18n.STATISTIC_PAGE.STM_STATISTICS.NOTES' |
                    translate}}</label>
                  <input [(ngModel)]="notesInput" id="note_input" type="text" title="note" placeholder="">
                </li>
                <li class="damage">
                  <label>{{'i18n.STATISTIC_PAGE.STM_STATISTICS.DAMAGE' |
                   translate}}</label>
                  <div class="damaged-checkboxes">
                      <div class="option">
                          <label for="damaged_checkbox_true">{{'i18n.STATISTIC_PAGE.STM_STATISTICS.TRUE' |
                            translate}}</label>
                          <input (change)="handleDamagedValueChangeTrue($event)" [checked]="(damagedValue === true)" id="damaged_checkbox_true" type="checkbox">
                      </div>
                      <div class="option">
                        <label for="damaged_checkbox_false">{{'i18n.STATISTIC_PAGE.STM_STATISTICS.FALSE' |
                          translate}}</label>
                        <input (change)="handleDamagedValueChangeFalse($event)" [checked]="(damagedValue === false)" id="damaged_checkbox_false" type="checkbox">
                    </div>
                  </div>
                </li> -->
                <li class="buttons">
                  <button *ngIf="filteredSummary != null" class="clear-filters" (click)="clearFilters()">{{'i18n.STATISTIC_PAGE.STM_STATISTICS.CLEAR_FILTERS' |
                    translate}}</button>
                  <button (click)="applyAllFilters()">{{'i18n.STATISTIC_PAGE.STM_STATISTICS.APPLY' |
                    translate}}</button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="item global_tile " *ngIf="summary && summary.length != 0" id="export_pdf">
          <table *ngIf="filteredSummary == null" class="table_users" id="count_table">
            <thead  class="table_head">
              <th>{{'i18n.STATISTIC_PAGE.TREE_TYPE_NAME' | translate}}</th>
              <th *ngFor="let bucket of bucketSizes">{{bucket}}</th>
            </thead>
            <tbody *ngIf="filteredSummary == null" class="table_body">
              <!-- <tr id="hello1" class="table_row" *ngFor="let treeType of treeTypes;"> -->
              <tr id="hello1" class="table_row" *ngFor="let item of summary">
                <td>{{item.tree_type}}</td>
                <td *ngFor="let size of item.data">
                  <p *ngIf="size.value != 0" [ngClass]="{
                    'positive-number' : size.value > 0 && isFieldComparisonOn && selectedFieldsToCompare.length != 0,
                    'negative-number' : size.value < 0 && isFieldComparisonOn && selectedFieldsToCompare.length != 0,
                    '' :  !isFieldComparisonOn && selectedFieldsToCompare.length == 0
                  }"><span *ngIf="size.value > 0 && isFieldComparisonOn && selectedFieldsToCompare.length != 0">+</span>{{size.value}}</p>
                  <p *ngIf="size.value == 0">-</p>
                </td>
              </tr>
            </tbody>

          </table>
          <table *ngIf="filteredSummary != null" class="table_users" id="count_table_2">
            <thead *ngIf="filteredSummary != null" class="table_head">
              <th>{{'i18n.STATISTIC_PAGE.TREE_TYPE_NAME' | translate}}</th>
              <th *ngFor="let bucket of filteredSummary[0].data">{{bucket.bucket}}</th>

            </thead>
            <tbody  *ngIf="filteredSummary != null" class="table_body">
              <tr id="hello1" class="table_row" *ngFor="let item of filteredSummary">
                <td>{{item.tree_type}}</td>
                <td *ngFor="let size of item.data">
                  <p *ngIf="size.value != 0" [ngClass]="{
                    'positive-number' : size.value > 0 && isFieldComparisonOn && selectedFieldsToCompare.length != 0,
                    'negative-number' : size.value < 0 && isFieldComparisonOn && selectedFieldsToCompare.length != 0,
                    '' :  !isFieldComparisonOn && selectedFieldsToCompare.length == 0
                  }"><span *ngIf="size.value > 0 && isFieldComparisonOn && selectedFieldsToCompare.length != 0">+</span>{{size.value}}</p>
                  <p *ngIf="size.value == 0">-</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="loader-container" *ngIf="summary && summary.length == 0">
          <span class="loader"></span>
        </div>
      </div>




      <!-- Translations for i18 file -->
      <!-- "LEADERBOARD": "Leaderboard",
        "FASTEST_SPECIES": "fastest growing species",
        "MOST_SPECIES": "highest count species",
        "BIGGEST_TREE": "biggest tree",
        "FASTEST_FIELD": "fastest growing field",
        "COMPANY_SUMMARY": "Company Summary",
        "TOTAL_TREES": "total trees",
        "AMOUNT_PER_SIZE_SPECIES": "amount per size bucket per type",
        "PER_ACRE": "average amount of trees per acre",
        "TREES_PER_TYPE": "total trees per type",
        "COMPARE_FIELDS": "Compare Different Fields",
        "FIELD_SELECTOR": "Field selector",
        "GROWTH_PERCENTAGE": "average growth in percentages",
        "GROWTH_SPECIES_PERCENTAGE": "same species growth percentages per field",
        "COMPARE_RUNS": "Compare Different Runs",
        "RUN_SELECTOR": "Run selector",
        "AMOUNT_OF_RUNS": "total amount of measurement runs",
        "GROWTH_TIME": "growth by time",
        "BEST_GROWING_SECTION": "best growing section",
        "FIELD_DETAILS": "Field Details",
        "AMOUNT_PER_TYPE": "amount of trees per type",
        "AMOUNT_PER_SIZE": "amount of trees per size",
        "AMOUNT_FREE_SPOTS": "amount of availible tree spots",
        "AVG_SIZE": "average tree size" -->


      <!-- <div class="content" id="export_pdf">
        <div class="section">
          <h1>Leaderboard</h1>
          <div class="item_box">
            <div class="global_tile item">
              <p class="item_header">fastest growing species</p>
              <p class="type_sub_header">Some species:</p>
              <p class="type_number">4.6</p>
              <p class="type_sub_header">cm in 2 months</p>
            </div>
            <div class="global_tile item">
              <p class="item_header">highest count species</p>
              <p class="type_sub_header">Some species:</p>
              <p class="type_number">4785<span>pcs</span></p>
            </div>
            <div class="global_tile item">
              <p class="item_header">biggest tree</p>
              <p class="type_sub_header">Some species:</p>
              <p class="type_number">72.4<span>cm</span></p>
              <p class="type_sub_header">id: 1853</p>
              <p class="type_sub_header">field: some field</p>

            </div>
            <div class="global_tile item">
              <p class="item_header">fastest growing field</p>
              <p class="type_sub_header">some field:</p>
              <p class="type_sub_header">grew 25% in some time</p>
            </div>
          </div>
        </div>

        <div class="section">
          <h1>Company Summary</h1>
          <div class="item_box">
            <div class="duo_item">
              <div class="global_tile duo_item_child">
                <p class="item_header">total trees</p>
                <p class="type_number">101.244</p>
              </div>
              <div class="global_tile duo_item_child">
                <p class="item_header">average amount of trees per acre</p>
                <p class="type_sub_header">total acres:</p>
                <p class="type_number">2452</p>
                <p class="type_sub_header">trees per acre:</p>
                <p class="type_number">127</p>
              </div>
            </div>
            <div class="global_tile item item_graph">
              <p class="item_header">amount per size bucket per type (selectable type)</p>
              <plotly-plot [data]="treesPerType.data" [layout]="treesPerType.layout" [config]="globalSettings.config"
                [updateOnDataChange]="true" [useResizeHandler]="true"></plotly-plot>
            </div>
            <div class="global_tile item item_graph">
              <p class="item_header">total trees per type (selectable type)</p>
              <plotly-plot [data]="treesPerType.data" [layout]="treesPerType.layout" [config]="globalSettings.config"
                [updateOnDataChange]="true" [useResizeHandler]="true"></plotly-plot>
            </div>

          </div>
        </div>

        <div class="section">
          <h1>Compare Different Fields</h1>
          <div class="item_box">
            <div class="global_tile item item_wide">
              <p class="item_header">Field selector</p>
              <div class="field_selector_container">


                <div class="search_drop">
                  <button (click)="showSearch1 = !showSearch1">Selecteer veld ↓</button>
                  <div *ngIf="showSearch1" class="search_drop_section">
                    <input type="text" class="global_input_field" placeholder="Veldnaam..."
                      (keyup)='update_search($event)'>
                    <div *ngFor="let s of options">
                      <div *ngIf="s.toLowerCase().includes(search.toLowerCase())" class="field_selection"><input
                          type="checkbox" value="s">
                        <p>{{s}}</p>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="selection_details">
                  [here we need some info about the fields, last measurement, first measurement, amount of trees, field
                  name,
                  size in acres, shape as svg]
                </div>
              </div>
            </div>

          </div>
          <div class="global_tile item">
            <p class="item_header">average growth in percentages</p>
            line chart with different line per field (more than 2 support?)
            <plotly-plot [data]="treesPerTypeLine.data" [layout]="treesPerTypeLine.layout"
              [config]="globalSettings.config" [updateOnDataChange]="true" [useResizeHandler]="true"></plotly-plot>
          </div>
          <div class="global_tile item">
            <p class="item_header">same species growth percentages per field</p>
            only show species that are on atleast 2 selected fields,
            combined bar chart in percentages with bars for each field sticking together per species
            <plotly-plot [data]="treesPerType.data" [layout]="treesPerType.layout" [config]="globalSettings.config"
              [updateOnDataChange]="true" [useResizeHandler]="true"></plotly-plot>
          </div>
        </div>


        <div class="section">
          <h1>Compare Different Runs</h1>
          <div class="item_box">
            <div class="global_tile item item_wide">
              <p class="item_header">Run selector</p>
              [thing to select from a list of fields] <br />
              [thing to select a specific run from that field (slider for dates?)] <br />
              [here we need some info about the field, last measurement, first measurement, amount of trees, field name,
              size in acres, shape as svg]
            </div>
            <div class="global_tile item item_wide">
              <p class="item_header">growth per species</p>
              line chart with line for each species on that field with dates based on slider from run selector
              <plotly-plot [data]="treesPerTypeLine.data" [layout]="treesPerTypeLine.layout"
                [config]="globalSettings.config" [updateOnDataChange]="true" [useResizeHandler]="true"></plotly-plot>
            </div>
            <div class="global_tile item">
              <p class="item_header">total amount of measurement runs</p>
              number
            </div>
            <div class="global_tile item item_graph">
              <p class="item_header">growth by time (months)</p>
              line chart with months and decent spacing (average of field)
              <plotly-plot [data]="treesPerTypeLine.data" [layout]="treesPerTypeLine.layout"
                [config]="globalSettings.config" [updateOnDataChange]="true" [useResizeHandler]="true"></plotly-plot>
            </div>
            <div class="global_tile item item_wide">
              <p class="item_header">best growing section (heatmap)</p>
              heatmap of field based on multiple runs
              <div class="map_container">
                <mgl-map [style]="'mapbox://styles/mapbox/light-v9'" [zoom]="[num]" [center]="center"
                  (load)="map = $event; loadMap()" class="map-box map">
                </mgl-map>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <h1>Field Details</h1>
          <div class="item_box">
            <div class="global_tile item item_wide">
              <p class="item_header">Field selector</p>
              [thing to select from a list of fields]<br />
              [here we need some info about the field, last measurement, first measurement, amount of trees, field name,
              size in acres, shape as svg]
            </div>
            <div class="global_tile item">
              <p class="item_header">amount of trees per type</p>
              <p class="type_sub_header">[barchart or piechart]</p>
              <plotly-plot [data]="treesPerTypePie.data" [layout]="treesPerTypePie.layout"
                [config]="globalSettings.config" [updateOnDataChange]="true" [useResizeHandler]="true"></plotly-plot>
            </div>
            <div class="global_tile item item_graph">
              <p class="item_header">amount of trees per size</p>
              <p class="type_sub_header">[barchart]</p>
              <plotly-plot [data]="treesPerType.data" [layout]="treesPerType.layout" [config]="globalSettings.config"
                [updateOnDataChange]="true" [useResizeHandler]="true"></plotly-plot>
            </div>
            <div class="global_tile item item_graph">
              <p class="item_header">best growig section</p>
              <p class="type_sub_header">[heatmap on a map with sizes per coordinate]</p>
              <div class="map_container">
                <mgl-map [style]="'mapbox://styles/mapbox/light-v9'" [zoom]="[num]" [center]="center"
                  (load)="map = $event; loadMap()" class="map-box map">
                </mgl-map>
              </div>

            </div>
            <div class="duo_item">
              <div class="global_tile duo_item_child">
                <p class="item_header">amount of availible tree spots</p>
                <p class="type_number">72<span> empty spots</span></p>

              </div>
              <div class="global_tile duo_item_child">
                <p class="item_header">average tree size</p>
                <p class="type_number">20-25<span>cm</span></p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
