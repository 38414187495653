import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'stem-thickness-measurement-data-component',
  templateUrl: './stem-thickness-measurement-data.component.html',
  styleUrls: ['./stem-thickness-measurement-data.component.css']
})

export class StemThicknessMeasurementData implements OnInit {
    @Input() isVisible: any = true;
    @Input() displayData: any;

    statusMap = {
      "none": "i18n.APP_SPECIFIC.PREMARKING.STATUS_NONE",
      "not-for-sale": "i18n.APP_SPECIFIC.PREMARKING.STATUS_NOT_FOR_SALE",
      "for-sale": "i18n.APP_SPECIFIC.PREMARKING.STATUS_FOR_SALE",
      "in-stock": "i18n.APP_SPECIFIC.PREMARKING.STATUS_IN_STOCK"
    }
    // custom data
    customDataFields: any[] = [];

    get stmCustomDataFields() {
        return this.customDataFields.filter(field => field.contexts.includes('stm_portal'));
    }

    constructor(private apiService: ApiService){}

    ngOnInit(){
      this.getCustomDataFields();
    }

    getFormatedCircumference(){
      const value = this.displayData.value.circumference;

      if(localStorage.getItem('lang')){
        const language = localStorage.getItem('lang');
        if(language == "nld"){
          let decimalValue = value.toFixed(1);
          return decimalValue.replace('.', ',');
        }
        else{
          return value.toFixed(1);
        }
      }
      return value;
    }

    getStatusText() {
      if (this.displayData.value && this.displayData.value.meta && this.displayData.value.meta.premarking && this.displayData.value.meta.premarking.status) {
        return this.statusMap[this.displayData.value.meta.premarking.status] || this.statusMap["none"];
      }
      return this.statusMap["none"];
    }
    getCustomDataFields(){
      const appId = '7fa63b0e-ee95-416d-8fa9-cc5dccfa2c4f';
      this.apiService.get_with_auth(`/custom-data/definitions?app_id=${appId}`).subscribe(data => {
        this.customDataFields = data.collection;
      })
    }

    getObjectKeys(obj: any): string[] {
      return Object.keys(obj);
    }

    getCustomDataValue(fieldId: string): string {
      if (this.displayData.value?.custom_data == undefined) return '';

      try {
        const customData = JSON.parse(this.displayData.value.custom_data);
        return customData[fieldId] || '';
      } catch (e) {
        return '';
      }
    }
}
