import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-pre-marking-data',
  templateUrl: './pre-marking-data.component.html',
  styleUrls: ['./pre-marking-data.component.css']
})
export class PreMarkingDataComponent implements OnInit{
  @Input() isVisible: any;
  @Input() displayData: any;

  // custom data
  customDataFields: any[] = [];

  get premarkingCustomDataFields() {
    return this.customDataFields.filter(field => field.contexts.includes('stm_premarking_portal'));
  }

  statusMap = {
    "none": "i18n.APP_SPECIFIC.PREMARKING.STATUS_NONE",
    "not-for-sale": "i18n.APP_SPECIFIC.PREMARKING.STATUS_NOT_FOR_SALE",
    "for-sale": "i18n.APP_SPECIFIC.PREMARKING.STATUS_FOR_SALE",
    "in-stock": "i18n.APP_SPECIFIC.PREMARKING.STATUS_IN_STOCK"
  }

  constructor(private apiService: ApiService){}

  ngOnInit(){
    this.getCustomDataFields();
  }

  getFormatedCircumference(){
    const value = this.displayData.value.premarkingCircumference;

    if(localStorage.getItem('lang')){
      const language = localStorage.getItem('lang');
      if(language == "nld"){
        let decimalValue = value.toFixed(1);
        return decimalValue.replace('.', ',');
      }
      else{
        return value.toFixed(1);
      }
    }
    return value;
  }

  getCustomDataFields(){
    const appId = '7fa63b0e-ee95-416d-8fa9-cc5dccfa2c4f';
    this.apiService.get_with_auth(`/custom-data/definitions?app_id=${appId}`).subscribe(data => {
      this.customDataFields = data.collection;
    })
  }

  getCustomDataValue(fieldId: string): string {
    if (this.displayData.value?.custom_data == undefined) return '';

    try {
      const customData = JSON.parse(this.displayData.value.custom_data);
      return customData[fieldId] || '';
    } catch (e) {
      return '';
    }
  }
}
