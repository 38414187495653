<div class="container">
  <h1>{{ 'i18n.CUSTOM_DATA.FORM.EDIT_TITLE' | translate }}</h1>
  <h2 *ngIf="selectedApp">{{ 'i18n.CUSTOM_DATA.FORM.FOR_APP' | translate:{ appName: selectedApp.name | translate } }}</h2>

  <div class="section">
    <small class="hint">{{ 'i18n.CUSTOM_DATA.FORM.REQUIRED_HINT' | translate }}</small>

    <form *ngIf="customDataForm" [formGroup]="customDataForm" (ngSubmit)="onSubmit()">
      <!-- Name Field -->
      <div class="form-field">
        <label for="name">{{ 'i18n.CUSTOM_DATA.FORM.FIELD_NAME' | translate }}</label>
        <input
          id="name"
          type="text"
          formControlName="name"
          [placeholder]="'i18n.CUSTOM_DATA.FORM.FIELD_NAME_PLACEHOLDER' | translate"
          [attr.aria-label]="'i18n.CUSTOM_DATA.FORM.FIELD_NAME' | translate">
        <div class="error" *ngIf="customDataForm.get('name').touched && customDataForm.get('name').errors?.required">
          {{ 'i18n.CUSTOM_DATA.FORM.VALIDATION.NAME_REQUIRED' | translate }}
        </div>
        <div class="error" *ngIf="customDataForm.get('name').touched && customDataForm.get('name').errors?.minlength">
          {{ 'i18n.CUSTOM_DATA.FORM.VALIDATION.NAME_MIN_LENGTH' | translate }}
        </div>
      </div>

      <!-- Type Field -->
      <div class="form-field">
        <label for="field_type">{{ 'i18n.CUSTOM_DATA.FORM.FIELD_TYPE' | translate }}</label>
        <select
          id="field_type"
          formControlName="field_type"
          [attr.aria-label]="'i18n.CUSTOM_DATA.FORM.FIELD_TYPE' | translate"
          [title]="'i18n.CUSTOM_DATA.FORM.FIELD_TYPE_PLACEHOLDER' | translate">
          <option *ngFor="let type of fieldTypes" [value]="type.id">
            {{ 'i18n.CUSTOM_DATA_PAGE.FIELD_TYPES.' + type.id.toUpperCase() | translate }}
          </option>
        </select>
        <div class="error" *ngIf="customDataForm.get('field_type').touched && customDataForm.get('field_type').errors?.required">
          {{ 'i18n.CUSTOM_DATA.FORM.VALIDATION.TYPE_REQUIRED' | translate }}
        </div>
      </div>

      <!-- Choices Field (conditional) -->
      <div class="form-field" *ngIf="showChoices()">
        <label for="choices">{{ 'i18n.CUSTOM_DATA.FORM.CHOICES' | translate }}</label>
        <textarea
          id="choices"
          formControlName="choices"
          [attr.aria-label]="'i18n.CUSTOM_DATA.FORM.CHOICES' | translate"
          [title]="'i18n.CUSTOM_DATA.FORM.CHOICES' | translate"
          [placeholder]="'i18n.CUSTOM_DATA.FORM.CHOICES_PLACEHOLDER' | translate"
          rows="5"></textarea>
        <div class="hint">{{ 'i18n.CUSTOM_DATA.FORM.CHOICES_HINT' | translate }} {{ 'i18n.CUSTOM_DATA.FORM.CHOICES_PASTE_HINT' | translate }}</div>
        <div class="error" *ngIf="customDataForm.get('choices').touched && customDataForm.get('choices').errors?.required">
          {{ 'i18n.CUSTOM_DATA.FORM.VALIDATION.CHOICES_REQUIRED' | translate }}
        </div>
      </div>

      <!-- Context Field -->
      <div class="form-field">
        <label id="context-group-label">{{ 'i18n.CUSTOM_DATA.FORM.CONTEXT' | translate }}</label>
        <div class="checkbox-group" role="group" [attr.aria-labelledby]="'context-group-label'">
          <div *ngFor="let ctx of contexts" class="checkbox-item">
            <input
              type="checkbox"
              [id]="ctx.slug"
              [value]="ctx.slug"
              [checked]="customDataForm.get('contexts').value?.includes(ctx.slug)"
              [title]="currentLang === 'nl' ? ctx.name_nl : ctx.name_en"
              [attr.aria-label]="currentLang === 'nl' ? ctx.name_nl : ctx.name_en"
              (change)="onContextChange($event, ctx.slug)">
            <label [for]="ctx.slug">{{currentLang === 'nl' ? ctx.name_nl : ctx.name_en}}</label>
          </div>
        </div>
        <div class="error" *ngIf="customDataForm.get('contexts').touched && customDataForm.get('contexts').errors?.required">
          {{ 'i18n.CUSTOM_DATA.FORM.VALIDATION.CONTEXT_REQUIRED' | translate }}
        </div>
      </div>

      <!-- Form Actions -->
      <div class="button-group">
        <div class="left-buttons">
          <button type="button" class="btn btn-danger" (click)="onDelete()" [attr.aria-label]="'i18n.CUSTOM_DATA.FORM.BUTTONS.DELETE' | translate">
            {{ 'i18n.CUSTOM_DATA.FORM.BUTTONS.DELETE' | translate }}
          </button>
        </div>
        <div class="right-buttons">
          <button type="button" class="btn btn-secondary" (click)="onCancel()" [attr.aria-label]="'i18n.CUSTOM_DATA.FORM.BUTTONS.CANCEL' | translate">
            {{ 'i18n.CUSTOM_DATA.FORM.BUTTONS.CANCEL' | translate }}
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!isFormValid()" [attr.aria-label]="'i18n.CUSTOM_DATA.FORM.BUTTONS.SAVE' | translate">
            {{ 'i18n.CUSTOM_DATA.FORM.BUTTONS.SAVE' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
