import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventBusService } from 'src/app/services/event-bus.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';

@Component({
  selector: 'app-pre-marking-mutation',
  templateUrl: './pre-marking-mutation.component.html',
  styleUrls: ['./pre-marking-mutation.component.css']
})
export class PreMarkingMutationComponent implements OnInit, OnDestroy{
  @Input() isVisible: any = true;
  @Input() displayData: any;

  // to receive events
  private subscription: Subscription = new Subscription();

  circumference: number = null;
  thinned: boolean | string = null;
  selectedStatus: string | null = 'null';

  //success message
  showSuccessMessage = false;

  statusOptions = [
    {label: "i18n.APP_SPECIFIC.STM.DO_NOT_CHANGE", value: 'null'},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_NONE", value: "none"},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_NOT_FOR_SALE", value: "not-for-sale"},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_FOR_SALE", value: "for-sale"},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_MARKED", value: "marked"},
    {label: "i18n.APP_SPECIFIC.PREMARKING.STATUS_IN_STOCK", value: "in-stock"},
  ]

  mapStrategy;
  constructor(private portalAppsService: PortalAppsService, private organizationService: OrganizationService, private eventBusService: EventBusService){
      this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
  }


  ngOnInit(): void {
    this.subscription.add(
      this.eventBusService.events$.subscribe(data => {
        // Handle the event
        if(data.target == "premarkingMutationComponent"){
          if(data.action == "update"){
            this.renderData();
          }
          if(data.action == "success"){
            this.showSuccessMessage = true;
          }
        }
      })
    );
    this.renderData();
    this.showSuccessMessage = false;
  }

  renderData(){
    if(this.displayData.value != null){
      this.circumference = this.displayData.value.circumference || null;
      this.thinned = this.displayData.value.thinned != null ? this.displayData.value.thinned : false;
      this.selectedStatus = this.displayData.value.status != null ? this.displayData.value.status : "null"
    }
    else{
      this.circumference = null;
      this.thinned = null;
      this.selectedStatus = "null";
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async applyMutationChanges(){
    const inputs = {
      circumference : this.circumference,
      thinned: this.thinned,
      status: this.selectedStatus
    }

    this.mapStrategy.mutateSelectedPremarkingPoints(inputs);
  }

  onStatusChange(selectedValue: string) {
    this.selectedStatus = selectedValue;
  }
}
