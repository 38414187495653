import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';
import { IMapStrategy } from '../../iMapStrategy';
import { OrganizationService } from 'src/app/services/organization.service';
import { switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'stm-control-set-component',
  templateUrl: 'stm-control-set.component.html',
  styleUrls: ['stm-control-set.component.css'],
  styles: [":host {height: 100%}"]
})

export class StmConrtolSetComponent implements OnInit {
  portalAppMapStrategy: any;

  // premarking
  premarkingAvailable = false;

  isToThinnViewOpen = false;

  constructor(private portalAppsService: PortalAppsService, private organizationService: OrganizationService, private userService: UserService) { }


  ngOnInit() {
    this.portalAppMapStrategy = this.portalAppsService.activeApp.mapStrategy;
    this.portalAppMapStrategy.intializeMapConrtols();
    // check for premarking availability

    let orgMembers = [];
    let premarkingCondition;

    this.organizationService.get_organization().pipe(
      switchMap((organization) => {
        // Process the first subscription
        if (organization.apps['pre-marking'] != null) {
          premarkingCondition = organization.apps['pre-marking'] === 'e443a3e7-11ea-4936-8167-2ff7454cdaf4';
        } else {
          premarkingCondition = false;
        }

        // Return the second observable
        return this.organizationService.get_members();
      }),
      switchMap((members) => {
        orgMembers = members.collection;
        return this.userService.get_user();
      })
    ).subscribe((userInfo) => {
      // Process the second subscription result
        if(premarkingCondition){

          for(let i=0; i < orgMembers.length; i++){
            if(orgMembers[i].id == userInfo.id){
              if(orgMembers[i].role.slug != 'admin'){
                this.premarkingAvailable = false;
              }
              else{
                this.premarkingAvailable = true;
              }
              break;
            }
          }
        }
    });

  }

  changePremarkingView(option){
    this.portalAppMapStrategy.togglePremarking(option);

  }

  displayThinning = false; // temp
  handleDisplayThinning(){
    this.displayThinning = !this.displayThinning;
    this.portalAppMapStrategy.showThinnedTrees(this.displayThinning);
  }

  toggleToThinView(){
    this.portalAppMapStrategy.toggleToThinView();
  }



}
