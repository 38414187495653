import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DbscanService {

  private epsilon: number = 0;
    private minPoints: number = 0;
    private labels: number[] = [];

    constructor() {
    }

    initialize(epsilon: number, minPoints: number) {
      this.epsilon = epsilon;
      this.minPoints = minPoints;
  }

    fit(points: [number, number][]): number[] {
        const clusters = [];
        let clusterId = 0;
        this.labels = new Array(points.length).fill(-1); // initialize as -1 (unclassified)

        for (let i = 0; i < points.length; i++) {
            if (this.labels[i] !== -1) continue; // already labeled
            const neighbors = this.getNeighbors(points, i);
            if (neighbors.length < this.minPoints) {
                this.labels[i] = 0; // mark as noise
            } else {
                clusterId++;
                this.expandCluster(points, i, neighbors, clusterId);
            }
        }
        return this.labels;
    }

    private getNeighbors(points: [number, number][], pointIndex: number): number[] {
        const neighbors = [];
        for (let i = 0; i < points.length; i++) {
            if (this.calculateDistance(points[pointIndex], points[i]) <= this.epsilon) {
                neighbors.push(i);
            }
        }
        return neighbors;
    }

    private calculateDistance(point1: [number, number], point2: [number, number]): number {
      const dx = point1[0] - point2[0];
      const dy = point1[1] - point2[1];
      return Math.sqrt(dx * dx + dy * dy);
  }

    private expandCluster(points: [number, number][], pointIndex: number, neighbors: number[], clusterId: number) {
        this.labels[pointIndex] = clusterId;
        let i = 0;
        while (i < neighbors.length) {
            const neighborIndex = neighbors[i];
            if (this.labels[neighborIndex] === 0) {
                this.labels[neighborIndex] = clusterId; // change noise to border point
            }
            if (this.labels[neighborIndex] === -1) {
                this.labels[neighborIndex] = clusterId;
                const newNeighbors = this.getNeighbors(points, neighborIndex);
                if (newNeighbors.length >= this.minPoints) {
                    neighbors = neighbors.concat(newNeighbors);
                }
            }
            i++;
        }
    }

}
