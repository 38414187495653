<div class="spacing_non_map_pages">

  <div fxLayout="column" class="flex-container">
    <div class="fields_heading">
      <div class="navigation_arrows">

        <h1 id="menu-text" class="unselectable">{{'i18n.FIELDS_PAGE.TITLE' | translate}}</h1>
        <div class="paginate-buttons unselectable">
          <div class="previous-devices paginate-button pointer-click" (click)="showFieldsForPage(activePage - 1)">
            <p class="paginate-arrows"><b>
                < </b>
            </p>
          </div>
          <p *ngFor="let p of pages" class="paginate-number pointer-click" [ngClass]="{'active-page': activePage == p}"
            (click)="showFieldsForPage(p)">{{p}}</p>
          <div class="previous-devices paginate-button pointer-click" (click)="showFieldsForPage(activePage + 1)">
            <p class="paginate-arrows"><b> > </b></p>
          </div>
        </div>
      </div>

      <button [routerLink]="['/apps/' + this.activeAppCode + '/fields/add']" class="global_btn add_field_btn unselectable">+
        {{'i18n.FIELDS_PAGE.NEW_FIELD' | translate}}</button>
    </div>

    <div class="fields-display-controls">
      <input type="text" [(ngModel)]="nameFilterInput" (ngModelChange)="handleNameFilterInput($event)">
      <div class="sort-container">
        <button class="toggle-sort-btn" (click)="toggleSortingMenu()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M183.6 42.4C177.5 35.8 169 32 160 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L128 146.3 128 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-301.7 32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 64c0 17.7 14.3 32 32 32l50.7 0-73.4 73.4c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l128 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-50.7 0 73.4-73.4c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L352 32c-17.7 0-32 14.3-32 32zm96 192c-12.1 0-23.2 6.8-28.6 17.7l-64 128-16 32c-7.9 15.8-1.5 35 14.3 42.9s35 1.5 42.9-14.3l7.2-14.3 88.4 0 7.2 14.3c7.9 15.8 27.1 22.2 42.9 14.3s22.2-27.1 14.3-42.9l-16-32-64-128C439.2 262.8 428.1 256 416 256zM395.8 400L416 359.6 436.2 400l-40.4 0z"/></svg>
           <span>{{'i18n.FIELDS_PAGE.SORT' | translate}}</span>
        </button>
        <ul *ngIf="sortingMenuOpen" class="sort-options">
          <li [ngClass]="{'selected' : sortItem == selectedSort}" (click)="selectSortingItem(sortItem)" *ngFor="let sortItem of sortingOptions">{{sortItem | translate}} <span *ngIf="sortItem == selectedSort"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></span></li>
        </ul>
      </div>
      <div class="view-on-the-map-btn">
        <button (click)="navigateToFieldsMapOverview()">{{'i18n.FIELDS_PAGE.VIEW_ON_THE_MAP' | translate}}</button>
      </div>
    </div>



    <div *ngIf="fieldList">
      <div class="no_fields_note" *ngIf="fieldList.items.length == 0">
        {{'i18n.FIELDS_PAGE.NO_FIELDS' | translate}}
      </div>
      <div *ngIf="fieldList.items.length != 0">
        <div class="content" *ngIf="!isLoading">
          <ng-container *ngFor="let field of fieldsToDisplay">
            <div class="global_tile card pointer-click" (click)="go_to_field(field.id, field.center)">
              <div #test class="svg_container">
                <svg style="width: 100%; height: 100%;">
                  <polygon [attr.points]="getPoints(field)"
                    style="stroke:purple;stroke-width:1;fill-rule:evenodd; fill: lightcoral;" />
                  {{'i18n.FIELDS_PAGE.NO_POLYGON_SUPPORT' | translate}}
                </svg>
              </div>
              <div class="field_card_header">
                <span>{{field.name}}</span>

                <div class="global-option-icon unselectable" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                  <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                    style="pointer-events: none; display: block; width: 100%; height: 100%;">
                    <g>
                      <path
                        d="M12,16.5c0.83,0,1.5,0.67,1.5,1.5s-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S11.17,16.5,12,16.5z M10.5,12 c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5S10.5,11.17,10.5,12z M10.5,6c0,0.83,0.67,1.5,1.5,1.5 s1.5-0.67,1.5-1.5S12.83,4.5,12,4.5S10.5,5.17,10.5,6z">
                      </path>
                    </g>
                  </svg>
                  <mat-menu #menu="matMenu">
                    <!-- <button mat-menu-item (click)="editField(field.id)">
                      <mat-icon>edit</mat-icon>
                      <ng-container>Edit</ng-container>
                    </button> -->
                    <button mat-menu-item (click)="archiveField(field.id)">
                      <mat-icon>archive</mat-icon>
                      <ng-container>{{'i18n.GLOBAL.ARCHIVE' | translate}}</ng-container>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="isLoading">
          <span class="loader">
            <span class="filler" id="loader_filler"></span>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>
