<p class="field_name" *ngIf="portalAppMapStrategy.selectedField">{{this.portalAppMapStrategy.selectedField.name}}‎</p>

        <mat-label>{{'i18n.FIELD_PAGE.DATASET' | translate}}</mat-label>

        <div class="date_slector">
            <!-- <input style="border: solid #3D9B47 1px" class="global_input_field" placeholder="{{'i18n.GLOBAL.SEARCH' | translate}}"
                (keyup)="portalAppMapStrategy.searchedDate = $event.target.value"> -->
            <div class="date_list" *ngIf="portalAppMapStrategy.selectedField">
                <div *ngIf="portalAppMapStrategy.searchedDate == ''">
                    <p  (click)="portalAppMapStrategy.dateChange('all')" [ngStyle]="{'background-color': (portalAppMapStrategy.selectedDate == 'all') ? '#daffde' : '#fff'}"><b>{{'i18n.FIELD_PAGE.ALL_TIME_OVERVIEW' | translate}}</b></p>
                </div>
                <div *ngFor="let date of portalAppMapStrategy.availibleDates">
                    <p *ngIf="date.includes(portalAppMapStrategy.searchedDate)" (click)="portalAppMapStrategy.dateChange(date)"
                        [ngStyle]="{'background-color': (date.includes(portalAppMapStrategy.selectedDate)) ? '#daffde' : '#fff'}">
                        {{date.substring(0, 10)}}
                    </p>
                </div>
            </div>
        </div>
        <!-- <button (click)="generateColorsTMP()">test gen</button> -->



        <!-- <div class="option_container">
            <label class="switch">
                <input type="checkbox" value="treeMeasurementData-layer" (change)="portalAppMapStrategy.changeMapStyle($event)">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{'i18n.FIELD_PAGE.SATELLITE' |
                translate}}</p>
        </div> -->

        <div class="global_devider"></div>
        <div class="map-actions-row">
          <div class="satellite-view">
            <mat-label>{{'i18n.FIELD_PAGE.MAP_STYLE' | translate}}</mat-label>
            <div class="option_container map_actions">
              <app-toggle-satellite-view></app-toggle-satellite-view>
            </div>
          </div>
          <div class="center-view">
            <mat-label>{{'i18n.FIELD_PAGE.MAP_ACTIONS' | translate}}</mat-label>
            <div class="option_container map_actions">
                <!-- <app-dynamic-importable-component *ngFor="let action of portalAppMapStrategy.mapActions" [component]="action"></app-dynamic-importable-component> -->
                <go-to-field-action-component></go-to-field-action-component>
            </div>
          </div>
        </div>


        <div class="global_devider"></div>
        <mat-label>{{'i18n.FIELD_PAGE.POINTS_SELECTION' | translate}}</mat-label>
        <div class="option_container point-selection">
            <app-dynamic-map-points-control *ngFor="let selection of portalAppMapStrategy.pointsSelections" [component]="selection"></app-dynamic-map-points-control>

        </div>

        <div *ngIf="!portalAppMapStrategy.showArchivedData" class="global_devider"></div>
        <mat-label *ngIf="!portalAppMapStrategy.showArchivedData">{{'i18n.FIELD_PAGE.POINTS_CREATION' | translate}}</mat-label>
        <div *ngIf="!portalAppMapStrategy.showArchivedData" class="option_container point-creation">
            <app-dynamic-map-points-control *ngFor="let creation of portalAppMapStrategy.pointsCreation" [component]="creation"></app-dynamic-map-points-control>

        </div>

        <div class="global_devider"></div>
        <mat-label>{{'i18n.FIELD_PAGE.VISUALIZATION' | translate}}</mat-label>

        <map-visualize-by-attribute [attributes]="['i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_CIRCUMFERENCE', 'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.TREE_TYPE', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.DAMAGED', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.AB_QUALITY', 'i18n.APP_SPECIFIC.STM.META_DATA_MEASUREMENTS.NOTE']"></map-visualize-by-attribute>

        <!-- Field is availbale by default -->
        <div class="option_container">
            <label class="switch">
                <input type="checkbox" value="fieldBoundery-layer"
                    [ngModel]="portalAppMapStrategy.visibleLayers.includes('fieldBoundery-layer')" (change)="portalAppMapStrategy.showhideLayer($event)">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{'i18n.FIELD_PAGE.FIELD'
                | translate}}</p>
        </div>

        <!-- Portal defined toggles -->
        <div *ngFor="let layer of portalAppMapStrategy.toggleLayers" class="option_container">
            <label class="switch">
                <input type="checkbox" value="{{layer.layerName}}" [ngModel]="portalAppMapStrategy.visibleLayers.includes(layer.layerName)" (change)="portalAppMapStrategy.showhideLayer($event)">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{layer.title  | translate}}</p>
        </div>

        <div class="option_container">
            <label class="switch">
                <input type="checkbox" value="archived-data"
                    [ngModel]="portalAppMapStrategy.showArchivedData" (change)="portalAppMapStrategy.toggleArchivedPoints()">
                <span class="slider"></span>
            </label>
            <p class="option_label">{{'i18n.FIELD_PAGE.SHOW_ARCHIVED' | translate}}</p>
        </div>

        <button class="global_btn export_btn" (click)="portalAppMapStrategy.exportTableToExcel()"
            [ngStyle]="{'display': (portalAppMapStrategy.visibleLayers.includes('treeMeasurementData-layer')) ? 'block' : 'none'}">{{'i18n.FIELD_PAGE.EXPORT_EXCEL'
            | translate}}</button>
