<div class="thinning-container">
  <h3>{{'i18n.APP_SPECIFIC.PREMARKING.THINNING' | translate}}</h3>

  <div class="thinning-types-container">
    <div class="thinning-types-header">
      <div [ngClass]="{'selected': thinningType == 'selection'}" class="thinning-option" (click)="changeThinningType('selection')">Selection</div>
      <div [ngClass]="{'selected': thinningType == 'row'}"  class="thinning-option" (click)="changeThinningType('row')">Row</div>
    </div>

    <div class="thinning-by-container">
      <div *ngIf="thinningType === 'selection'" class="thinning-by-selection">
        <h4>{{'i18n.APP_SPECIFIC.PREMARKING.THINNING_BY_SELECTION' | translate}}</h4>

        <div *ngIf="showErrorSelectionMessage" class="message-selection">
          {{'i18n.APP_SPECIFIC.PREMARKING.THINNING_SELECTION_WARNING_MESSAGE' | translate}}
        </div>

        <div *ngIf="!showErrorSelectionMessage" class="thinning-selection-percentage">
          <label>
            {{'i18n.APP_SPECIFIC.PREMARKING.PERCENTAGE_TO_BE_THINNED' | translate}}
            <input type="number" min="0" max="100" step="1" [(ngModel)]="toThinPercentageInput">
          </label>

          <div class="pattern-btns">
            <button (click)="showThinningBySelection()">{{'i18n.GLOBAL.GENERATE' | translate}}</button>
            <button (click)="clearPatern()">{{'i18n.GLOBAL.CLEAR' | translate}}</button>
          </div>

        </div>
      </div>

      <div *ngIf="thinningType === 'row'" class="thinning-by-row">
        <div class="section">
          <h4>{{'i18n.APP_SPECIFIC.PREMARKING.ROW_SELECTION' | translate}}</h4>
          <div class="content">


            <div class="generate-rows">
              <!-- <span>{{'i18n.APP_SPECIFIC.PREMARKING.CREATE_ROWS_THINNING' | translate}}</span> -->

              <!-- <button class="w-100" (click)="generateGuideRowsForThinning()">Generate</button>

              <div class="divider"></div> -->
              <div class="manual-row-selection">

                <div class="manual-rows-btns">
                  <button (click)="toggleRowSelectionForThinning(true)" [ngClass]="{'selected': portalAppMapStrategy.isRowSelectionForThiningEnabled}">Enabled</button>
                  <button (click)="toggleRowSelectionForThinning(false)" [ngClass]="{'selected': !portalAppMapStrategy.isRowSelectionForThiningEnabled}">Disabled</button>
                  <div class="row-selection-message" *ngIf="this.portalAppMapStrategy.isRowSelectionForThiningEnabled">
                    <span class="title">{{'i18n.APP_SPECIFIC.STM.ROW_SELECTION_ENABLED' | translate}}</span>
                    <span>{{'i18n.APP_SPECIFIC.PREMARKING.ROW_SELCTION_MESSAGE' | translate}}</span>
                  </div>
                </div>



              </div>
              <div class="divider"></div>
              <button class="w-100" (click)="clearGuideRows()">{{'i18n.APP_SPECIFIC.PREMARKING.CLEAR_GUIDE_ROWS' | translate}}</button>
            </div>
          </div>

        </div>

        <div class="section" *ngIf="!this.portalAppMapStrategy.isRowSelectionForThiningEnabled && this.portalAppMapStrategy.clickedTreesCoordsForThinnedRows.length >= 1 && this.portalAppMapStrategy.clickedTreesCoordsForThinnedRows[0].coordEnd">
          <h4>{{'i18n.APP_SPECIFIC.PREMARKING.PATTERN' | translate}}</h4>
          <div class="content">
            <div class="pattern-container">
              <label>{{'i18n.APP_SPECIFIC.PREMARKING.TO_KEEP' | translate}}: <input [(ngModel)]="treesToKeep" type="number"></label>
              <label>{{'i18n.APP_SPECIFIC.PREMARKING.TO_SKIP' | translate}}: <input [(ngModel)]="treesToSkip" type="number"></label>
            </div>

            <div class="divider"></div>

            <div class="pattern-btns">
              <button (click)="handleShowThinningResult()">{{'i18n.APP_SPECIFIC.PREMARKING.SHOW_RESULT' | translate}}</button>
              <button (click)="clearPatern()">{{'i18n.APP_SPECIFIC.PREMARKING.CLEAR_PATERN' | translate}}</button>
            </div>

          </div>

        </div>





      </div>
    </div>

    <div *ngIf="this.showSuccessMessage" class="success-messaage">
      <div class="message">
          {{'i18n.APP_SPECIFIC.PREMARKING.THINNING_SUCCESS_MESSAGE' | translate}}
      </div>
      <div class="progress-bar">
          <div class="progress-bar-filler"></div>
      </div>
    </div>

    <button (click)="applyThinning()" class="apply-btn" *ngIf="(thinningType == 'selection' && !showErrorSelectionMessage) || (!this.portalAppMapStrategy.isRowSelectionForThiningEnabled && this.portalAppMapStrategy.clickedTreesCoordsForThinnedRows.length >= 1 && this.portalAppMapStrategy.clickedTreesCoordsForThinnedRows[0].coordEnd)">{{'i18n.GLOBAL.APPLY' | translate}}</button>


  </div>

</div>
