import { KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventBusService } from 'src/app/services/event-bus.service';

@Component({
  selector: 'stem-thickness-legend-component',
  templateUrl: './stem-thickness-legend.component.html',
  styleUrls: ['./stem-thickness-legend.component.css'],
  styles: [":host {margin-top: auto;}"]
})

export class StemThicknessLegend  {
    @Input() isVisible: any;
    @Input() displayData: any;

    private subscription: Subscription = new Subscription();

    constructor(private eventBusService: EventBusService){}

    ngOnInit():void{
      this.subscription.add(
        this.eventBusService.events$.subscribe(data => {
          // Handle the event
          if(data.target == "legendComponent"){
            if(data.action == "scroll"){
              this.scrollToElement(data.item);
            }
          }
        })
      );
    }

    scrollToElement(item){

      const legend = document.getElementsByClassName("legend")[0] as HTMLElement;

      function scrollToText(text){
        const matchingElement = Array.from(legend.getElementsByTagName('p')).find(p => p.textContent.includes(text));

        if (matchingElement) {
          const topPosition = matchingElement.offsetTop - legend.offsetTop;
          legend.scrollTop = topPosition;
        } else {
          // console.log('Text not found in the container.');
        }
      }


      if(legend){
        if(this.displayData.value != null && legend.scrollHeight > legend.clientHeight){
          const availableAttributes = [
            'i18n.FIELD_PAGE.LEGEND_TREE_TYPE',
            'i18n.FIELD_PAGE.LEGEND_DAMAGED',
            'i18n.FIELD_PAGE.LEGEND_QUALITY',
            'i18n.FIELD_PAGE.LEGEND_NOTE',
          ];
          switch(this.displayData.value.title){
            case availableAttributes[0]:
              const treeType = item.treeType;
              scrollToText(treeType);
              break;

            case availableAttributes[1]:
              break;

            case availableAttributes[2]:
              const quality = JSON.parse(item.meta).abQuality;
              scrollToText(quality);
              break;

            case availableAttributes[3]:
              const note = JSON.parse(item.meta).note;
              scrollToText(note);
              break;
          }
        }
      }

    }

    customSort = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
      if (a.key === '(geen)') return 1; // Move `(geen)` to the end
      if (b.key === '(geen)') return -1; // Ensure `(geen)` is last
      return a.key.localeCompare(b.key); // Regular alphabetical order for other keys
    };
}
