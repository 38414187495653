import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from 'src/app/services/organization.service';
import { PortalAppsService } from 'src/app/services/portal-apps/portal-apps.service';
import { IPortalApp } from 'src/app/services/portal-apps/iportal-app';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';

interface CustomDataDefinition {
  id: string;
  name: string;
  field_type: string;
  contexts: string[];
  options: {
    values: string[];
  } | null;
  app_id: string;
  organization_id: string;
  created_at: string;
  updated_at: string;
}

@Component({
  selector: 'app-edit-custom-data',
  templateUrl: './edit-custom-data.component.html',
  styleUrls: ['./edit-custom-data.component.css']
})
export class EditCustomDataComponent implements OnInit {
  customDataForm: FormGroup;
  selectedApp: IPortalApp | null = null;
  currentLang: string;
  customDataId: string;
  customData: CustomDataDefinition | null = null;
  contexts = [];

  fieldTypes = [
    { id: 'text', name: 'Text' },
    { id: 'number', name: 'Number' },
    { id: 'single_choice', name: 'Single choice selection' },
    { id: 'multiple_choice', name: 'Multiple choice selection' }
  ];

  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private portalAppsService: PortalAppsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private apiService: ApiService
  ) {
    this.currentLang = this.translate.currentLang || this.translate.defaultLang;
    this.translate.onLangChange.subscribe(event => {
      this.currentLang = event.lang;
    });
  }

  ngOnInit(): void {
    this.customDataId = this.route.snapshot.paramMap.get('id');
    this.selectedApp = this.portalAppsService.getSelectedApp();

    if (!this.selectedApp) {
      this.router.navigate(['/custom-data']);
      return;
    }

    this.contexts = this.selectedApp.contextViews;
    this.loadCustomData();
  }

  private loadCustomData(): void {
    this.apiService.get_with_auth(`/custom-data/definition/${this.customDataId}`).subscribe(
      (data: CustomDataDefinition) => {
        this.customData = data;
        this.initForm();
      },
      error => {
        console.error('Error loading custom data:', error);
        this.router.navigate(['/custom-data']);
      }
    );
  }

  private initForm(): void {
    if (!this.customData) return;

    // Ensure contexts is an array
    const contextValue = Array.isArray(this.customData.contexts) ? this.customData.contexts : [this.customData.contexts];

    // Get options values from the correct structure
    const optionsValue = this.customData.options?.values ? this.customData.options.values.join('\n') : '';

    this.customDataForm = this.fb.group({
      name: [this.customData.name, [Validators.required, Validators.minLength(3)]],
      field_type: [this.customData.field_type, [Validators.required]],
      choices: [optionsValue],
      contexts: [contextValue, [Validators.required, Validators.minLength(1)]]
    });

    // Subscribe to field type changes to handle choices validation
    this.customDataForm.get('field_type').valueChanges.subscribe(type => {
      const choicesControl = this.customDataForm.get('choices');
      if (type === 'single_choice' || type === 'multiple_choice') {
        choicesControl.setValidators([Validators.required]);
      } else {
        choicesControl.clearValidators();
        choicesControl.setValue('');
      }
      choicesControl.updateValueAndValidity();
    });

    // Subscribe to choices changes to update form validity
    this.customDataForm.get('choices').valueChanges.subscribe(value => {
      const type = this.customDataForm.get('field_type').value;
      if (type === 'single_choice' || type === 'multiple_choice') {
        if (!value || value.trim() === '') {
          this.customDataForm.get('choices').setErrors({ required: true });
        } else {
          this.customDataForm.get('choices').setErrors(null);
        }
      }
      this.customDataForm.updateValueAndValidity();
    });

    // Subscribe to context changes to ensure at least one is selected
    this.customDataForm.get('contexts').valueChanges.subscribe(value => {
      if (!value || value.length === 0) {
        this.customDataForm.get('contexts').setErrors({ required: true });
      } else {
        this.customDataForm.get('contexts').setErrors(null);
      }
      this.customDataForm.updateValueAndValidity();
    });
  }

  onContextChange(event: any, contextId: string): void {
    const contextControl = this.customDataForm.get('contexts');
    const currentValue = contextControl.value || [];

    if (event.target.checked) {
      contextControl.setValue([...currentValue, contextId]);
    } else {
      contextControl.setValue(currentValue.filter(id => id !== contextId));
    }

    contextControl.updateValueAndValidity();
  }

  getContextName(contextSlug: string): string {
    const contextView = this.contexts.find(ctx => ctx.slug === contextSlug);
    return contextView ? (this.currentLang === 'nl' ? contextView.name_nl : contextView.name_en) : contextSlug;
  }

  getContextNames(contextSlugs: string[]): string {
    return contextSlugs.map(slug => this.getContextName(slug)).join(', ');
  }

  isContextSelected(contextSlug: string): boolean {
    return this.customDataForm?.get('contexts').value?.includes(contextSlug) || false;
  }

  showChoices(): boolean {
    const type = this.customDataForm?.get('field_type').value;
    return type === 'single_choice' || type === 'multiple_choice';
  }

  getFieldTypeName(type: string): string {
    const fieldType = this.fieldTypes.find(ft => ft.id === type);
    return fieldType ? fieldType.name : type;
  }

  isFormValid(): boolean {
    const nameValid = this.customDataForm?.get('name').valid;
    const contextValid = this.customDataForm?.get('contexts').valid;
    const choicesValid = this.showChoices()
      ? this.customDataForm?.get('choices').valid
      : true;

    return nameValid && contextValid && choicesValid;
  }

  onSubmit(): void {
    if (this.isFormValid() && this.selectedApp && this.customData) {
      // Format options as a list if present
      let options = null;
      if (this.showChoices()) {
        options = this.customDataForm.get('choices').value
          .split('\n')
          .map(choice => choice.trim())
          .filter(choice => choice !== '');
      }

      const formData = {
        name: this.customDataForm.get('name').value,
        field_type: this.customDataForm.get('field_type').value,
        contexts: this.customDataForm.get('contexts').value,
        app_id: this.selectedApp.measurementAppsMap[0],
        options: options
      };

      this.apiService.post_with_auth(`/custom-data/definition/${this.customDataId}`, formData).subscribe(
        () => {
          this.router.navigate(['/custom-data']);
        },
        error => {
          console.error('Error updating custom data:', error);
        }
      );
    }
  }

  onDelete(): void {
    if (confirm('Are you sure you want to delete this custom data field?')) {
      this.apiService.post_with_auth(`/custom-data/definition/${this.customDataId}/delete`).subscribe(
        () => {
          this.router.navigate(['/custom-data']);
        },
        error => {
          console.error('Error deleting custom data:', error);
        }
      );
    }
  }

  onCancel(): void {
    this.router.navigate(['/custom-data']);
  }
}
