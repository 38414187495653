import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDevice } from 'src/app/models/organization.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-device-page',
  templateUrl: './device-page.component.html',
  styleUrls: ['./device-page.component.css']
})
export class DevicePageComponent implements OnInit {

  constructor(private organizationService: OrganizationService, private permissionService: PermissionService, private router: Router) {
  }

  devices: IDevice[];
  connectivity: IDevice[] = [];
  camera: IDevice[] = [];

  ngOnInit(): void {
    if(this.permissionService.hasRightTo('view-devices')){
      this.organizationService.get_devices().subscribe(data => {
        this.devices = data.collection;
        for(let dev of this.devices){
          if(dev.type == 'connectivity'){
            this.connectivity.push(dev);
          }
          else{
            this.camera.push(dev);
          }
        }
      });
    }
    else{
      this.router.navigate["/apps"]
    }

  }
}
