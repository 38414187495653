import MapboxDraw from "@mapbox/mapbox-gl-draw";
import FreehandMode from "mapbox-gl-draw-freehand-mode";
import * as turf from "@turf/turf";

let map;
let updatePointsFunc;
let draw;


export function TreeMeasurementDataLassoHandler(mapObj, functionCallBack){
      map = mapObj;
      updatePointsFunc = functionCallBack;

      draw = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: false,
          trash: false
        },
        modes: Object.assign(MapboxDraw.modes, {
          draw_polygon: FreehandMode
        }),
        // Set mapbox-gl-draw to draw by default.
        // The user does not have to click the polygon control button first.
        defaultMode: "draw_polygon"
      });

      map.addControl(draw);

      map.on("draw.create", updateArea);
      map.on("draw.delete", updateArea);
      map.on("draw.update", updateArea);


          // Add an event listener for the Enter key
    document.addEventListener('keydown', handleKeyPress);
}

// Handle key press events
function handleKeyPress(event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      clearDrawControl();
    }
}

function updateArea(e) {
    const data = draw.getAll();
    if (!data || !data.features || !data.features[0]) {
      return;
    }

    const filterPolygon = data.features[0];

    const bbox = turf.bbox(filterPolygon);

    const featuresInBBox = map.queryRenderedFeatures(
      [map.project([bbox[0], bbox[1]]), map.project([bbox[2], bbox[3]])],
      { layers: ["treeMeasurementData-layer"] }
    );

    const filteredFeatures = featuresInBBox.filter((f) =>
      isWithinOrIntersecting(filterPolygon, f)
    );

    updatePointsFunc(filteredFeatures);
  }

  function isWithinOrIntersecting(filterPolygon, feature) {
    if (feature.geometry.type == "MultiPolygon") {
      // TODO: Handle multipolygon...
    } else {
      return (
        turf.booleanIntersects(filterPolygon, feature) ||
        turf.booleanContains(filterPolygon, feature)
      );
    }
  }

export function clearDrawControl(){
  // Check if draw control exists
  if (draw) {
    // Delete all features (polygons, lines, points, etc.)
    draw.deleteAll();
    draw.changeMode('draw_polygon');
  }
}

export function isDrawConrtolOn(){
  return draw != null && draw != undefined
}

// Function to remove the draw control
export function removeDrawControl(mapObj) {
  try{
    if (draw) {
        mapObj.removeControl(draw);
        draw = null;
    }
    // Remove the event listener for the Enter key
    document.removeEventListener('keydown', handleKeyPress);
    mapObj.resize();
  }
  catch{
  }

}
