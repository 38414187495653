import { Component, Input, OnInit } from '@angular/core';
import { PortalAppsService } from '../../../portal-apps.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'selected-points-component',
  templateUrl: './selected-points.component.html',
  styleUrls: ['./selected-points.component.css']
})

export class SelectedPointsComponent implements OnInit {
    @Input() data: any;

    userRole = null;
    mapStrategy;
    constructor(private portalAppsService: PortalAppsService, public permissionService: PermissionService){
        this.mapStrategy = this.portalAppsService.activeApp.mapStrategy;
    }

    ngOnInit(): void {
      this.userRole = localStorage.getItem('user_role');
    }

    clearPoints(){
      this.mapStrategy.unselectAllPoints();
    }

    mutateSelectedPoints(){
      this.mapStrategy.handleMutateClickButton();
    }

    archiveSelectedPoints(){
      this.mapStrategy.archiveSelectedPoints();
    }

    unarchiveSelectedpoints(){
      this.mapStrategy.unarchiveSelectedPoints();
    }

    closeSelection(){
      const selectedMode = this.mapStrategy.pointSelectionMode.value;
      this.mapStrategy.changePointSelectionMode(selectedMode);
    }
}
