import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, PreloadingStrategy, Router, ROUTES, Route } from '@angular/router';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { CompanyManagementPageComponent } from './pages/company-management-page/company-management-page.component'
import { DevicePageComponent } from './pages/devices-page/device-page.component';
import { FieldsPageComponent } from './pages/fields-overview-page/fields-page.component';
import { AddFieldPageComponent } from './pages/add-field-page/add-field-page.component';
import { FieldPageComponent } from './pages/field-page/field-page.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { DataManagementPageComponent } from "./pages/data-management-page/data-management-page.component";
import { StatisticsPageComponent } from "./pages/statistics-page/statistics-page.component";
import { TermsAndConditionsComponent } from './pages/terms-and-conditions-page/terms-and-conditions.component';
import { SuperuserPageComponent } from './pages/superuser-page/superuser-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { FieldMapPageComponent } from './pages/field-map-page/field-map-page.component';
import { ApplicationsPageComponent } from "./pages/applications-page/applications-page.component";
import { PortalAppsService } from './services/portal-apps/portal-apps.service';
import { AppRouteResolver } from './services/portal-apps/appRouteResolver';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { AccessDeniedPageComponent } from './pages/access-denied-page/access-denied-page/access-denied-page.component';
import { PermissionGuard } from './services/permission.guard';
import { CustomDataPageComponent } from './pages/custom-data-page/custom-data-page.component';
import { AddCustomDataComponent } from './pages/add-custom-data/add-custom-data.component';
import { EditCustomDataComponent } from './pages/edit-custom-data/edit-custom-data.component';
import { ImportCustomDataComponent } from './pages/import-custom-data/import-custom-data.component';


export const standardRoutes: Routes = [

  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'login', redirectTo: 'welcome', pathMatch: 'full' },
  {
    path: 'welcome',
    component: LandingPageComponent,
    canActivate: [AuthGuard],

  },
  {
    path: 'access-denied',
    component: AccessDeniedPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfilePageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access', 'self-view-profile']}
  },
  {
    path: 'company-management',
    component: CompanyManagementPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access']}
  },
  {
    path: 'devices',
    component: DevicePageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access']}
  },
  {
    path: "apps",
    component: ApplicationsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access']}
  },
  {
    path: "apps/:appCode",
    component: ApplicationsPageComponent,
    resolve: {appData: AppRouteResolver},
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access']}
  },
  {
    path: 'terms',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access']}
  },
  {
    path: 'superuser',
    component: SuperuserPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access']}
  },
  {
    path: 'manage_data',
    component: DataManagementPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access']}
  },
  {
    path: 'help',
    component: HelpPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {permissions: ['portal-access']}
  },
  {
    path: 'custom-data',
    component: CustomDataPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'custom-data/add',
    component: AddCustomDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'custom-data/edit/:id',
    component: EditCustomDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'custom-data/import',
    component: ImportCustomDataComponent,
    canActivate: [AuthGuard],
  },
  {path: "**", component: LandingPageComponent,
    canActivate: [AuthGuard]}
];



@NgModule({
  imports: [RouterModule.forRoot(standardRoutes, {})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
