<div class="tree-measurement-data-component">
  <h3>{{'i18n.APP_SPECIFIC.PREMARKING.PREMARKING_DATA' | translate}}</h3>

  <div class="tree-measurement-data-overview" *ngIf="displayData.value">
      <table>
          <tr>
              <th>
                  {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.PROPERTY' | translate}}
              </th>
              <th>
                  {{'i18n.APP_SPECIFIC.STM.MAIN_DATA_MEASUREMENTS.VALUE' | translate}}
              </th>
          </tr>

          <tr>
              <td>{{'i18n.APP_SPECIFIC.PREMARKING.MARKED_VALUE' | translate}}</td>
              <td><span *ngIf="displayData.value.premarkingCircumference != null">{{displayData.value.premarkingCircumference}}</span></td>
          </tr>

          <tr>
            <td>{{'i18n.APP_SPECIFIC.PREMARKING.THINNED' | translate}}</td>
            <td><ng-container *ngIf="displayData.value.meta">
              <span *ngIf="displayData.value.meta.premarking != null">{{displayData.value.meta.premarking.thinned}}</span><span *ngIf="displayData.value.meta.premarking == null">false</span>
            </ng-container></td>
          </tr>
          <tr>
            <td>{{'i18n.APP_SPECIFIC.PREMARKING.STATUS' | translate}}</td>
            <td>
              <ng-container *ngIf="displayData.value.meta">
                <span *ngIf="displayData.value.meta.premarking != null">{{statusMap[displayData.value.meta.premarking.status] | translate}}</span><span *ngIf="displayData.value.meta.premarking == null">{{'i18n.APP_SPECIFIC.PREMARKING.STATUS_NONE' | translate}}</span>
              </ng-container>
            </td>
          </tr>
      </table>
  </div>

</div>
