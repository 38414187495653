import { Component } from "@angular/core";

@Component({
    selector: "app-help-page",
    templateUrl: "./help-page.component.html",
    styleUrls: ["./help-page.component.css"]
})
export class HelpPageComponent {

    faqItmes = [
        {
            subject: "subject 1",
            subquestions: [
                {
                    subquestion_name: "Subquestion 1",
                    htmlAnswer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. <b>AAA</b> Quibusdam voluptate veritatis fugiat necessitatibus quod, dolor aperiam itaque maiores iure tenetur deleniti excepturi consequuntur nobis laudantium?"
                },
                {
                    subquestion_name: "Subquestion 2",
                    htmlAnswer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam voluptate veritatis fugiat necessitatibus quod, dolor aperiam itaque maiores iure tenetur deleniti excepturi consequuntur nobis laudantium?"
                }
            ]
        },
        {
            subject: "subject 2",
            subquestions: [
                {
                    subquestion_name: "Subquestion 1",
                    htmlAnswer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam voluptate veritatis fugiat necessitatibus quod, dolor aperiam itaque maiores iure tenetur deleniti excepturi consequuntur nobis laudantium?",
                },
                {
                    subquestion_name: "Subquestion 2",
                    htmlAnswer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam voluptate veritatis fugiat necessitatibus quod, dolor aperiam itaque maiores iure tenetur deleniti excepturi consequuntur nobis laudantium?",
                }
            ],
        }
    ]

    manuals = [
        {
            title: "AgroWizard - Handleiding servicedesk v2",
            description: "19 september 2024",
            downloadLink: "/assets/manuals/AgroWizard - Handleiding servicedesk v2.pdf"
        },
        {
            title: "AgroWizard - Handleiding dataverzameling v2.2",
            description: "21 oktober 2024",
            downloadLink: "/assets/manuals/AgroWizard - Handleiding dataverzameling v2.2.pdf"
        },
        {
            title: "AgroWizard - Handleiding bestuurdersdashboard v2.1",
            description: "1 augustus 2024",
            downloadLink: "/assets/manuals/AgroWizard - Handleiding bestuurdersdashboard v2.1.pdf"
        }
    ]

    downloadPDF(fileName,downloadLink) {
        const link = document.createElement('a');
        link.href = downloadLink; // Replace with your PDF file URL
        link.download = fileName + '.pdf'; // The filename for the downloaded file
        link.click();
      }

    handleFAQItemClick(event){

        let element = event.target;
        element = element.nextElementSibling;


        if (element.style.height) {
            if(element.classList.contains("sub-question-answer")){
                let parentContainer = element.parentElement.parentElement;
                parentContainer.style.height = (parentContainer.scrollHeight - element.scrollHeight) + "px";
            }
            element.style.height = '';
          } else {
            if(element.classList.contains("sub-question-answer")){
                let parentContainer = element.parentElement.parentElement;
                parentContainer.style.height = (parentContainer.scrollHeight + element.scrollHeight) + "px";
            }
            element.style.height = element.scrollHeight + 'px';
          }
    }



}
